import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import unitApi from "../../apis/api/unit";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { reqGetListUnits } from "../../reduxs/cms/action";
import { ErrorMessage } from "@hookform/error-message";
import { CloseModalIcon } from '../../components/svgs/icons';

const EditPropertyModal = ({ property, onClose }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    setError,
  } = useForm({ values: { ...property } }); // spread property to reset form on close without submit
  const onSubmit = async (data) => {
    const { name, price, bedrooms, aspect, type, cellingHeight, area } = data;
    try {
      const result = await unitApi.putUnitUpdate(property.id, {
        name,
        price,
        bedrooms,
        aspect,
        type,
        cellingHeight,
        area: area.name,
      });
      if (!result.data) {
        throw new Error(result);
      }
      toast.success("Update property successfully");
      dispatch(reqGetListUnits());
      onClose();
    } catch (error) {
      if (error.message.includes("Building")) {
        setError("area.name", { message: "Please enter a valid building" });
      }
      if (error.message.includes("Bad Request")) {
        toast.error("Invalid Data");
        return;
      }
      toast.error(error?.message);
    }
  };

  return (
    <Modal show={!!property} onHide={onClose} centered backdrop={true} animation={false}>
      <span className="close-button-x" onClick={onClose}>
        <CloseModalIcon />
      </span>
      <Modal.Body className="edit-property-modal">
        <span className="modal-title">Update residence</span>
        <Form onSubmit={handleSubmit(onSubmit)} id="edit-property-form">
          <Row>
            <Col>
              <Form.Group controlId="Residence">
                <Form.Label className={errors["name"] && "error"}>
                  Residence*
                </Form.Label>
                <Form.Control
                  className={errors["name"] && "error"}
                  type="text"
                  placeholder="Residence"
                  {...register("name", {
                    required: "Please enter a valid residence",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="Building">
                <Form.Label className={errors["area"]?.["name"] && "error"}>
                  Building*
                </Form.Label>
                <Form.Control
                  className={errors["area"]?.["name"] && "error"}
                  type="text"
                  placeholder="Building"
                  {...register("area.name", {
                    required: "Please enter a valid building",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="area.name"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="Price">
                <Form.Label className={errors["price"] && "error"}>
                  Price*
                </Form.Label>
                <Form.Control
                  className={errors["price"] && "error"}
                  type="text"
                  placeholder="Price"
                  {...register("price", {
                    required: {
                      value: true,
                      message: "Please enter a valid price",
                    },
                    max: {
                      value: Number.MAX_SAFE_INTEGER,
                      message: "Please enter a valid price",
                    },
                    min: {
                      value: 0,
                      message: "Please enter a valid price",
                    },
                    pattern: {
                      value: /^([0-9]+|[0-9]+\.[0-9]+)$/,
                      message: "Please enter a valid price",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="price"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="Bedrooms">
                <Form.Label className={errors["bedrooms"] && "error"}>
                  Bedrooms*
                </Form.Label>
                <Form.Control
                  className={errors["bedrooms"] && "error"}
                  type="text"
                  placeholder="Bedrooms"
                  {...register("bedrooms", {
                    required: {
                      value: true,
                      message: "Please enter a valid bedrooms number",
                    },
                    max: {
                      value: 100,
                      message: "Please enter a valid number of bedrooms",
                    },
                    min: {
                      value: 0,
                      message: "Please enter a valid number of bedrooms",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number of bedrooms",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="bedrooms"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="Aspect">
                <Form.Label className={errors["aspect"] && "error"}>
                  Aspect*
                </Form.Label>
                <Form.Control
                  className={errors["aspect"] && "error"}
                  type="text"
                  placeholder="Aspect"
                  {...register("aspect", {
                    required: "Please enter a valid aspect",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="aspect"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="ResidenceType">
                <Form.Label className={errors["type"] && "error"}>
                  Residence Type*
                </Form.Label>
                <Form.Control
                  className={errors["type"] && "error"}
                  type="text"
                  placeholder="Residence Type"
                  {...register("type", {
                    required: "Please enter a valid residence type",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="type"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="CeilingHeight">
                <Form.Label className={errors["cellingHeight"] && "error"}>
                  Ceiling Height*
                </Form.Label>
                <Form.Control
                  className={errors["cellingHeight"] && "error"}
                  type="text"
                  placeholder="Ceiling Height"
                  {...register("cellingHeight", {
                    required: "Please enter a valid ceiling height",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="cellingHeight"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="edit-property-modal-footer">
          <button
            className={isDirty ? "submit-button dirty" : "submit-button"}
            type="submit"
            form="edit-property-form"
            disabled={!isDirty}
          >
            Save
          </button>
          <button className="close-button" onClick={onClose} type="button">
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditPropertyModal;
