import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  reqGetListGalleries,
  reqSetIsShowCreateGalleryModal,
  reqSetSelectedMedia,
} from "../../reduxs/cms/action";
import Select from "react-select";
import MediaPicker from "./media-picker";
import galleryApi from "../../apis/api/gallery";
import { toast } from "react-toastify";

const CreateGalleryModal = () => {
  const fileRef = useRef();
  const isShowCreateGalleryModal = useSelector(
    (state) => state.cms.isShowCreateGalleryModal
  );
  const selectedMedia = useSelector((state) => state.cms.selectedMedia);
  const dispatch = useDispatch();

  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [type, setType] = useState("image");
  const [videoUrl, setVideoUrl] = useState("");

  const handleClose = () => {
    dispatch(reqSetIsShowCreateGalleryModal(false));
    resetForm();
  };

  const resetForm = () => {
    setName();
    setFile();
    setType("image");
    setVideoUrl();
    fileRef.current.value = "";
    dispatch(reqSetSelectedMedia([]));
  };

  const onOpenFileInput = () => {
    fileRef.current.click();
  };

  const onSaveGallery = async () => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("file", file);
      formData.append("type", type);
      formData.append("videoUrl", videoUrl);

      selectedMedia.forEach((el, index) => {
        formData.append(`media[${index}]`, el);
      });

      await galleryApi.createGallery(formData);

      dispatch(reqSetIsShowCreateGalleryModal(false));
      dispatch(reqGetListGalleries());
      toast.success("Created gallery successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return "/images/image.png";
  };

  const renderImagePicker = () => {
    if (type === "video") return;

    return (
      <div className="row">
        <label className="title">GALLERY IMAGES</label>
        <MediaPicker />
      </div>
    );
  };

  const renderVideoURLForm = () => {
    if (type !== "video") return;
    return (
      <>
        <label className="title">VIDEO URL</label>
        <input
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          className="form-control"
          type="text"
        />
      </>
    );
  };

  return (
    <Modal
      size="lg"
      className="wrap-create-gallery-modal"
      show={isShowCreateGalleryModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <div className="modal-form__title mb-4">ADD NEW GALLERY</div>
        <div className="content">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="title">UPLOAD THUMBNAIL</div>
              <img
                onClick={onOpenFileInput}
                className="gallery-image cursor-pointer"
                src={renderImagePreview()}
                alt=""
              />
              <input
                ref={fileRef}
                accept="image/*"
                id="file"
                className="hidden"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <label className="title" htmlFor="">
                GALLERY TITLE
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control mb-3"
                type="text"
              />
              <label className="title">GALLERY TYPE</label>
              <Select
                className="wrap-select-gallery-type mb-3"
                classNamePrefix="select"
                defaultValue={{
                  value: "image",
                  label: "IMAGES",
                }}
                isDisabled={false}
                isSearchable={true}
                options={[
                  {
                    value: "image",
                    label: "IMAGES",
                  },
                  {
                    value: "virtual_tour",
                    label: "VIRTUAL TOUR",
                  },
                  {
                    value: "video",
                    label: "VIDEO",
                  },
                  {
                    value: "floor_plan",
                    label: "FLOOR PLAN",
                  },
                ]}
                name="type"
                onChange={(item) => setType(item.value)}
              />
              {renderVideoURLForm()}
            </div>
          </div>
          {renderImagePicker()}
        </div>
        <div className="wrap-button d-flex justify-content-between">
          <button className="text-danger" onClick={handleClose}>
            CANCEL
          </button>
          <button onClick={onSaveGallery}>SAVE</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateGalleryModal;
