import React, { useEffect, useState } from "react";
import "./index.scss";
import jwtAuth from "../../apis/utils/jwtAuth";
import authApi from "../../apis/api/auth";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reqGetUserProfile } from "../../reduxs/user/action";
import logo from "../../assets/images/logo.png";

const HoldingPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    redirectIfAuthenticated();
  }, [authUser]);

  useEffect(() => {
    if (email || password) {
      setErrorMessage("");
    }
  }, [email, password]);

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken() && authUser) {
      history.push("/");
    }
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      if (isPresentation) {
        history.push('/presentation');
      } else {
        history.push('/');
      }
    } else {
      setErrorMessage("Email not found or password does not match");
    }
  };

  const getUserProfile = async () => {
    try {
      dispatch(reqGetUserProfile());
    } catch (error) {
      return;
    }
  };

  return (
    <div className="wrap-holding-page h-100">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className={"modal-form"}>
          <img src={logo} alt="" width="300px" height="40px" />
          <div className="modal-form__body">
            <div className="form-group">
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                id="password"
                aria-describedby="passwordHelp"
                placeholder="Password"
              />
            </div>
            {errorMessage !== "" && (
              <p id="passwordHelp" className="text-danger mt-2 mb-0">
                {errorMessage}
              </p>
            )}
            <button
              type="submit"
              onClick={() => handleLogin()}
              className="button-submit"
            >
              <span>Enter</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoldingPage;
