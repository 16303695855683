import masterData from "./master-data";

export const UNIT_AREAS = [
  {
    id: 1,
    name: "ORLA",
    value: "orla",
  },
  {
    id: 2,
    name: "ORLA Infinity",
    value: "orla_infinity",
  },
];

export const ASPECT_OPTION = [
  {
    id: 1,
    name: "Beach view",
    value: "beach_view",
  },
  {
    id: 2,
    name: "Palm view",
    value: "palm_view",
  },
];

export const UNIT_TYPE_OPTION = [
  {
    id: 1,
    name: "Simple",
    value: "simple",
  },
  {
    id: 2,
    name: "Duplex",
    value: "duplex",
  },
];

export const CELLING_HEIGHT_OPTION = [
  {
    id: 1,
    name: "Regular",
    value: "regular",
  },
  {
    id: 2,
    name: "Double",
    value: "double",
  },
];

export const AVAILABILITY_STATUS_OPTION = [
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.AVAILABLE_NOW,
    title: "Available now",
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.RESERVED,
    title: "Reserved",
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.MUTILPLE_OFFERS,
    title: "Multiple Offers",
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.SOLD,
    title: "Sold",
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.RELEASING_SOON,
    title: "Releasing soon",
  },
];

export const MEDIA_TYPE_OPTION = [
  {
    value: masterData.MEDIA_TYPE_ENUM["360_PANO_TOUR"],
    title: "360 Pano tour",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.FLOORPLAN,
    title: "Floorplan",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.IMAGE,
    title: "Image",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.INTERIOR_ID,
    title: "Interior id",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.VIDEO,
    title: "Video",
  },
];

export const PROPERTY_TYPE_OPTION = [
  {
    value: masterData.PROPERTY_TYPE_ENUM.APARTMENT,
    title: "Apartment",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.HOUSE,
    title: "House",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.LAND,
    title: "Land",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.OFFICE,
    title: "Office",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.TOWNHOUSE,
    title: "Townhouse",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.UNIT,
    title: "Unit",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.VILLA,
    title: "Villa",
  },
];

export const UNIT_OPTION = [
  {
    value: masterData.UNIT_ENUM.ACRE,
    title: "Acre",
  },
  {
    value: masterData.UNIT_ENUM.HECTARE,
    title: "Hectare",
  },
  {
    value: masterData.UNIT_ENUM.SQUARE,
    title: "Square",
  },
  {
    value: masterData.UNIT_ENUM.SQUARE_METER,
    title: "Square meter",
  },
];

export const BEDROOM_OPTION = [
  {
    title: "1",
    id: 1,
    value: 1,
  },
  {
    title: "2",
    id: 2,
    value: 2,
  },
  {
    title: "3",
    id: 3,
    value: 3,
  },
  {
    title: "4",
    id: 4,
    value: 4,
  },
  {
    title: "5",
    id: 5,
    value: 5,
  },
];

export const PARKING_STALLS = [
  {
    title: "2",
    value: 2,
  },
  {
    title: "3",
    value: 3,
  },
];

export const PAGES = {
  NONE: "NONE",
  INTRODUCE_PAGE: "INTRODUCE_PAGE",
  AMENITY_PAGE: "AMENITY_PAGE",
  UNIT_EXPLORER_PAGE: "UNIT_EXPLORER_PAGE",
  GALLERY_PAGE: "GALLERY_PAGE",
  IMMERSE_PAGE: "IMMERSE_PAGE",
  TEAM_PAGE: "TEAM_PAGE",
  LOCATION_PAGE: "LOCATION_PAGE",
  ONBOARD_PAGE: "ONBOARD_PAGE",
  EXCUTIVE_SUMMARY_PAGE: "EXCUTIVE_SUMMARY_PAGE",
  SEARCH_PAGE: "SEARCH_PAGE",
  LANDING_PAGE: "LANDING_PAGE",
  EXPLORE_PRECINCT_PAGE: "EXPLORE_PRECINCT_PAGE",
  EXPLORE_DISTRICT_PAGE: "EXPLORE_DISTRICT_PAGE",
  EXPLORE_CITY_PAGE: "EXPLORE_CITY_PAGE",
  PRECINCT_SUMMANRY_PAGE: "PRECINCT_SUMMANRY_PAGE",
  ENVIRONMENT_PAGE: "ENVIRONMENT_PAGE",
  END_GUIDE_TENANT_SESSION_PAGE: "END_GUIDE_TENANT_SESSION_PAGE",
  ABOUT_PAGE: "ABOUT_PAGE",
  LIFE_STYLE_PAGE: "LIFE_STYLE_PAGE",
  GALLERY_LANDING_PAGE: "GALLERY_LANDING_PAGE",
  AMENITIES_PAGE: "AMENITIES_PAGE",
  SESSION_PAGE: "SESSION_PAGE",
};

export const LAYERS = {
  NONE: 0,
  HOTPOT: 1,
  UNITS: 2,
  FEATURE_HOUSE: 3,
  D_HOTSPOT: 4,
  EXPLORE_DISTRICT_HOTSPOT: 5,
  PINS_DO: 6,
  DO_BUILDING: 7,
  LANDING: 8,
  EXPLORE_CITY_ONLY: 9,

  BUILDING_23: 13,
  BUILDING_25: 15,
  BUILDING_27: 17,
  BUILDING_29: 19,
  BUILDING_31: 21,

  DISABLE: 31,
};

export const OBJECT_TYPES = {
  NONE: "none",
  DO: "do",
  FD: "fd",
  UNIT: "unit",
  AREA: "area",
  AMENITY: "amenity",
};
export const HOTSPOT_TYPES = {
  GALLERY: "gallery",
  PANO_TOUR: "360_pano_tour",
  URL: "url",
  VIDEO: "video",
  EXCUTIVE_SUMMARY: "excutive_summary",
  AMENITY: "amenity",
  UNIT_EXPLORE: "unit_explore",
  MODAL: "modal",
  AMENITY_ITEM: "amenity_item",
  EXPLORE_DISTRICT: "explore_district",
  EXPLORE_DISTRICT_DETAIL: "explore_district_detail",
  FUTURE_ITEM: "future_item",
  EXPLORE_DISTRICT_PAGE: "explore_district_page",
  LOCATION_PAGE: "location_page",
  AMENITY_PAGE: "amenity_page",
};

export const FLOORPLAN_NAME_OPTION = [
  {
    title: "Eucalyptus",
    value: "Eucalyptus",
  },
  {
    title: "Laurel",
    value: "Laurel",
  },
  {
    title: "Madrone A",
    value: "Madrone A",
  },
  {
    title: "Madrone B",
    value: "Madrone B",
  },
  {
    title: "Madrone C",
    value: "Madrone C",
  },
  {
    title: "Manzanita",
    value: "Manzanita",
  },
  {
    title: "Maple",
    value: "Maple",
  },
  {
    title: "Oak",
    value: "Oak",
  },
  {
    title: "Sycamore A",
    value: "Sycamore A",
  },
  {
    title: "Sycamore B",
    value: "Sycamore B",
  },
  {
    title: "Sycamore C",
    value: "Sycamore C",
  },
];

export const PRICE_OPTION = [
  {
    id: 1,
    title: "Starting 3M",
    min: 3000000,
    max: 4000000,
  },
  {
    id: 2,
    title: "Starting 4M",
    min: 4000000,
    max: 5000000,
  },
  {
    id: 3,
    title: "Starting 5M",
    min: 5000000,
  },
];

export const SQUARE_FOOTAGE_OPTION = [
  {
    id: 1,
    title: "Starting 2000 sq ft",
    min: 2000,
    max: 3000,
  },
  {
    id: 2,
    title: "Starting 3000 sq ft",
    min: 3000,
    max: 4000,
  },
  {
    id: 3,
    title: "Starting 4000 sq ft",
    min: 4000,
    max: 5000,
  },
  {
    id: 4,
    title: "Starting 5000 sq ft",
    min: 5000,
  },
];

export default {
  AVAILABILITY_STATUS_OPTION,
  MEDIA_TYPE_OPTION,
  PROPERTY_TYPE_OPTION,
  UNIT_OPTION,
  PARKING_STALLS,
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  FLOORPLAN_NAME_OPTION,
  PRICE_OPTION,
  SQUARE_FOOTAGE_OPTION,
  UNIT_AREAS,
};

export const GROSS_RENT_OPTION = [
  {
    id: 1,
    text: "$50 - 100 PSM",
    min: 50,
    max: 100,
  },
  {
    id: 2,
    text: "$100 - 200 PSM",
    min: 100,
    max: 200,
  },
  {
    id: 3,
    text: "$200+ PSM",
    min: 200,
  },
];

export const CENTRE_OPTION = [
  {
    text: "Existing Centre",
    value: "Existing Centre",
  },
  {
    text: "Cinema Building",
    value: "Cinema Building",
  },
  {
    text: "Fresh Food",
    value: "Fresh Food",
  },
];

export const LEVEL_OPTION = [
  {
    text: "Ground",
    value: "GROUND",
  },
  {
    text: "Level 1",
    value: "LEVEL ONE",
  },
];

export const AREA_OPTION = [
  {
    id: 1,
    min: 10,
    max: 50,
    text: "10 - 50 sqm",
  },
  {
    id: 2,
    min: 50,
    max: 200,
    text: "50 - 200 sqm",
  },
  {
    id: 3,
    min: 200,
    max: 500,
    text: "200 - 500 sqm",
  },
  {
    id: 4,
    min: 500,
    text: "500+ sqm",
  },
];

export const PRECINCT_OPTION = [
  {
    text: "Entertainment and F&B",
    value: "Entertainment and F&B",
  },
  {
    text: "General Retail",
    value: "General Retail",
  },
  {
    text: "Health & Wellbeing",
    value: "Health & Wellbeing",
  },
  {
    text: "Lifestyle & Tourism",
    value: "Lifestyle & Tourism",
  },
  {
    text: "Commercial Office",
    value: "Commercial Office",
  },
  {
    text: "Fresh Food and Daily Needs",
    value: "Fresh Food and Daily Needs",
  },
];

export const SUB_TAB_GALLERY = {
  FILMS: "Films",
  FLOOR_PLANS: "Floorplans",
  RENDER: "Images",
  VIEWS: "Views",
};
export const TAB_GALLERY = {
  ORLA: "ORLA",
  ORLA_INFINITY: "ORLA Infinity",
  ORLA_MANSION: "ORLA Mansion",
  ORLA_SKY_PALACES: "ORLA Sky Palaces",
};

export const GALLERY_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
  VIRTUAL_TOUR: "virtual_tour",
};

export const ACTION_NAME = {
  CLICK_UNIT_EXPLORE_MENU: "CLICK_UNIT_EXPLORE_MENU",
  CLICK_LOCATION_MENU: "CLICK_LOCATION_MENU",
  CLICK_CUBE_MENU: "CLICK_CUBE_MENU",
  CLICK_GALLERY_MENU: "CLICK_GALLERY_MENU",
  CLICK_TEAM_MENU: "CLICK_TEAM_MENU",
  CLICK_IMMERSER_MENU: "CLICK_IMMERSER_MENU",
  CLICK_SEARCH_MENU: "CLICK_SEARCH_MENU",
  CLICK_START_DISCOVER: "CLICK_START_DISCOVER",
  CLICK_UNIT_FILTER: "CLICK_UNIT_FILTER",
  CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS",
  CLICK_UNIT: "CLICK_UNIT",
  CLICK_HOTSPOT: "CLICK_HOTSPOT",
  CLICK_AMENITY_ITEM: "CLICK_AMENITY_ITEM",
  CLICK_CLOSE_MODAL: "CLICK_CLOSE_MODAL",
  CLICK_MODAL_BUTTON: "CLICK_MODAL_BUTTON",
  CHANGE_MODAL_CONTENT: "CHANGE_MODAL_CONTENT",
  SHOW_UNIT_GALLERY: "SHOW_UNIT_GALLERY",
  CLOSE_UNIT_GALLERY: "CLOSE_UNIT_GALLERY",
  SHOW_UNIT_FLOORPLAN: "SHOW_UNIT_FLOORPLAN",
  CLOSE_UNIT_FLOORPLAN: "CLOSE_UNIT_FLOORPLAN",
  CLICK_EYE_ICON: "CLICK_EYE_ICON",
  CLICK_NEXT_GALLERY: "CLICK_NEXT_GALLERY",
  CLICK_PREV_GALLERY: "CLICK_PREV_GALLERY",
  CHANGE_SLIDE_GALLERY: "CHANGE_SLIDE_GALLERY",
  CLICK_FLOORPLAN_SLIDE_BTN: "CLICK_FLOORPLAN_SLIDE_BTN",
  ZOOM_IN_FLOORPLAN: "ZOOM_IN_FLOORPLAN",
  ZOOM_OUT_FLOORPLAN: "ZOOM_OUT_FLOORPLAN",
  NUDGE_UP_FLOORPLAN: "NUDGE_UP_FLOORPLAN",
  NUDGE_DOWN_FLOORPLAN: "NUDGE_DOWN_FLOORPLAN",
  NUDGE_LEFT_FLOORPLAN: "NUDGE_LEFT_FLOORPLAN",
  NUDGE_RIGHT_FLOORPLAN: "NUDGE_RIGHT_FLOORPLAN",
  CLICK_SEARCH_UNIT: "CLICK_SEARCH_UNIT",
  CLICK_SEARCH_AMENITY: "CLICK_SEARCH_AMENITY",
  CLICK_SEARCH_POI: "CLICK_SEARCH_POI",
  CHANGE_POI_MODAL: "CHANGE_POI_MODAL",
  CLICK_POI_GROUP: "CLICK_POI_GROUP",
  CLOSE_POI_MODAL: "CLOSE_POI_MODAL",
  CHANGE_POI_GROUP: "CHANGE_POI_GROUP",
  CHANGE_ACTIVE_POI: "CHANGE_ACTIVE_POI",
  CHANGE_ACTIVE_TYPE: "CHANGE_ACTIVE_TYPE",
  WINDOW_SIZE: "WINDOW_SIZE",
  CHANGE_PANORAMA_CAMERA: "CHANGE_PANORAMA_CAMERA",
  CLOSE_PANO_VIEWER: "CLOSE_PANO_VIEWER",
  CLICK_END_SESSION: "CLICK_END_SESSION",
  SHOW_INTRO_SLIDE: "SHOW_INTRO_SLIDE",
  CHANGE_INTRO_SLIDE: "CHANGE_INTRO_SLIDE",
  EXPLORE_SHOW_FILTER: "EXPLORE_SHOW_FILTER",
  CLICK_ABOUT_MENU: "CLICK_ABOUT_MENU",
  CLICK_LIFE_STYLE_MENU: "CLICK_LIFE_STYLE_MENU",
  SHOW_INSPIRE_FILM: "SHOW_INSPIRE_FILM",
  CLOSE_INSPIRE_FILM: "CLOSE_INSPIRE_FILM",
  SATRT_SESSION: "START_SESSION",
  SKIP_SESSION_VIDEO: "SKIP_SESSION_VIDEO",
  SHOW_UNIT_VIEWLINE: "SHOW_UNIT_VIEWLINE",
  CLOSE_UNIT_VIEWLINE: "CLOSE_UNIT_VIEWLINE",
  ENABLE_TRANSPARENT_MODE: "ENABLE_TRANSPARENT_MODE",
  DISABLE_TRANSPARENT_MODE: "DISABLE_TRANSPARENT_MODE",
  HIDE_FILTER: "HIDE_FILTER",
  SHOW_FILTER: "SHOW_FILTER",
  CLICK_SEARCH_UNIT: "CLICK_SEARCH_UNIT",
  CLICK_SEARCH_AMENITY: "CLICK_SEARCH_AMENITY",
  CLICK_POI: "CLICK_POI",
  CLICK_POI_ITEM: "CLICK_POI_ITEM",
  CHANGE_ACTIVE_GALLERY_TAB: "CHANGE_ACTIVE_GALLERY_TAB",
  CHANGE_ACTIVE_SUB_GALLERY_TAB: "CHANGE_ACTIVE_SUB_GALLERY_TAB",
  PLAY_VIDEO: "PLAY_VIDEO",
  STOP_VIDEO: "STOP_VIDEO",
  SHOW_RENDER_GALLERY: "SHOW_RENDER_GALLERY",
  GO_NEXT_RENDER_IMAGE: "GO_NEXT_RENDER_IMAGE",
  GO_PREV_RENDER_IMAGE: "GO_PREV_RENDER_IMAGE",
  CLOSE_RENDER_GALLERY: "CLOSE_RENDER_GALLERY",
  JUMP_TO_RENDER_IMAGE: "JUMP_TO_RENDER_IMAGE",
  CHANGE_ACTIVE_FLOORPLAN_LEVEL: "CHANGE_ACTIVE_FLOORPLAN_LEVEL",
  SHOW_VIEW_FLOORPLAN: "SHOW_VIEW_FLOORPLAN",
  CLOSE_VIEW_FLOORPLAN: "CLOSE_VIEW_FLOORPLAN",
  CLOSE_FLOOR_MODAL: "CLOSE_FLOOR_MODAL",
  SHOW_GALLERY_DATA: "SHOW_GALLERY_DATA",
  CLOSE_FLOORPLAN_IMAGE: "CLOSE_FLOORPLAN_IMAGE",
  JUMP_TO_FLOORPLAN: "JUMP_TO_FLOORPLAN",
  GO_NEXT_FLOORPLAN: "GO_NEXT_FLOORPLAN",
  GO_PREV_FLOORPLAN: "GO_PREV_FLOORPLAN",
  GOOGLE_MAP_ZOOM_CHANGED: "GOOGLE_MAP_ZOOM_CHANGED",
  GOOGLE_MAP_CENTER_CHANGED: "GOOGLE_MAP_CENTER_CHANGED",
  PAGE_SCROLL: "PAGE_SCROLL",
  CLOSE_SEARCH_MODAL: "CLOSE_SEARCH_MODAL",
  SEARCH_KEYWORD_CHANGED: "SEARCH_KEYWORD_CHANGED",
  TOAST_MESSAGE: "TOAST_MESSAGE"
};
export const WEBSOCKET_CHANNEL = {
  SHARE_UI_ACTION: "share_screen_ui_action",
  SHARE_CAMERA_ACTION: "share_screen",
  SHARE_3D_BUILDING_ACTION: "share_3d_building_action",
  SHARE_PANORAMA_ACTION: "share_panorama_action",
  SHARE_MEDIA: "share_media",
};
