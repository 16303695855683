import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.scss";
import { memo } from "react";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
} from "../../reduxs/explore-modal/action";
import { useSelector } from "react-redux";
import {
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
  reqSetSubPrecincts,
  reqSetTransportOptionDistricts,
  reqSetTransportOptions,
} from "../../reduxs/home/action";
import { LAYERS, PAGES } from "../../constants/options";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";

const TransportOptionDistrict = memo((props) => {
  const { handleClickTransportOptionDistricts, refScene } = props;
  const dispatch = useDispatch();
  const transportOptionDistricts = useSelector(
    (state) => state.home.transportOptionDistricts
  );
  const activeTpId = useSelector(
    (state) => state.home.activeTransportOptionDistricts
  );
  const subPrecincts = useSelector((state) => state.home.subPrecincts);
  const activePrecinctId = useSelector(
    (state) => state.transportOption.activePrecinctId
  );

  const page = useSelector((state) => state.home.page);

  let filteredTransportOptionDistricts = [];

  useEffect(() => {
    dispatch(reqSetTransportOptionDistricts());
    dispatch(reqSetSubPrecincts());
  }, []);

  if (refScene != null) {
    transportOptionDistricts.forEach((tp) => {
      let isVisible = activeTpId.includes(tp.id);
      tp.hidden_when_not_selected.forEach((id) => {
        let object = refScene.current?.getObjectByName(id);
        if (object != null) {
          if (!isVisible) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          } else {
            object.layers.set(LAYERS.NONE);
          }
        }
      });
    });
  }

  transportOptionDistricts.forEach((tp) => {
    if (tp.show_on_pages.includes(page)) {
      filteredTransportOptionDistricts.push(tp);
    }
  });

  const onClick = (transportOption) => {
    let newArray = [].slice();
    let newActiveTpIds = activeTpId.slice();
    if (newActiveTpIds.includes(transportOption.id)) {
      let index = newActiveTpIds.indexOf(transportOption.id);
      if (index !== -1) {
        newActiveTpIds.splice(index, 1);
      }
    } else {
      newActiveTpIds.push(transportOption.id);
    }

    let lastTPModal = null;
    let maxIndex = -1;

    let activeColorModel = {};
    transportOptionDistricts.forEach((tp) => {
      if (newActiveTpIds.includes(tp.id)) {
        let needCheckedIndex = newActiveTpIds.indexOf(tp.id);

        (tp.asset || []).forEach((a) => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
          if (
            activeColorModel["" + a] == null ||
            needCheckedIndex > activeColorModel["" + a].index
          ) {
            activeColorModel["" + a] = {
              colorCode: tp.activeAssetColor,
              index: needCheckedIndex,
            };
          }
        });

        if (needCheckedIndex > maxIndex) {
          maxIndex = needCheckedIndex;
          lastTPModal = tp.modal;
        }
      }
    });

    subPrecincts.forEach((tp) => {
      if (activePrecinctId == tp.id) {
        (tp.asset || []).forEach((a) => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
          if (activeColorModel["" + a] == null) {
            activeColorModel["" + a] = {
              colorCode: tp.activeAssetColor,
              index: 0,
            };
          }
        });
        if (lastTPModal == null) {
          lastTPModal = tp.modal;
        }
      }
    });

    handleClickTransportOptionDistricts(newArray, activeColorModel);
    dispatch(reqSetExploreModal(lastTPModal));
    dispatch(
      reqSetIsShowExploreModal(
        newActiveTpIds.length > 0 || activePrecinctId != null
      )
    );
    dispatch(reqSetActiveTransportOptionDistricts(newActiveTpIds));
  };

  const onClickPrecinct = (transportOption) => {
    let newArray = [].slice();
    let newActiveTpIds = activeTpId.slice();
    let newActivePrecinctId =
      activePrecinctId != transportOption.id ? transportOption.id : null;
    let lastTPModal = null;
    let activeColorModel = {};
    let maxIndex = -1;

    subPrecincts.forEach((tp) => {
      if (newActivePrecinctId == tp.id) {
        (tp.asset || []).forEach((a) => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
          activeColorModel["" + a] = {
            colorCode: tp.activeAssetColor,
            index: 0,
          };
          lastTPModal = tp.modal;
        });
      }
    });

    transportOptionDistricts.forEach((tp) => {
      if (newActiveTpIds.includes(tp.id)) {
        let needCheckedIndex = newActiveTpIds.indexOf(tp.id);

        (tp.asset || []).forEach((a) => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
          if (
            activeColorModel["" + a] == null ||
            needCheckedIndex > activeColorModel["" + a].index
          ) {
            activeColorModel["" + a] = {
              colorCode: tp.activeAssetColor,
              index: needCheckedIndex,
            };
          }
        });

        if (newActivePrecinctId == null && needCheckedIndex > maxIndex) {
          maxIndex = needCheckedIndex;
          lastTPModal = tp.modal;
        }
      }
    });

    handleClickTransportOptionDistricts(newArray, activeColorModel);
    dispatch(reqSetExploreModal(lastTPModal));
    dispatch(
      reqSetIsShowExploreModal(
        newActiveTpIds.length > 0 || newActivePrecinctId != null
      )
    );
    dispatch(reqSetActivePrecinctID(newActivePrecinctId));
  };

  const getActiveClass = (tp) => {
    return activeTpId.includes(tp.id) ? "active" : "";
  };

  const getActiveColor = (tp) => {
    return activeTpId.includes(tp.id) ? tp.activeColor : "";
  };

  const getActivePrecinctClass = (tp) => {
    return tp.id == activePrecinctId ? "active" : "";
  };

  const getActivePrecinctColor = (tp) => {
    return tp.id == activePrecinctId ? tp.activeColor : "";
  };

  const renderSubPrecinctOption = () => {
    if (page === PAGES.EXPLORE_CITY_PAGE) return;

    return (
      <div className="wrap-list-transport">
        <h4 className="card-title">
          Sub
          <br />
          Precincts
        </h4>
        <div className="card-body">
          <ul className="list-transport list-2col">
            {subPrecincts.map((tp) => (
              <li key={`transport-${tp.id}`}>
                <div
                  className={`transport-item transport-item-${
                    tp.name
                  } ${getActivePrecinctClass(tp)}`}
                  style={{
                    backgroundColor: getActivePrecinctColor(tp),
                    borderColor: getActivePrecinctColor(tp),
                  }}
                >
                  <span onClick={() => onClickPrecinct(tp)}>
                    <svg
                      width="47"
                      height="47"
                      viewBox="0 0 47 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      dangerouslySetInnerHTML={{ __html: tp.svgPath }}
                    />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const fillTransportOptions = () => {
    if (filteredTransportOptionDistricts.length % 2 !== 0) {
      return (
        <li>
          <div className="transport-item transparent" />
        </li>
      );
    }
  };

  return (
    <div className="wrap-explore-district">
      {renderSubPrecinctOption()}
      <div className="wrap-list-transport">
        <h4 className="card-title">
          Transport
          <br />
          Options
        </h4>
        <div className="card-body">
          <ul className="list-transport list-2col">
            {filteredTransportOptionDistricts.map((tp) => (
              <li key={`transport-${tp.id}`}>
                <div
                  className={`transport-item transport-item-${
                    tp.name
                  } ${getActiveClass(tp)}`}
                  style={{
                    backgroundColor: getActiveColor(tp),
                    borderColor: getActiveColor(tp),
                  }}
                >
                  <span onClick={() => onClick(tp)}>
                    <svg
                      width="47"
                      height="47"
                      viewBox="0 0 47 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      dangerouslySetInnerHTML={{ __html: tp.svgPath }}
                    />
                  </span>
                </div>
              </li>
            ))}
            {fillTransportOptions()}
          </ul>
        </div>
      </div>
    </div>
  );
});
TransportOptionDistrict.displayName = "TransportOptionDistrict";

export default TransportOptionDistrict;
