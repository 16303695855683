import { Mesh, PerspectiveCamera, PlaneGeometry, Scene, ShaderMaterial } from "three";

const DOFmaterial = new ShaderMaterial({
  uniforms: {
    uPosition: { type: "t", value: null },
    uRadiance: { type: "t", value: null },
    aspect: { value: window.innerWidth / window.innerHeight },
    focus: { value: 0 },
    dof: { value: 0 },
    aperture: { value: 0 },
    maxBlur: { value: 0 },
    cameraFar: { value: 1 },
  },

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = vec4(position.xy, 0.0, 1.0);    
    }
  `,

  fragmentShader: `
        
uniform sampler2D uPosition;
uniform sampler2D uRadiance;

uniform float focus;
uniform float dof;
uniform float aperture;
uniform float maxBlur;
uniform float aspect;
uniform float cameraFar;

varying vec2 vUv;

// vec4 LinearToLinear( in vec4 value ) {
// vec4 LinearTosRGB( in vec4 value ) {

vec4 SRGBToLinear( vec4 c ) {
	return vec4(
		c.x = ( c.x < 0.04045 ) ? c.x * 0.0773993808 : pow( c.x * 0.9478672986 + 0.0521327014, 2.4 ),
		c.y = ( c.y < 0.04045 ) ? c.y * 0.0773993808 : pow( c.y * 0.9478672986 + 0.0521327014, 2.4 ),
		c.z = ( c.z < 0.04045 ) ? c.z * 0.0773993808 : pow( c.z * 0.9478672986 + 0.0521327014, 2.4 ),
		1.0
	);
}

void main() {
  vec2 uv = vUv;
	vec2 aspectCorrection = vec2(1.0 / aspect, 1.0);

	float linearDepth = abs(texture2D(uPosition, uv).z) / cameraFar;

	float focusNear = clamp(focus - dof, 0.0, 1.0);
	float focusFar = clamp(focus + dof, 0.0, 1.0);

	// Calculate a DoF mask.
	float low = step(linearDepth, focusNear);
	float high = step(focusFar, linearDepth);

	float factor = (linearDepth - focusNear) * low + (linearDepth - focusFar) * high;
	vec2 dofBlur = vec2(clamp(factor * aperture, -maxBlur, maxBlur));

	vec2 dofblur9 = dofBlur * 0.9;
	vec2 dofblur7 = dofBlur * 0.7;
	vec2 dofblur4 = dofBlur * 0.4;

	// this is only half of the original samples
	vec2 samples[] = vec2[](
		vec2( 0.0, 0.4 ),
		vec2( 0.29,  0.29),
		vec2( 0.40,  0.0 ),
		vec2( 0.29, -0.29),
		vec2( 0.0,  -0.4 ),
		vec2(-0.29,  0.29),
		vec2(-0.4,   0.0 ),
		vec2(-0.29, -0.29),
		vec2( 0.15,  0.37),
		vec2( 0.37, -0.15),
		vec2(-0.15,  0.37),
		vec2(-0.37, -0.15),
		vec2( 0.29,  0.29),
		vec2( 0.29, -0.29),
		vec2(-0.29,  0.29),
		vec2(-0.29, -0.29),
		vec2( 0.29,  0.29),
		vec2( 0.29, -0.29),
		vec2(-0.29,  0.29),
		vec2(-0.29, -0.29)

		// vec2( 0.15,  0.37),
		// vec2(-0.37,  0.15),
		// vec2( 0.37, -0.15),
		// vec2(-0.15, -0.37),
		// vec2(-0.15,  0.37),
		// vec2( 0.37,  0.15),
		// vec2(-0.37, -0.15),
		// vec2( 0.15, -0.37),
		// vec2(-0.37,  0.15),
		// vec2(-0.15, -0.37),
		// vec2( 0.37,  0.15),
		// vec2( 0.15, -0.37),
		// vec2( 0.40,  0.0 ),
		// vec2( 0.0,  -0.4 ),
		// vec2(-0.4,   0.0 ),
		// vec2( 0.0,   0.4 ),
		// vec2( 0.4,   0.0 ),
		// vec2( 0.0,  -0.4 ),
		// vec2(-0.4,   0.0 ),
		// vec2( 0.0,   0.4 )
	);

	// all of our samples have to be transformed from srgb to linear since
	// radianceRT unfortunately had to be created in srgb, because
	// ipadPro doesn't support RTs with { samples: 4, type: FloatType }
	vec4 color = vec4(0.0);
	for (int i = 0; i < 20; i++) {
		vec2 sDofBlur = vec2(0.0);
		if (i < 20) sDofBlur = dofblur4;
		if (i < 16) sDofBlur = dofblur7;
		if (i < 12) sDofBlur = dofblur9;
		if (i < 8) sDofBlur = dofBlur;

		vec4 s = texture2D(uRadiance, uv + samples[i] * aspectCorrection * sDofBlur * 1.5);
		color += SRGBToLinear(s);
	}
	color = LinearTosRGB(color / 20.0);
	gl_FragColor = color;

	// vec4 color = texture2D(uRadiance, uv);
	// color += texture2D(uRadiance, uv + (vec2( 0.0,   0.4 ) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2( 0.15,  0.37) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2( 0.29,  0.29) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2(-0.37,  0.15) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2( 0.40,  0.0 ) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2( 0.37, -0.15) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2( 0.29, -0.29) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2(-0.15, -0.37) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2( 0.0,  -0.4 ) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2(-0.15,  0.37) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2(-0.29,  0.29) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2( 0.37,  0.15) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2(-0.4,   0.0 ) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2(-0.37, -0.15) * aspectCorrection) * dofBlur);
	// color += texture2D(uRadiance, uv + (vec2(-0.29, -0.29) * aspectCorrection) * dofBlur);
	// // color += texture2D(uRadiance, uv + (vec2( 0.15, -0.37) * aspectCorrection) * dofBlur);

	// color += texture2D(uRadiance, uv + (vec2( 0.15,  0.37) * aspectCorrection) * dofblur9);
	// // color += texture2D(uRadiance, uv + (vec2(-0.37,  0.15) * aspectCorrection) * dofblur9);
	// color += texture2D(uRadiance, uv + (vec2( 0.37, -0.15) * aspectCorrection) * dofblur9);
	// // color += texture2D(uRadiance, uv + (vec2(-0.15, -0.37) * aspectCorrection) * dofblur9);
	// color += texture2D(uRadiance, uv + (vec2(-0.15,  0.37) * aspectCorrection) * dofblur9);
	// // color += texture2D(uRadiance, uv + (vec2( 0.37,  0.15) * aspectCorrection) * dofblur9);
	// color += texture2D(uRadiance, uv + (vec2(-0.37, -0.15) * aspectCorrection) * dofblur9);
	// // color += texture2D(uRadiance, uv + (vec2( 0.15, -0.37) * aspectCorrection) * dofblur9);

	// color += texture2D(uRadiance, uv + (vec2( 0.29,  0.29) * aspectCorrection) * dofblur7);
	// // color += texture2D(uRadiance, uv + (vec2( 0.40,  0.0 ) * aspectCorrection) * dofblur7);
	// color += texture2D(uRadiance, uv + (vec2( 0.29, -0.29) * aspectCorrection) * dofblur7);
	// // color += texture2D(uRadiance, uv + (vec2( 0.0,  -0.4 ) * aspectCorrection) * dofblur7);
	// color += texture2D(uRadiance, uv + (vec2(-0.29,  0.29) * aspectCorrection) * dofblur7);
	// // color += texture2D(uRadiance, uv + (vec2(-0.4,   0.0 ) * aspectCorrection) * dofblur7);
	// color += texture2D(uRadiance, uv + (vec2(-0.29, -0.29) * aspectCorrection) * dofblur7);
	// // color += texture2D(uRadiance, uv + (vec2( 0.0,   0.4 ) * aspectCorrection) * dofblur7);

	// color += texture2D(uRadiance, uv + (vec2( 0.29,  0.29) * aspectCorrection) * dofblur4);
	// // color += texture2D(uRadiance, uv + (vec2( 0.4,   0.0 ) * aspectCorrection) * dofblur4);
	// color += texture2D(uRadiance, uv + (vec2( 0.29, -0.29) * aspectCorrection) * dofblur4);
	// // color += texture2D(uRadiance, uv + (vec2( 0.0,  -0.4 ) * aspectCorrection) * dofblur4);
	// color += texture2D(uRadiance, uv + (vec2(-0.29,  0.29) * aspectCorrection) * dofblur4);
	// // color += texture2D(uRadiance, uv + (vec2(-0.4,   0.0 ) * aspectCorrection) * dofblur4);
	// color += texture2D(uRadiance, uv + (vec2(-0.29, -0.29) * aspectCorrection) * dofblur4);
	// // color += texture2D(uRadiance, uv + (vec2( 0.0,   0.4 ) * aspectCorrection) * dofblur4);

	// // gl_FragColor = color / 41.0;
	// gl_FragColor = color / (41.0 * 0.5);

}
      `,

  depthTest: false,
  depthWrite: false,
});

const mesh = new Mesh(
  new PlaneGeometry(2, 2),
  DOFmaterial
);
const camera = new PerspectiveCamera(
  45,
  1 /* remember that the camera is worthless here */,
  1,
  1000
);

const scene = new Scene();
scene.add(mesh);


window.elements = {
	focus: 0.2,
	dof: 0.3,
}

export function computeDOF(renderer, radianceTexture, positionTexture, renderTargetDest, cameraFar) {
  renderer.setRenderTarget(renderTargetDest);

  DOFmaterial.uniforms.uRadiance.value = radianceTexture;
  DOFmaterial.uniforms.uPosition.value = positionTexture;
  DOFmaterial.uniforms.focus.value = window.elements.focus;
  DOFmaterial.uniforms.dof.value = window.elements.dof;
  DOFmaterial.uniforms.aperture.value = 0.03;
  DOFmaterial.uniforms.maxBlur.value = 0.0075;
  DOFmaterial.uniforms.cameraFar.value = cameraFar;
  renderer.render(scene, camera);

  renderer.setRenderTarget(null);
}
