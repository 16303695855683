import React, { useEffect, useRef, useState } from "react";
import CanvasBox from "../../components/3d-scene/CanvasBox";
import _3dSettings from "../../apis/api/_3dSettings";

import { connect } from "react-redux";
import { setColor } from "../../helper/threeHeper";
import { Vector3, LinearSRGBColorSpace } from "three";
import ReactUI from "../reactui";
import { useHistory } from "react-router-dom";

const RefCanvasBox = React.forwardRef((props, ref) => (
  <CanvasBox ref={ref} {...props} />
));
RefCanvasBox.displayName = "RefCanvasBox";

const Index = (props) => {
  const { roles, authMiddleware } = props;
  const [isIntroduction, setIsIntroduction] = useState(false);
  const controls = useRef();
  let refScene = useRef();
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  let activeObjectIds = [];
  let activeColorModel = {};

  function setActiveObjectIds(ids) {
    let prevIds = [];

    for (const index in activeObjectIds) {
      if (!ids.includes(activeObjectIds[index])) {
        prevIds.push(activeObjectIds[index]);
      }
    }

    activeObjectIds = ids;
    handleResetObjectColor(prevIds);
    handleSetActiveObjectColor(activeObjectIds);
  }

  function resetActiveColorModel(model) {
    activeColorModel = model;
  }

  const handleResetObjectColor = async (prevIds = []) => {
    refScene.current &&
      (prevIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.color,
            objectSelector.userData.active_alpha,
            objectSelector,
          );
          objectSelector.userData.isActive = false;
          objectSelector.visible = false;
        }
      });
  };

  const handleSetActiveObjectColor = (activeObjectIds) => {
    refScene.current &&
      (activeObjectIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          // let activeColor =
          //   activeColorModel["" + item]?.colorCode ??
          //   objectSelector.userData.active_color;
          // setColor(
          //   activeColor,
          //   objectSelector.userData.active_alpha,
          //   objectSelector,
          // );

          objectSelector.userData.isActive = true;
          objectSelector.visible = true;

          // this guard is necessary otherwise after setting .map to null
          // you would re-assign null to objectSelector.material.userData.mapRef
          if (!objectSelector.material.userData.mapRef) {
            objectSelector.material.userData.mapRef =
              objectSelector.material.map;

            objectSelector.material.userData.mapRef.colorSpace =
              LinearSRGBColorSpace;
          }

          const modelGroupName = objectSelector.userData.modelGroupName;
          let m = 0.7;
          if (modelGroupName.startsWith("Orla_Main")) m = 1;
          if (modelGroupName.startsWith("Infinity_UNIT")) m = 0.9;
          if (modelGroupName.startsWith("Mansion_Stone")) m = 1;

          objectSelector.material.emissive.set(1.3 * m, 1.1 * m, 0.8 * m);
          objectSelector.material.emissiveIntensity = 1;
          objectSelector.material.emissiveMap =
            objectSelector.material.userData.mapRef;
          objectSelector.material.map = null;
          objectSelector.material.toneMapped = true;
        }
      });
  };

  const renderCanvasBox = () => {
    if (
      !(
        !!props.assets.length &&
        !!props.hotspots.length &&
        Object.keys(props.settings).length &&
        props.fbxs.length &&
        props.pagesSettings != undefined
      )
    )
      return <div />;

    if (props.isLoading) {
      return <div />;
    }

    let ids = [];

    return (
      <CanvasBox
        isPresentation={true}
        ref={refScene}
        controls={controls}
        objects={props.assets}
        hotspots={props.hotspots}
        activeObjectIds={ids}
        setActiveObjectIds={setActiveObjectIds}
        _3dSetting={props.settings}
        fbxs={props.fbxs}
        pagesSettings={props.pagesSettings}
        isIntroduction={true}
        targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
      />
    );
  };

  return (
    <>
      {renderCanvasBox()}
      <ReactUI
        isPresentation={true}
        setIsIntroduction={setIsIntroduction}
        controls={controls}
        refScene={refScene}
        setActiveObjectIds={setActiveObjectIds}
        resetActiveColorModel={resetActiveColorModel}
        activeObjectIds={activeObjectIds}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    assets: scene.assets,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
    pagesSettings: scene.pagesSettings,
  };
};
export default connect(mapStateToProps)(Index);
