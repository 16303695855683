import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
  reqSetIsShowUserTypeModal,
  reqSetUserSelectTypeModal,
  reqSetIsShowListConsultantModal,
} from "../../reduxs/guide-session/action";
import "./index.scss";

const UserTypeModal = () => {
  const dispatch = useDispatch();
  const isShowUserTypeModal = useSelector(
    (state) => state.guideSession.isShowUserTypeModal
  );

  const handleClose = () => {
    dispatch(reqSetIsShowUserTypeModal(false));
  };

  const showListConsultantModal = () => {
    dispatch(reqSetIsShowListConsultantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("consultant"));
  };

  const showListInvestorModal = () => {
    dispatch(reqSetIsShowListInvestorModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("investor"));
  };

  const showListTenantModal = () => {
    dispatch(reqSetIsShowListTenantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("tenant"));
  };

  return (
    <>
      <Modal
        className="wrap-user-type-modal"
        show={isShowUserTypeModal}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Body className="wrap-modal-body">
          <div className="d-flex justify-content-end icon-close">
            <img
              className="cursor-pointer"
              onClick={handleClose}
              width={30}
              src="./icons/close-yellow.svg"
              alt=""
            />
          </div>
          <div className="modal-form__title" style={{ fontSize: "24px" }}>
            Set up a guided session using the
          </div>
          <div
            className="modal-form__title underline-sm"
            style={{ fontSize: "24px" }}
          >
            ORLA Dorchester Collection app
          </div>
          <p
            style={{
              fontSize: "16px",
              marginBottom: "40px",
              color: "#0C2032",
              fontFamily: "RadikalRegular",
            }}
          >
            Please select your session type for today
          </p>
          <div className="d-flex-column justify-content-between">
            <button
              onClick={showListInvestorModal}
              className="btn-outline text-uppercase"
            >
              <span>REMOTE GUIDED SESSION</span>
            </button>
            <button
              onClick={showListConsultantModal}
              className="btn-outline text-uppercase"
            >
              <span>SALES GALLERY SESSION</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserTypeModal;
