import config from "../config/index";

class SocketHelper {
  constructor() {
    this.webSocket = new WebSocket(config.WEBSOCKET_URL);
  }

  connect(authUser) {
    this.authUser = authUser;
  }

  send(message) {
    if (!this.authUser?.presentation) return;

    return this.webSocket.send(message);
  }
}

const socket = new SocketHelper();

export default socket;
