export const config = {
  center: {
    lat: 25.1188863,
    lng: 55.1129475,
  },
  zoom: 13.67,
};

export const options = {
  styles: [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [
        {
          color: "#202c3e",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          gamma: 0.01,
        },
        {
          lightness: 20,
        },
        {
          weight: "1.39",
        },
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          weight: "0.96",
        },
        {
          saturation: "9",
        },
        {
          visibility: "on",
        },
        {
          color: "#000000",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          lightness: 30,
        },
        {
          saturation: "9",
        },
        {
          color: "#29446b",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          saturation: 20,
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          lightness: 20,
        },
        {
          saturation: -20,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 10,
        },
        {
          saturation: -30,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#193a55",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          saturation: 25,
        },
        {
          lightness: 25,
        },
        {
          weight: "0.01",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          lightness: -20,
        },
      ],
    },
  ],
  minZoom: 4,
  maxZoom: 18,
};

export const mapStyles = {
  default: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },
      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ffd77990" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
    ],
  },
  road: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },
      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ffd77990" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ffd77990" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
    ],
  },
  parking: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },

      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "transit.line.ferry",
        stylers: [{ weight: 8 }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
    ],
  },
  ferry: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },

      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "transit.line.ferry",
        stylers: [{ color: "#ffe5c163" }, { visibility: "on" }, { weight: 4 }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "transit.line.ferry",
        stylers: [{ weight: 8 }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
    ],
  },
  train: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },
      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
      {
        elementType: "geometry",
        featureType: "transit.line.rail",
        stylers: [{ color: "#ff7fdaac" }, { visibility: "on" }, { weight: 2 }],
      },
      {
        elementType: "geometry",
        featureType: "transit.line.transit_layer",
        stylers: [{ color: "#ff7fdaac" }, { visibility: "on" }],
      },
    ],
  },
};

export const specialLocations = [
  {
    id: "612e367abe5ae5cef4168aa9",
    coordinate: { lat: 38.2436599, long: -122.3007812 },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    group: "STANLY RANCH",
    modal: {
      id: "61394f098eef2b660e73c02c",
      content:
        '<div style="padding: 30px; background-color: #fff">\n  <div style="" class="px-3 py-4 d-flex align-item-center text-center">\n  <div>\n  <p style="letter-spacing: 0.2rem;">Stanly Ranch</p>\n  <h2 class="f-title mt-4">\n  Stanly Ranch Auberge Collections Napa\n </h2>\n  <p class="mb-0 mt-4">200 Stanly Cross Rd,<br>Napa, CA 94559</p>\n  </div>\n  </div>\n </div>',
      type: "pois",
    },
    name: "Stanly Ranch Auberge Collections Napa",
    icon: "uploads/images/icons/stanly_ranch_icon.svg",
  },
  {
    id: "612e367abe5ae5cef4168ac5",
    coordinate: { lat: 38.29295225985651, long: -122.45765962415877 },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    group: "DOWNTOWN",
    modal_: {
      id: "613954ad8eef2b660e73c056",
      content:
        '<div style="padding: 30px; background-color: #fff"> <div style="" class="px-3 py-4 d-flex align-item-center text-center"> <div> <p style="letter-spacing: 0.2rem;">Downtown</p> <h2 class="f-title mt-4"> Downtown Sonoma </h2> </div> </div> </div>',
      type: "pois",
    },
    name: "Downtown Sonoma",
    icon: "uploads/images/icons/icon-downtown-sonoma.svg",
  },
  {
    id: "612e367abe5ae5cef4168dc1",
    coordinate: { lat: 38.2978836795144, long: -122.28796385899975 },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    group: "DOWNTOWN",
    modal_: {
      id: "613954ad8eef2b660e73c056",
      content:
        '<div style="padding: 30px; background-color: #fff"> <div style="" class="px-3 py-4 d-flex align-item-center text-center"> <div> <p style="letter-spacing: 0.2rem;">Downtown</p> <h2 class="f-title mt-4"> Downtown Napa </h2> </div> </div> </div>',
      type: "pois",
    },
    name: "Downtown Napa",
    icon: "uploads/images/icons/icon-downtown-napa.svg",
  },
  {
    id: "612e367abe5ae5cef4168abc",
    coordinate: { lat: 38.2172561, long: -122.2730219 },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    group: "TRANSPORTATION",
    modal: {
      id: "6139547b8eef2b660e73c04d",
      content:
        '<div style="padding: 30px; background-color: #fff"> <div style="" class="px-3 py-4 d-flex align-item-center text-center"> <div> <p style="letter-spacing: 0.2rem;">Transportation</p> <h2 class="f-title mt-4"> Napa County Airport </h2> <p class="mb-0 mt-4"> 2030 Airport Rd,<br> Napa, CA 94558 </p> </div> </div> </div>',
      type: "pois",
    },
    name: "Napa County Airport",
    icon: "uploads/images/icons/airport_icon.svg",
  },
];
