import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { reqSetIsShowVideo, reqSetPage } from "../../reduxs/home/action";
import { PAGES } from "../../constants/options";
import { actIntroduction } from "../../reduxs/scene/action";
import {
  reqGetCustomerProfile,
  reqSetCustomerProfile,
} from "../../reduxs/user/action";
import { useHistory } from "react-router-dom";
import { getImagePath } from "../../helper/media";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";

const Session = (props) => {
  const { isPresentation } = props;
  const [counter, setCounter] = useState(5);
  const history = useHistory();
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.user.customer);
  const customerPreference = useSelector(
    (state) => state.precinctExplore.customerPreference,
  );

  const paths = history.location.pathname.split("/");
  const isPostEngagementPage = paths[paths.length - 1].length === 24; // mongodb object id length
  const customerId = paths[paths.length - 1];

  useEffect(() => {
    if (customer) return;
    dispatch(reqGetCustomerProfile(customerId));
    if (!customerPreference) {
      dispatch(reqGetCustomerPreference(customerId));
    }
  }, [customer]);

  const refCount = useRef(5);
  const interValCountDown = useRef(null);

  useEffect(() => {
    if (isPostEngagementPage) {
      return;
    }
    countTime();
    return () => clearInterval(interValCountDown.current);
  }, []);

  const countTime = () => {
    interValCountDown.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
      refCount.current -= 1;
      if (refCount.current <= 0) {
        handleCloseSession();
        clearInterval(interValCountDown.current);
      }
    }, 1000);
  };

  const handleCloseSession = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    dispatch(reqSetIsShowVideo(false));
    isPresentation ? history.push("/presentation") : history.push("/");
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  return (
    <div className="static-page sessionPage">
      <div className="scroll-view">
        {isPostEngagementPage && (
          <div className="image-page-wrapper">
            <div className="image-page">
              <span className="txtImg">
                A lifestyle
                <br />
                <br />
                beyond compare
              </span>
            </div>
          </div>
        )}
        <div
          className={`${
            isPostEngagementPage ? "post-engagement " : ""
          }content-page`}
          style={{ alignItems: "flex-start" }}
        >
          <span className="title-bottom" style={{ color: "#ffffff" }}>
            {`${customer?.firstname || ""} ${customer?.surname || ""}`},
          </span>

          <span className="content-bottom">
            Here are the items you expressed interest in during your session
            with us at ORLA Dorchester Collection.
          </span>
        </div>
        <img
          src="/uploads/images/post-session-2.jpeg"
          style={{ width: "100%", height: "435px" }}
        />

        {customerPreference?.units?.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <img
                    src="/uploads/images/post-session-3.jpeg"
                    style={{ width: "100%", height: "768.375" }}
                  />
                )}
                <div className="post-engagement-content">
                  <div className="post-engagement-content-units left">
                    <div className="units-title">Unit {item.name}</div>
                    <div className="units-description">{item.description}</div>
                  </div>
                  <div className="post-engagement-content-images right">
                    <img
                      className={`unit-images-1 right`}
                      src={"/uploads" + item.floorPlan.media[0].path}
                    />
                    <img
                      className={`unit-images-2 right`}
                      src="/uploads/images/post-engagement-unit-image-2.jpeg"
                    />
                    <img
                      className={`unit-images-3 right`}
                      src="/uploads/images/post-engagement-unit-image-3.jpeg"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          }
          return (
            <div className="post-engagement-content" key={index}>
              <div className="post-engagement-content-images left">
                <img
                  className={`unit-images-1 left`}
                  src={"/uploads" + item.floorPlan.media[0].path}
                />
                <img
                  className={`unit-images-2 left`}
                  src="/uploads/images/post-engagement-unit-image-2.jpeg"
                />
                <img
                  className={`unit-images-3 left`}
                  src="/uploads/images/post-engagement-unit-image-3.jpeg"
                />
              </div>
              <div className="post-engagement-content-units right">
                <div className="units-title">Unit {item.name}</div>
                <div className="units-description">{item.description}</div>
              </div>
            </div>
          );
        })}
      </div>

      {!isPostEngagementPage && (
        <div className="close-view">
          <span>{`Closing and saving session in ${counter} seconds ...`}</span>
        </div>
      )}
    </div>
  );
};

export default Session;
