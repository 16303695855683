import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getGalleryDetail = (galleryId, data) =>
  client.get(
    endPoint.GALLERY_GET_DETAIL.replace(":galleryId", galleryId),
    data
  );

const findOneGalleryDetail = (data) =>
  client.get(
    endPoint.GALLERY_FIND_ONE,
    data
  );

const createGallery = (data) =>
  defaultClient.uploadFormData(endPoint.GALLERY_CREATE, data);

const getListGallery = (data) =>
  defaultClient.get(endPoint.GALLERY_GET_LIST, data);

const getListGalleryFloor = (data) =>
  defaultClient.get(endPoint.GALLERY_GET_LIST, data);

const updateGallery = (id, data) =>
  defaultClient.editFormData(endPoint.GALLERY_UPDATE.replace(":id", id), data);

const deleteGallery = (id) =>
  defaultClient.delete(endPoint.GALLERY_DELETE.replace(":id", id));

export default {
  getGalleryDetail,
  createGallery,
  getListGallery,
  updateGallery,
  deleteGallery,
  getListGalleryFloor,
  findOneGalleryDetail,
};
