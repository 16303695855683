import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import NotesModal from "./notes-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  reqGetListCustomers,
  reqSetEditableCustomer,
} from "../../reduxs/cms/action";
import CustomerRow from "./customer-row";
import customerApi from "../../apis/api/customer";
import AddNewCustomerModal from "./add-new-customer-modal";
import icSearch from "../../assets/images/icSearchV3.svg";
import CustomerModal from "./new-customer-modal";
import FavoritesModal from "./favorites-modal";

const CMSCustomers = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.cms.customers);
  const editableCustomer = useSelector((state) => state.cms.editableCustomer);
  // const [isShowAddNewCustomerModal, setIsShowAddNewCustomerModal] =
  //   useState(false);

  const [isEditNotes, setIsEditNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState("");
  const [isShowSearch, setShowSearch] = useState(false);
  const [isShowCustomerModal, setShowCustomerModal] = useState(false);
  const [isShowFavoriteModal, setShowFavoriteModal] = useState(false);
  const customerSelected = useRef(null);

  useEffect(() => {
    getListCustomers();
  }, []);

  useEffect(() => {
    getListCustomers();
  }, [search, isSortAsc]);

  const getListCustomers = () => {
    dispatch(
      reqGetListCustomers({
        search,
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const handleHideNotesModal = () => {
    setIsEditNotes(false);
    setNotes("");
  };

  const handleSaveNotesModal = (e) => {
    setIsEditNotes(false);
    setNotes("");
  };

  const handleCancel = () => {
    dispatch(reqSetEditableCustomer());
  };

  const handleSaveCustomer = async () => {
    try {
      const defaultValue = customers.find(
        (customer) => customer.id === editableCustomer?.id
      );
      if (_.isEqual(defaultValue, editableCustomer)) {
        toast.warning("No changes to save");
        return;
      }
      await customerApi.updateCustomer(editableCustomer.id, {
        name: nameInput,
        email: emailInput,
        mobile: mobileInput,
        company: companyInput,
        role: roleInput,
        userGroup: userGroupInput,
      });
      toast.success("Updated customer successfully.");
      dispatch(reqSetEditableCustomer());
      dispatch(reqGetListCustomers());
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const showFavorites = (val) => {
    setShowFavoriteModal(true);
    customerSelected.current = val;
  };

  const onToggleActiveCustomer = async (customer) => {
    try {
      await customerApi.updateCustomer(customer.id, {
        isActive: !customer.isActive,
      });
      toast.success("Updated customer successfully.");
      customer.isActive = !customer.isActive;
    } catch (error) {
      toast.error(error?.message);
      throw error;
    }
  }

  const renderListCustomers = () => {
    return (customers || []).map((customer, idx) => {
      return (
        <CustomerRow
          key={idx}
          item={customer}
          showFavorites={showFavorites}
          onToggleActiveCustomer={onToggleActiveCustomer}
        />
      );
    });
  };

  const renderBtnEdit = () => {
    if (!editableCustomer) return;

    return (
      <>
        <button
          onClick={handleCancel}
          className={`btn fw-bold shadow-0 btn-cancel text-decoration-underline d-block `}
        >
          CANCEL
        </button>
        <button
          onClick={handleSaveCustomer}
          className={`btn fw-bold shadow-0 pe-0 text-decoration-underline d-block`}
        >
          SAVE
        </button>
      </>
    );
  };

  const onCloseModalCustomer = () => {
    dispatch(reqSetEditableCustomer(null));
    getListCustomers();
    setShowCustomerModal(false);
  };

  const onCloseModalFavorites = () => {
    setShowFavoriteModal(false);
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">Customers</h1>
        <button
          className="btn-update-profile btn-customer"
          onClick={() => setShowCustomerModal(true)}
        >
          New profile
        </button>
        {isShowSearch && (
          <div className="input-group page-header__input-search w-50">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="form-control"
              type="text"
              placeholder="Search..."
            />
          </div>
        )}
        <img
          src={icSearch}
          alt="icon-search"
          onClick={() => setShowSearch((v) => !v)}
        />
        {/*<button*/}
        {/*  className={`btn ${!isSortAsc && "sort-desc"}`}*/}
        {/*  onClick={() => toggleSortAsc(!isSortAsc)}*/}
        {/*>*/}
        {/*  <span className="btn-icon-append">*/}
        {/*    <svg*/}
        {/*      width="23.5"*/}
        {/*      height="23.5"*/}
        {/*      viewBox="0 0 12 16"*/}
        {/*      fill="none"*/}
        {/*      xmlns="http://www.w3.org/2000/svg"*/}
        {/*    >*/}
        {/*      <path*/}
        {/*        d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696699 5.24264C0.403806 5.53553 0.403806 6.01041 0.696699 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 16L6.75 1H5.25L5.25 16H6.75Z"*/}
        {/*        fill="#000000"*/}
        {/*      />*/}
        {/*    </svg>*/}
        {/*  </span>*/}
        {/*</button>*/}
      </div>
      <div className="page-body">
        <div className="table-responsive-custom-cms">
          <table className="table-cms">
            <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email Address</th>
              <th>Favorites</th>
              <th>Unique URL</th>
              <th>Active</th>
              <th />
            </tr>
            </thead>
            <tbody>
              {renderListCustomers()}
            </tbody>
          </table>
        </div>
      </div>
      <div className="page-footer d-flex justify-content-end">
        <div className="d-flex flex-grow-1">
          {/*<button*/}
          {/*  className="btn-outline fw-bold"*/}
          {/*  onClick={() => setIsShowAddNewCustomerModal(true)}*/}
          {/*>*/}
          {/*  ADD NEW CUSTOMER*/}
          {/*</button>*/}
        </div>
      </div>
      <NotesModal
        notes={notes}
        isEditNotes={isEditNotes}
        setIsEditNotes={setIsEditNotes}
        handleSaveNotesModal={handleSaveNotesModal}
        handleHideNotesModal={handleHideNotesModal}
      />

      {/*<AddNewCustomerModal*/}
      {/*  show={isShowAddNewCustomerModal}*/}
      {/*  setIsShowAddNewCustomerModal={setIsShowAddNewCustomerModal}*/}
      {/*/>*/}
      {(isShowCustomerModal || editableCustomer) && 
        <CustomerModal
        isShow={isShowCustomerModal}
        onClose={onCloseModalCustomer}
        />
      }
      {isShowFavoriteModal && (
        <FavoritesModal
          isShow={isShowFavoriteModal}
          onClose={onCloseModalFavorites}
          customer={customerSelected.current}
        />
      )}
    </div>
  );
};
export default CMSCustomers;
