export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const handleCollapse = (ev) => {
  const selector =
    ev.target.getAttribute('data-target') || ev.target.getAttribute('href');
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach((target) => {
    target.classList.toggle('show');
  });
};

export const capitializeText = (originalString, textToTransform) => {
  const words = originalString.split(" ");

  const transformedWords = words.map((word) => {
    if (word.toLowerCase() === textToTransform.toLowerCase()) {
      return textToTransform;
    }
    return word;
  });

  return transformedWords.join(" ");
};

export const isTouchDevice = () => {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}
