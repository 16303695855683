import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actSetEditablePropertyColumn } from "../../reduxs/cms/action";
import { toDateFormat, toDateString } from "../../helper/date";
import { toast } from "react-toastify";
import transformHelper from '../../helper/transform';

const PropertyRow = (props) => {
  const { data, setSelectedProperty } = props;
  const dispatch = useDispatch();
  const { tenancy, availabilityStatus, psm, expiryDate, tradingName } = data;
  const editablePropertyColumn = useSelector(
    (state) => state.cms.editablePropertyColumn
  );
  const [tenancyInput, setTenancyInput] = useState(data?.tenancy);
  const [availabilityInput, setAvailabilityInput] = useState(
    data?.availabilityStatus
  );
  const [PSMInput, setPSMInput] = useState(data?.psm);
  const [tradingNameInput, setTradingNameInput] = useState(data?.tradingName);
  const [expiryDateInput, setExpiryDateInput] = useState(
    data?.expiryDate ? data?.expiryDate : ""
  );

  const handleWarningSave = (e) => {
    console.log(editablePropertyColumn, data);
    if (editablePropertyColumn && editablePropertyColumn?.id !== data?.id) {
      toast.warning("Please save before editing another property.");
      e.target.blur();
    }
  };

  useEffect(() => {
    if (tenancy !== tenancyInput) {
      dispatch(
        actSetEditablePropertyColumn({
          id: data.id,
          tenancy: tenancyInput,
        })
      );
    }
  }, [tenancy, tenancyInput]);

  useEffect(() => {
    if (availabilityStatus !== availabilityInput) {
      dispatch(
        actSetEditablePropertyColumn({
          id: data.id,
          availabilityStatus: availabilityInput,
        })
      );
    }
  }, [availabilityStatus, availabilityInput]);

  useEffect(() => {
    if (psm !== Number(PSMInput)) {
      dispatch(
        actSetEditablePropertyColumn({
          id: data.id,
          psm: PSMInput,
        })
      );
    }
  }, [psm, PSMInput]);

  useEffect(() => {
    if (tradingName !== tradingNameInput) {
      dispatch(
        actSetEditablePropertyColumn({
          id: data.id,
          tradingName: tradingNameInput,
        })
      );
    }
  }, [tradingName, tradingNameInput]);

  useEffect(() => {
    if (expiryDateInput) {
      const toISOExpiryDateInput = new Date(expiryDateInput).toISOString();
      if (expiryDate !== toISOExpiryDateInput) {
        dispatch(
          actSetEditablePropertyColumn({
            id: data.id,
            expiryDate: toISOExpiryDateInput,
          })
        );
      }
    }
  }, [expiryDate, expiryDateInput]);

  return (
    <tr style={{textTransform: 'capitalize'}} >
      <td className="col-2">Residence {data?.name || "###"}</td>
      <td className="col-2">{data?.area?.name || "###"}</td>
      <td className="col-2">{transformHelper.formatMoney(data?.price) || "###"}</td>
      <td className="col-1">{data?.bedrooms || "###"}</td>
      <td className="col-2">{data?.aspect?.split('_')?.[0] || "###"}</td>
      <td className="col-2">{data?.type || "###"}</td>
      <td className="col-2">{data?.cellingHeight || "###"}</td>
      <td >
        <button className="edit-property-button"  onClick={() => setSelectedProperty(data)}>
          <span>...</span>
        </button>
      </td>
    </tr>
  );
};

export default PropertyRow;
