import React, { useEffect } from "react";
import "./index.scss";
import Modal from "../../components/modal-base";
import imgExplore from "../../assets/images/explore_ex.png";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CloseModalIcon } from '../../components/svgs/icons';

const dataMedia = [
  {
    url: '/uploads/media/favorites-media-1.jpeg',
  },
  {
    url: '/uploads/media/favorites-media-2.jpeg',
  },
  {
    url: '/uploads/media/favorites-media-3.jpeg',
  },
];

const FavoritesModal = ({ isShow, onClose, customer }) => {
  const dispatch = useDispatch();
  const customerPreference = useSelector(
    (state) => state.precinctExplore.customerPreference,
  );
  const favorites = customerPreference?.units || [];
  // const dataMedia = customerPreference?.units?.map((unit) => unit.gallery.media.) || [];
  console.log(customerPreference);
  useEffect(() => {
    if (customer) {
      getCustomerPreference(customer.id);
    }
  }, [customer]);

  const getCustomerPreference = async (customerId) => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const renderTable = () => {
    return (
      <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8 wrapper-table">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pb-8 pl-4 pr-3 text-left ">
                Residence
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Building
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Price
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Additional
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Additional 2
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Bedrooms
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Aspect
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Residence Type
              </th>
              <th scope="col" className="px-3 py-3.5 text-left ">
                Ceiling Height
              </th>
            </tr>
          </thead>
          <div className="spacing-header" />
          <tbody className="divide-y divide-gray-200">
            {favorites.map((item) => (
              <tr key={item.email}>
                <td className="whitespace-nowrap pl-4 pr-3 text-sm ">
                  Residence {item.name}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  {item.area.name}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  {item.price}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  Filter
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  Filter
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  {item.bedrooms}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  {item.aspect.split("_")[0]}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  {item.type}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  {item.cellingHeight}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const content = (
    <>
      <div className="header-favorite">
        <span className="title">
            {customer.firstname} {customer.surname}
          <span className='title' style={{textTransform: 'lowercase'}}>’s favorites</span>
        </span>
        {renderTable()}
      </div>
      <div className="body-favorite">
        <span className="title">Media</span>
        <div className="media-list">
          {dataMedia.map((item, idx) => {
            return (
              <div key={`image-${idx}`} className="item-image">
                <img src={item.url} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );

  return (
    <Modal
      classNames="modal-favorites"
      open={isShow}
      onHide={onClose}
      isOutSideClose={false}
      isShowBtnClose={false}
      currentColor="#C7A446"
    >
      <span className="close-button-x" onClick={onClose}>
        <CloseModalIcon />
      </span>
      {content}
    </Modal>
  );
};

export default FavoritesModal;
