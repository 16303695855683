/* eslint-disable no-undef */
export const META_DATA = {
  PAGINATION: {
    PAGE_SIZE_NONE: 10000,
    PAGE_SIZE: 1000,
  },
};

export default {
  SITE_URL: process.env.REACT_APP_URL,
  API_URL: process.env.REACT_APP_API_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  LIFX_URL: process.env.REACT_APP_LIFX_URL,
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL
};
