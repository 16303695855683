import React, { useState } from "react";
import "./index.scss";
import Modal from "../../components/modal-base";

const RequestSupportModal = ({ isShow, onClose }) => {
  const [msg, setMsg] = useState("");
  const [sub, setSub] = useState("");
  const [type, setType] = useState("");

  const content = (
    <>
      <div className="modal-form__title">Request support</div>
      <div className="mb-4 wrapper-list-user">
        <div className="form-info w-100 mt-4">
          <label htmlFor="email">Question type *</label>
          <input
            onChange={(e) => setType(e.target.value)}
            id="type"
            placeholder="Select a question type"
          />
        </div>
        <div className="form-info w-100 mt-4">
          <label htmlFor="email">Subject *</label>
          <input
            onChange={(e) => setSub(e.target.value)}
            id="Subject"
            placeholder="Subject"
          />
        </div>
        <div className="form-info w-100 mt-4">
          <label htmlFor="email">Message *</label>
          <textarea
            onChange={(e) => setMsg(e.target.value)}
            id="message"
            placeholder="What would you like support with?"
          />
        </div>
      </div>
      <div className="wrap-button-list-user d-flex justify-content-between">
        <button onClick={onClose}>Create</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </>
  );

  return (
    <Modal
      classNames="modal-request-support"
      open={isShow}
      onHide={onClose}
      isOutSideClose={false}
      currentColor="#C7A446"
    >
      {content}
    </Modal>
  );
};

export default RequestSupportModal;
