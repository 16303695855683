import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMediaUrl, getUploadedMediaUrl } from "../../helper/media";
import { useDispatch, useSelector } from "react-redux";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { FloorplanPlus, FloorplanMinus, FloorplanUp, FloorplanDown, FloorplanLeft, FloorplanRight } from "../svgs/icons";
import socket from "../../helper/socket";
import { FloorPlanView } from './FloorPlanView';

const FloorPlanGallery = (props) => {
  const dispatch = useDispatch();
  const { floorPlanGalleryRef } = props;
  const [media, setMedia] = useState([]);
  const refs = useRef({});

  const [activeSlide, setActiveSlide] = useState({
    index: 0,
  });
  const [zoomSize, setZoomSize] = useState({
    size: 100
  });
  /* Percentages */
  const [backgroundPosition, setbackgroundPosition] = useState({
    x: 50,
    y: 50
  });
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.ZOOM_IN_FLOORPLAN) {
          return zoomIn();
        }

        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          return zoomOut();
        }

        if (content.action === ACTION_NAME.NUDGE_UP_FLOORPLAN) {
          return nudgeUp();
        }

        if (content.action === ACTION_NAME.NUDGE_DOWN_FLOORPLAN) {
          return nudgeDown();
        }

        if (content.action === ACTION_NAME.NUDGE_LEFT_FLOORPLAN) {
          return nudgeLeft();
        }

        if (content.action === ACTION_NAME.NUDGE_RIGHT_FLOORPLAN) {
          return nudgeRight();
        }

        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          return showFilter();
        }
      })
    }
  }, [isPresentation]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    className: 'slides',
    focusOnSelect: true,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    afterChange: (index) => {
      zoomSize.size = 100;
      if(refs.current) {
        if (refs.current[activeSlide.index] != undefined) {
           refs.current[activeSlide.index].style.backgroundSize = `100%`;
         }
         if (refs.current[index] != undefined) {
           refs.current[index].style.backgroundSize = `100%`;
         }
   }  
      activeSlide.index = index;
    }
  };


  useEffect(() => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floorPlan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
    }
  }, [selectedUnit]);

  const zoomIn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ZOOM_IN_FLOORPLAN);
    }
    if (refs.current && refs.current[activeSlide.index] != undefined) {
      refs.current[activeSlide.index].style.backgroundSize = `${zoomSize.size + 10}%`;
    }
    zoomSize.size = zoomSize.size + 10;
  }

  const zoomOut = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN);
    }
    if (zoomSize.size > 50) {
      if (refs.current && refs.current[activeSlide.index] != undefined) {
        refs.current[activeSlide.index].style.backgroundSize = `${zoomSize.size - 10}%`;
      }
      zoomSize.size = zoomSize.size - 10;
    }
  }

  const nudgeUp = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NUDGE_UP_FLOORPLAN);
    }
    backgroundPosition.y -= 15;
    if (refs.current && refs.current[activeSlide.index] != undefined) {
    refs.current[activeSlide.index].style.backgroundPositionY = `${backgroundPosition.y}%`;  
    }
  };


  const nudgeDown = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NUDGE_DOWN_FLOORPLAN);
    }
    backgroundPosition.y += 15;
    if (refs.current && refs.current[activeSlide.index] != undefined) {
    refs.current[activeSlide.index].style.backgroundPositionY = `${backgroundPosition.y}%`;  
    }
  };

  const nudgeLeft = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NUDGE_LEFT_FLOORPLAN);
    }
    backgroundPosition.x -= 15;
    if (refs.current && refs.current[activeSlide.index] != undefined) {
    refs.current[activeSlide.index].style.backgroundPositionX = `${backgroundPosition.x}%`;  
    }
  };

  const nudgeRight = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NUDGE_RIGHT_FLOORPLAN);
    }
    backgroundPosition.x += 15;
    if (refs.current && refs.current[activeSlide.index] != undefined) {
    refs.current[activeSlide.index].style.backgroundPositionX = `${backgroundPosition.x}%`;  
    }
  };

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  return (
    <>
      <div className='wrap-floorplan-gallery'>
        <FloorPlanView url={getUploadedMediaUrl(encodeURI(media[0]?.path))} isPresentation={isPresentation} />
      </div>
      {isTransparent && (
        <div className="wrap-close-btn">
          <div onClick={showFilter}>
            <img src="/icons/add.svg" alt="" />
            <span>Reopen panels</span>
          </div>
          <div onClick={hideFloorplan}>
            <img src="/icons/close.svg" alt="" />
            <span>Close floorplan</span>
          </div>
        </div>
      )}
    </>
  );
};

export default FloorPlanGallery;
