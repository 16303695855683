import React from "react";
import { useState } from "react";
import "./index.scss";
import { ACTION_NAME, PAGES } from "../../constants/options";
import { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import iconSearch from "../../assets/images/icSearchYellow.svg";
import { reqSetPage } from "../../reduxs/home/action";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import logo from "../../assets/images/orla_logo.png";
import socket from "../../helper/socket";

library.add(faSearch);

const TopNav = (props) => {
  const {
    isShow,
    discoverLanding,
    handleClickCube,
    handleClickGallery,
    handleClickLocation,
    handleClickAbout,
    handleClickLifeStyle,
    handleClickTeam,
    handleSearch,
    handleClickExplore,
    isPresentation
  } = props;
  const [isCollapse, setIsCollapse] = useState(false);
  const pageActive = useSelector((state) => state.home.page);
  const [linksDisabled, setLinksDisabled] = useState(false);
  const dispatch = useDispatch();
  const page = useSelector((state) => state.home.page);
  const isShowVideo = useSelector((state) => state.home.isShowVideo);

  const setClickTimer = (localCollapsed) => {
    if (localCollapsed) {
      setLinksDisabled(true);
      return;
    }
    setTimeout(() => {
      setLinksDisabled(false);
    }, 700);
  };

  const onClickCube = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    handleClickCube();
    setIsCollapse(false);
    setClickTimer(false);
  };

  const isActive = (menu) => {
    return pageActive === menu ? "active" : "";
  };

  const startDiscover = () => {
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    setLinksDisabled(false);
    dispatch(reqSetIsShowReplayVideo(false));
  };

  return (
    <div
      className={`wrap-top-nav body-container
      ${(isCollapse || isPresentation) ? "hide" : "show"} ${
        (page === PAGES.ABOUT_PAGE ||
          page === PAGES.TEAM_PAGE ||
          page === PAGES.LIFE_STYLE_PAGE) &&
        "linear-top"
      }`}
    >
      <div className={`nav-menu ${linksDisabled && "disabled"}`}>
        {(isShow || isShowVideo) && (
          <ul className="left">
            <li
              className={isActive(PAGES.EXPLORE_CITY_PAGE)}
              onClick={handleClickExplore}
            >
              <span>EXPLORE</span>
            </li>
            <li
              className={isActive(PAGES.LOCATION_PAGE)}
              onClick={handleClickLocation}
            >
              <span>LOCATION</span>
            </li>
            <li
              className={isActive(PAGES.GALLERY_LANDING_PAGE)}
              onClick={handleClickGallery}
            >
              <span>GALLERY</span>
            </li>
          </ul>
        )}
        <ul className="nav-cube">
          {page !== PAGES.ONBOARD_PAGE && (
            <img
              width="105px"
              height="25px"
              src={logo}
              alt="icon-cube"
              className="cursor-pointer"
              onClick={isShow ? onClickCube : startDiscover}
            />
          )}
        </ul>
        {(isShow || isShowVideo) && (
          <ul className="right">
            <li
              className={isActive(PAGES.ABOUT_PAGE)}
              onClick={handleClickAbout}
            >
              <span>ABOUT</span>
            </li>
            <li
              className={isActive(PAGES.LIFE_STYLE_PAGE)}
              onClick={handleClickLifeStyle}
            >
              <span>LIFESTYLE</span>
            </li>
            <li className={isActive(PAGES.TEAM_PAGE)} onClick={handleClickTeam}>
              <span>PARTNERS</span>
            </li>
          </ul>
        )}
        {(isShow || isShowVideo) && (
          <div className="btn-search" onClick={handleSearch}>
            <img src={iconSearch} alt="search-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNav;
