import React, { useEffect, useRef } from "react";
import SideNav from "../sidenav";
import { useDispatch, useSelector } from "react-redux";
import {
  reqIsShowUnitDetail,
  reqSetIsShowFilter,
} from "../../reduxs/unit-explore/action";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const UnitList = (props) => {
  const { handleUnitClick, isPresentation } = props;

  const refs = useRef({});
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const units = useSelector((state) => state.unitExplore.units);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_FILTER) {
          onShowFilter();
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current && (pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      dispatch(reqIsShowUnitDetail(true));
    }
  }, [selectedUnit]);

  const onShowFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_FILTER);
    }
    dispatch(reqSetIsShowFilter(true));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <SideNav heading="Units" position="left" id="lots" isDark={false}>
      <ul ref={pageRef} className="list-group scroll-unit" onScroll={onScroll}>
        {units.map((item, key) => {
          return (
            <li
              className={`list-group-item ${
                selectedUnit?.id == item.id ? "active" : ""
              }`}
              key={key}
              onClick={() => handleUnitClick(item)}
              ref={(ref) => (refs.current[item.id] = ref)}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
      <div
        className={`wrap-show-filter-btn ${isShowFilter ? "text-gray" : ""}`}
      >
        <div
          className="btn-bottom"
          onClick={() => !isShowFilter && onShowFilter()}
        >
          <img
            className="rotate-img"
            src={
              isShowFilter ? "/icons/arrow-unActive.svg" : "/icons/arrow.svg"
            }
            alt=""
          />
          <span className={!isShowFilter ? "txt-active" : ""}>Show filter</span>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitList;
