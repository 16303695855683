import { Vector2 } from "three";
import { parseIncludes } from "./utils";
import { sharedData } from "./App";

export function makeSSRMaterial(material, SSRtexture) {
  material.onBeforeCompile = (shader) => {
    shader.uniforms = {
      ...shader.uniforms,
      uSSR: { value: SSRtexture, type: "t" },
      uInvScreenDimensions: {
        value: new Vector2(
          1 / (window.innerWidth * sharedData.textBasedPixelRatio),
          1 / (window.innerHeight * sharedData.textBasedPixelRatio),
        ),
      },
      uApplyGamma: { value: false },
    };

    material.userData.shader = shader;
    material.userData.uniforms = shader.uniforms;

    const prependAttributes = `
      uniform sampler2D uSSR;
      uniform vec2 uInvScreenDimensions;
      uniform bool uApplyGamma;
    `;

    shader.fragmentShader = parseIncludes(shader.fragmentShader);

    /*
      vec3 getIBLRadiance( const in vec3 viewDir, const in vec3 normal, const in float roughness ) {
        #ifdef ENVMAP_TYPE_CUBE_UV
          vec3 reflectVec = reflect( - viewDir, normal );
          reflectVec = normalize( mix( reflectVec, normal, roughness * roughness) );
          reflectVec = inverseTransformDirection( reflectVec, viewMatrix );
          vec4 envMapColor = textureCubeUV( envMap, reflectVec, roughness );
          return envMapColor.rgb * envMapIntensity;
        #else
          return vec3( 0.0 );
        #endif
      }
    */
    shader.fragmentShader = shader.fragmentShader.replace(
      "vec4 envMapColor = textureCubeUV( envMap, reflectVec, roughness );",
      `
      vec2 uvs = gl_FragCoord.xy * uInvScreenDimensions;
      vec4 ssrs = texture2D( uSSR, uvs);
      // vec4 envMapColor = vec4(ssrs.xyz * 0.02, 1.0);
      vec4 envMapColor = vec4(ssrs.xyz * 0.5, 1.0);
      `,
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      "radiance += getIBLRadiance( geometry.viewDir, geometry.normal, material.roughness );",
      `
      vec3 iblrad = getIBLRadiance( geometry.viewDir, geometry.normal, material.roughness ); 
      radiance += iblrad * diffuseColor.xyz;
      `,
    );

    // IF YOU TOUCH THIS ONE ALSO UPDATE THE SWITCHTONEMAPPINGMATERIAL
    // IF YOU TOUCH THIS ONE ALSO UPDATE THE SWITCHTONEMAPPINGMATERIAL
    // IF YOU TOUCH THIS ONE ALSO UPDATE THE SWITCHTONEMAPPINGMATERIAL
    shader.fragmentShader = shader.fragmentShader.replace(
      "gl_FragColor = linearToOutputTexel( gl_FragColor );",
      `
      if (uApplyGamma) {
        gl_FragColor = LinearTosRGB( gl_FragColor );
      } else {
        gl_FragColor = linearToOutputTexel( gl_FragColor );
      }
      `,
    );

    shader.fragmentShader = prependAttributes + shader.fragmentShader;
  };

  return material;
}
