import React from "react";

export const ChevronLeft = ({fill = '', style = null}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
      <path
        d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/>
    </svg>
  )
};

export const ArrowCircleIcon = ({fill = '', style = null}) => {
  return (
    <svg style={style} width="22" height="22" viewBox="0 0 22 22" fill={fill || "none"}
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill="#C7A446"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1064 10.125L15 11L14.1064 11.875L9.89362 16L9 15.125L13.2128 11L9 6.875L9.89362 6L14.1064 10.125Z"
            fill="white"/>
    </svg>
  )
};

export const ArrowCircleLightIcon = ({fill = '', style = null}) => {
  return (
    <svg style={style} width="22" height="22" viewBox="0 0 22 22" fill={fill || "none"}
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="10.5" stroke="#C7A446"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1064 10.125L15 11L14.1064 11.875L9.89362 16L9 15.125L13.2128 11L9 6.875L9.89362 6L14.1064 10.125Z"
            fill="#C7A446"/>
    </svg>
  )
};

export const FloorIcon = ({fill = '', style = null}) => {
  return (
    <svg style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="floor">
        <circle id="Ellipse 1" cx="10" cy="10" r="10" fill={fill || "#C7A446"}/>
        <path id="Union" fillRule="evenodd" clipRule="evenodd"
              d="M10.5115 5.04072C10.4006 4.98648 10.271 4.98642 10.1601 5.04057L4.77289 7.67131C4.61429 7.72443 4.5 7.87422 4.5 8.05069L4.5 8.05435L4.5 11.6192C4.5 11.7731 4.58826 11.9133 4.727 11.9798L10.1155 14.5647C10.2241 14.6168 10.3504 14.6172 10.4594 14.5657L15.9299 11.9809C16.0698 11.9148 16.159 11.7739 16.159 11.6192V8.06984C16.1591 8.06766 16.1592 8.06547 16.1592 8.06328C16.1595 8.04921 16.1591 8.03523 16.158 8.0214C16.1462 7.85827 16.0365 7.72225 15.8874 7.67175L10.5115 5.04072ZM15.359 8.66495L10.6821 10.7013C10.6863 10.7245 10.6885 10.7484 10.6885 10.7729V13.5726L15.359 11.3658V8.66495ZM9.89516 10.6996L5.3 8.66852V11.3674L9.88847 13.5685V10.7729C9.88847 10.7479 9.89077 10.7234 9.89516 10.6996ZM10.29 9.99946L5.84856 8.03632L10.3354 5.84524L14.806 8.03318L10.29 9.99946Z"
              fill="white"/>
      </g>
    </svg>
  )
};

export const IconCloseButton = ({fill = '', style = null}) => {
  return (
    <svg style={style} fill={fill || '#0000'} viewBox="0 0 26 24" xmlns="http://www.w3.org/2000/svg">
      <g id="close button">
        <path id="Vector" d="M1 1L25 22.8182M25 1L1 22.8182" stroke={fill || '#0000'} strokeMiterlimit="10"
              strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  )
};

export const IconMarker = ({fill = '', style = null}) => {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 50">
      <g id="location">
        <path id="Vector"
              d="M19.6429 0C14.4352 0.0061437 9.44248 2.07763 5.76007 5.76005C2.07765 9.44246 0.0061646 14.4351 2.0898e-05 19.6429C-0.00621641 23.8986 1.38391 28.0389 3.95716 31.4286C3.95716 31.4286 4.49288 32.1339 4.58038 32.2357L19.6429 50L34.7125 32.2268C34.7911 32.1321 35.3286 31.4286 35.3286 31.4286L35.3304 31.4232C37.9023 28.035 39.2918 23.8967 39.2857 19.6429C39.2796 14.4351 37.2081 9.44246 33.5257 5.76005C29.8433 2.07763 24.8506 0.0061437 19.6429 0ZM19.6429 26.7857C18.2302 26.7857 16.8492 26.3668 15.6745 25.5819C14.4999 24.7971 13.5844 23.6815 13.0437 22.3763C12.5031 21.0711 12.3617 19.6349 12.6373 18.2494C12.9129 16.8638 13.5932 15.591 14.5921 14.5921C15.5911 13.5931 16.8638 12.9129 18.2494 12.6372C19.635 12.3616 21.0711 12.5031 22.3763 13.0437C23.6815 13.5843 24.7971 14.4999 25.5819 15.6745C26.3668 16.8491 26.7857 18.2301 26.7857 19.6429C26.7834 21.5365 26.0301 23.352 24.691 24.691C23.352 26.03 21.5366 26.7834 19.6429 26.7857Z"
              fill={fill || "#C7A446"}/>
      </g>
    </svg>
  )
};

export const LeftArrowIcon = ({fill = '', style = null}) => {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="21" height="35" viewBox="0 0 21 35" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.12766 14.4375L-4.7876e-08 17.5L3.12766 20.5625L17.8723 35L21 31.9375L6.25532 17.5L21 3.0625L17.8723 0L3.12766 14.4375Z"
            fill={fill || '#C7A446'}/>
    </svg>)
};
export const RightArrowIcon = ({fill = '', style = null}) => {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="21" height="35" viewBox="0 0 21 35" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.8723 14.4375L21 17.5L17.8723 20.5625L3.12766 35L0 31.9375L14.7447 17.5L0 3.0625L3.12766 0L17.8723 14.4375Z"
            fill={fill || '#C7A446'}/>
    </svg>
  )
};
export const FloorplanPlus = ({fill = '', style = null}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#C7A446"/>
      <path d="M10.4274 3.59711L9.23199 3.59711L9.23199 9.2324L3.59671 9.2324V10.4278L9.23199 10.4278L9.23199 16.063L10.4274 16.063L10.4274 10.4278L16.0626 10.4278V9.2324L10.4274 9.2324L10.4274 3.59711Z" fill="white"/>
    </svg>

  )
};
export const FloorplanMinus = ({fill = '', style = null}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="#C7A446"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.23199 9.2324H10.4274L16.0626 9.2324V10.4278L10.4274 10.4278H9.23199L3.59671 10.4278V9.2324L9.23199 9.2324Z" fill="white"/>
</svg>
  )
};
export const FloorplanLeft = ({fill = '', style = null}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" fill="#C7A446"/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.17619 9.2041L6.36381 9.99956L7.17619 10.795L11.006 14.545L11.8184 13.7496L7.98857 9.99956L11.8184 6.24956L11.006 5.4541L7.17619 9.2041Z" fill="white"/>
</svg>
  )
};
export const FloorplanRight = ({fill = '', style = null}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="#C7A446"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.8238 9.2041L13.6362 9.99956L12.8238 10.795L8.99402 14.545L8.18164 13.7496L12.0114 9.99956L8.18164 6.24956L8.99402 5.4541L12.8238 9.2041Z" fill="white"/>
</svg>

  )
};
export const FloorplanUp = ({fill = '', style = null}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" transform="matrix(0 -1 -1 0 20 20)" fill="#C7A446"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.7959 7.17619L10.0004 6.36381L9.20499 7.17619L5.45499 11.006L6.25044 11.8184L10.0004 7.98857L13.7504 11.8184L14.5459 11.006L10.7959 7.17619Z" fill="white"/>
</svg>
  )
};
export const FloorplanDown = ({fill = '', style = null}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" transform="rotate(90 10 10)" fill="#C7A446"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.7959 12.8238L10.0004 13.6362L9.20499 12.8238L5.45499 8.99402L6.25044 8.18164L10.0004 12.0114L13.7504 8.18164L14.5459 8.99402L10.7959 12.8238Z" fill="white"/>
</svg>

  )
}
export const ArrowRightFill = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow">
        <circle id="Ellipse 1" cx="11" cy="11" r="11" fill="black" />
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1064 10.125L15 11L14.1064 11.875L9.89362 16L9 15.125L13.2128 11L9 6.875L9.89362 6L14.1064 10.125Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const CloseModalIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="CMS Close" clipPath="url(#clip0_2646_4088)">
    <path id="Vector" d="M23.0002 45.4249C35.3852 45.4249 45.4252 35.3849 45.4252 23C45.4252 10.615 35.3852 0.574951 23.0002 0.574951C10.6152 0.574951 0.575195 10.615 0.575195 23C0.575195 35.3849 10.6152 45.4249 23.0002 45.4249Z" stroke="white"/>
    <path id="Vector_2" d="M15.5461 31.8094L31.8096 15.5459L30.4543 14.1906L14.1908 30.4541L15.5461 31.8094Z" fill="white"/>
    <path id="Vector_3" d="M14.1906 15.5461L30.4541 31.8096L31.8094 30.4543L15.5459 14.1908L14.1906 15.5461Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_2646_4088">
    <rect width="46" height="46" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}
