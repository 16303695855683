import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import "./index.scss";
import { ReactComponent as Close } from "../../assets/images/close.svg";

const VideoIntro = ({ isIntro = false, onHide }) => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();

  const onSkipVideo = () => {
    dispatch(reqSetIsShowReplayVideo(false));
  };

  return (
    <div className="wrap-video-intro">
      <video
        ref={videoRef}
        autoPlay={true}
        onEnded={() => onSkipVideo()}
        preload="auto"
        muted={false}
        id="intro-video-2"
      >
        <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
      </video>
      {!isIntro && (
        <button onClick={() => onSkipVideo()} className="btn-skip-video">
          Skip video
        </button>
      )}
      {isIntro && (
        <div className="btn-close-modal" onClick={onHide}>
          <Close fill="#C7A446" />
        </div>
      )}
    </div>
  );
};

export default VideoIntro;
