import { parseIncludes } from "./utils";

export function makeApplyGammaMaterial(material) {
  material.onBeforeCompile = (shader) => {
    shader.uniforms = {
      ...shader.uniforms,
      uApplyGamma: { value: false },
    };

    material.userData.shader = shader;
    material.userData.uniforms = shader.uniforms;

    const prependAttributes = `
      uniform bool uApplyGamma;

      // #include <tonemapping_pars_fragment>
    `;

    shader.fragmentShader = parseIncludes(shader.fragmentShader);

    
    // IF YOU TOUCH THIS ONE ALSO UPDATE THE SSRSTANDARDMATERIAL
    // IF YOU TOUCH THIS ONE ALSO UPDATE THE SSRSTANDARDMATERIAL
    // IF YOU TOUCH THIS ONE ALSO UPDATE THE SSRSTANDARDMATERIAL
    shader.fragmentShader = shader.fragmentShader.replace(
      "gl_FragColor = linearToOutputTexel( gl_FragColor );",
      `
      if (uApplyGamma) {
        gl_FragColor = LinearTosRGB( gl_FragColor );
      } else {
        gl_FragColor = linearToOutputTexel( gl_FragColor );
      }
      `
    );
  
    shader.fragmentShader = prependAttributes + shader.fragmentShader;
  };  

  return material;
}
