import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopNav from "../../components/top-nav";
import Loading from "../../components/loading";
import _3dSettings from "../../apis/api/_3dSettings";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useHistory } from "react-router-dom";

import Location from "../../components/location";
import BottomNavigation from "../../components/bottom-navigation";
import Gallery from "../../components/gallery";
import RightPanel from "../../components/right-panel";

import {
  reqSetSelectedUnit,
  reqIsShowGallery,
  reqIsShowFloorplan,
  reqSetIsShowFilter,
  reqSetUnitQuery,
} from "../../reduxs/unit-explore/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import {
  reqSetIsExpandNav,
  reqSetPage,
  reqSetIsShowEndGuideTenantSession,
  reqSetActiveEndGuideTenantSessionId,
  reqSetIsTransparent,
  reqSetIsPresentation,
} from "../../reduxs/home/action";
import { PAGES, LAYERS, ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { threePosition } from "../../helper/threeHeper";
import {
  reqSetIsShowReplayVideo,
} from "../../reduxs/precinct-explore/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import { setColor2 } from "../../helper/threeHeper";
import { reqSetIsShowGalleryModal } from "../../reduxs/district-future-detail/action";
import TransportOptionDistrict from "../../components/transport-options-district";
import EndGuideTenantSessionModal from "../../components/guide-sesions/end-guide-tenant-session-modal";
import { actIntroduction } from "../../reduxs/scene/action";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import VideoIntro from "../../components/video-intro";
import PostSessionPopup from "../../components/post-session-popup";
import HomeGallery from "../../components/home-gallery";
import VirtualModal from "../../components/home-gallery/virtual-modal";
import BookingAppointment from "../../components/booking-appointment";
import About from "../../components/about";
import LifeStyle from "../../components/life-style";
import Team from "../../components/team";
import SearchV2 from "../../components/search-v2";
import GalleryLanding from "../../components/gallery-landing";
import UnitExplore from "../../components/unit-explore";
import socket from "../../helper/socket";
import webSocket from "../../helper/websocket";
import Session from "../../components/session";
import { Vector3 } from "three";
import { isTouchDevice } from "../../helper/utils";
import { toast } from "react-toastify";

const ReactUI = (props) => {
  const {
    setIsIntroduction,
    controls,
    refScene,
    setActiveObjectIds,
    resetActiveColorModel,
    activeObjectIds,
    isPresentation
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const page = useSelector((state) => state.home.page);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const transportOptionDistricts = useSelector(
    (state) => state.home.transportOptionDistricts
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const isShowVirtualModal = useSelector(
    (state) => state.gallery.isShowVirtualModal
  );
  const isShowBookingAppointmentForm = useSelector(
    (state) => state.home.isShowBookingAppointmentForm
  );
  const customer = useSelector((state) => state.user.customer);
  const authUser = useSelector((state) => state.user.data);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const [isShowSearch, setShowSearchModal] = useState(false);
  const [isShowBottomNav, showBottomNav] = useState(true);
  let position1 = new Vector3();

  const establishSocket = (authUser) => {
    if(!authUser) return

    webSocket.connect(authUser);
    socket.connect(authUser);

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        handleApplyAction(content.action, content.data);
      });

      socket.on(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, ({ content }) => {
        if (controls.current != null && !controls.current.isMoving) {
          position1.x = content.position.x;
          position1.y = content.position.y;
          position1.z = content.position.z;

          controls.current.object.position.copy(position1);
          controls.current.object.quaternion.set(content.quaternion.x, content.quaternion.y, content.quaternion.z, content.quaternion.w);

          controls.current.object.zoom = content.zoom;
          controls.current.object.updateProjectionMatrix();
        }
      });
    }
  }

  useEffect(() => {
    isPresentation && dispatch(reqSetIsPresentation(true));
  }, []);

  useEffect(() => {
    establishSocket(authUser)

    const root = window.document.getElementById("root")

    const handleMouseMove = _.debounce((e) => {
      if(!socket.socket.connected){
        establishSocket(authUser)
      }
    }, 150)

    const touchDevice = isTouchDevice()

    if(touchDevice) {
      root.addEventListener("touchstart", handleMouseMove)
    } else {
      root.addEventListener("mouseover", handleMouseMove)
    }

    return () => {
      if(socket.connected) {
        socket.disconnect();
      }

      if(touchDevice) {
        root.removeEventListener("touchstart", handleMouseMove)
      } else {
        root.removeEventListener("mouseover", handleMouseMove)
      }
    };
    
  }, [authUser]);

  useEffect(() => {
    if (selectedUnit) {
      setActiveObjectIds([selectedUnit['3d_asset'].id]);
    }
  }, [selectedUnit]);

  const handleApplyAction = (action, data) => {
    if (action == ACTION_NAME.CLICK_UNIT_EXPLORE_MENU) {
      return handleClickExplore();
    }

    if (action == ACTION_NAME.CLICK_LOCATION_MENU) {
      return handleClickLocation();
    }

    if (action == ACTION_NAME.CLICK_GALLERY_MENU) {
      return handleClickGallery();
    }

    if (action == ACTION_NAME.CLICK_CUBE_MENU) {
      return handleClickCube();
    }

    if (action == ACTION_NAME.CLICK_ABOUT_MENU) {
      return handleClickAbout();
    }

    if (action == ACTION_NAME.CLICK_LIFE_STYLE_MENU) {
      return handleClickLifeStyle();
    }

    if (action == ACTION_NAME.CLICK_TEAM_MENU) {
      return handleClickTeam();
    }

    if (action === ACTION_NAME.CLICK_UNIT) {
      return handleUnitClick(data?.unit);
    }

    if (action === ACTION_NAME.CLICK_SEARCH_MENU) {
      return handleSearch();
    }

    if (action === ACTION_NAME.CLOSE_PANO_VIEWER) {
      return handleClosePanoViewer();
    }

    if (action === ACTION_NAME.EXPLORE_SHOW_FILTER) {
      let showFilter = data?.showFilter ?? false;
      dispatch(reqSetIsShowFilter(showFilter));
      return;
    }

    if(action === ACTION_NAME.TOAST_MESSAGE) {
      toast[data.type](data.message, {
        toastId: "socket-toast"
      });
      return
    }
  }

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(
      (setting) => setting.name == page
    );
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null) {
      return <div />;
    }
    controls.current.currentPage = page;
    const setting = settings[0];

    setting.active_objects != null &&
      setting.active_objects.length > 0 &&
      setActiveObjectIds(setting.active_objects);

    if (setting.camera && setting.camera_look_at_position && !selectedUnit) {
      const camPosition = threePosition(setting.camera);
      const camLookAtPosition = threePosition(setting.camera_look_at_position);
      controls.current.isMoving = true
      controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        () => {
          controls.current.isMoving = false
          }
      );
    }

    if (
      Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)
    ) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(0);
      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }
    }
    return <div />;
  });
  HandleSetActivePage.displayName = "HandleSetActivePage";

  const handleClickCube = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      webSocket.send(
        JSON.stringify({ FG: { FG_0001: "MOV", MSG_ID: messageId } })
      );
      socket.shareMedia({ FG: { FG_0001: "MOV", MSG_ID: messageId } });
    }
    resetState();
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetIsExpandNav(!isNavExpand));
  };

  const discoverLanding = () => {
    resetState();
    dispatch(reqSetIsExpandNav(!isNavExpand));
  }

  const handleClickGallery = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLICK_GALLERY_MENU);
      socket.shareMedia({ BG: { BG_0002: "MOV", MSG_ID: messageId } });
      webSocket.send(
        JSON.stringify({ BG: { BG_0002: "MOV", MSG_ID: messageId } })
      );
    }

    resetState();
    dispatch(reqSetPage(PAGES.GALLERY_LANDING_PAGE));
    analytics.track("View Gallery", {});
  };

  const handleClickLocation = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LOCATION_MENU);
      webSocket.send(
        JSON.stringify({ BG: { BG_0003: "MOV", MSG_ID: messageId } })
      );
      socket.shareMedia({ BG: { BG_0003: "MOV", MSG_ID: messageId } });
    }
    resetState();
    dispatch(reqSetPage(PAGES.LOCATION_PAGE));
  };

  const handleClickExplore = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_EXPLORE_MENU);
      webSocket.send(
        JSON.stringify({ BG: { BG_0001: "MOV", MSG_ID: messageId } })
      );
      socket.shareMedia({ BG: { BG_0001: "MOV", MSG_ID: messageId } });
    }

    resetState();
    dispatch(reqSetIsShowFilter(true));
    dispatch(reqSetUnitQuery({}));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const handleClickAbout = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLICK_ABOUT_MENU);
      webSocket.send(
        JSON.stringify({ BG: { BG_0005: "MOV", MSG_ID: messageId } })
      );
      socket.shareMedia({ BG: { BG_0005: "MOV", MSG_ID: messageId } });
    }

    resetState();
    dispatch(reqSetPage(PAGES.ABOUT_PAGE));
  };

  const handleClickLifeStyle = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LIFE_STYLE_MENU);
      socket.shareMedia({ BG: { BG_0004: "MOV", MSG_ID: messageId } });
      webSocket.send(
        JSON.stringify({ BG: { BG_0004: "MOV", MSG_ID: messageId } })
      );
    }

    resetState();
    dispatch(reqSetPage(PAGES.LIFE_STYLE_PAGE));
  };

  const handleClickTeam = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLICK_TEAM_MENU);
      socket.shareMedia({ BG: { BG_0006: "MOV", MSG_ID: messageId } });
      webSocket.send(
        JSON.stringify({ BG: { BG_0006: "MOV", MSG_ID: messageId } })
      );
    }

    resetState();
    dispatch(reqSetPage(PAGES.TEAM_PAGE));
  };

  const handleSearch = () => {
    socket.emitUIActionEvent(ACTION_NAME.CLICK_SEARCH_MENU);
    setShowSearchModal(true);
  };

  const handleClickEndGuideTenantSession = (customerID) => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    resetState();
    dispatch(reqSetActiveEndGuideTenantSessionId(customerID));
    dispatch(reqSetIsShowEndGuideTenantSession(true));
    dispatch(reqSetPage(PAGES.END_GUIDE_TENANT_SESSION_PAGE));
  };

  const handleClickTenantEndSession = () => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push("/");
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  const handleClickTransportOptions = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const handleClickTransportOptionDistricts = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const resetState = () => {
    handleClickTransportOptions([], {});

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;
    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach((id) => {
          let object = refScene.current?.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        });
      });
    }

    resetActiveColorModel({});
    setActiveObjectIds([]);
    dispatch(reqSetSelectedUnit(""));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
    dispatch(reqSetUnitQuery({}));
    dispatch(unitExploreAct.reqFilterUnitArea());
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAspect());
    dispatch(unitExploreAct.reqFilterUnitType());
    dispatch(unitExploreAct.reqFilterUnitCellingHeight());
    dispatch(unitExploreAct.reqFilterUnitPrice());
  };

  const handleUnitClick = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT, {
        unit: item
      })
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }

    if (!controls.current || !item['3d_asset']) return;

    const object = item["3d_asset"];
    handleMoveCamera(object);
    dispatch(reqSetSelectedUnit(item.id));
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  };

  const handleAreaClick = (area) => {
    if (!controls.current) return;

    if (!area["3d_asset"]) {
      return setActiveObjectIds([]);
    }

    handleMoveCamera(area["3d_asset"]);
  };

  const handleMoveCamera = (object, onCompleted = () => { }) => {
    if (!object.xyz_position) return;

    const position = threePosition(object.xyz_position);

    if (object.cam_position != null) {
      const camPosition = threePosition(object.cam_position);
      const camLookAtPosition =
        object.cam_focus_point_position != null
          ? threePosition(object.cam_focus_point_position)
          : position;
      controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, onCompleted);
    }
  };

  const renderTransportOptionDistricts = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter((setting) => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (
      setting.features == null ||
      !setting.features.transport_option_districts
    ) {
      return <div />;
    }

    return (
      <TransitionGroup>
        {
          <CSSTransition timeout={1000} classNames="fade-item">
            <TransportOptionDistrict
              activeObjectIds={activeObjectIds}
              refScene={refScene}
              handleClickTransportOptionDistricts={
                handleClickTransportOptionDistricts
              }
            />
          </CSSTransition>
        }
      </TransitionGroup>
    );
  };

  const onReplayVideo = () => {
    if (!isPresentation) {
      const messageId = Date.now();
      webSocket.send(
        JSON.stringify({
          FG: { "FG_Orla_IR-Film-01": "MOV", MSG_ID: messageId },
        })
      );
      socket.shareMedia({
        FG: { "FG_Orla_IR-Film-01": "MOV", MSG_ID: messageId },
      });
    }

    dispatch(reqSetIsShowReplayVideo(true));
  };

  return (
    <>
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      <TransitionGroup>
        {page == PAGES.END_GUIDE_TENANT_SESSION_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <EndGuideTenantSessionModal resetState={resetState} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TopNav
        isPresentation={isPresentation}
        isShow={page !== PAGES.ONBOARD_PAGE}
        isLoading={isLoading}
        discoverLanding={discoverLanding}
        handleClickCube={handleClickCube}
        handleClickGallery={handleClickGallery}
        handleClickLocation={handleClickLocation}
        handleClickExplore={handleClickExplore}
        handleClickAbout={handleClickAbout}
        handleClickLifeStyle={handleClickLifeStyle}
        handleClickTeam={handleClickTeam}
        handleSearch={handleSearch}
      />
      {page == PAGES.ONBOARD_PAGE && (
        <Loading isPresentation={isPresentation} isLoading={isLoading} setIsIntroduction={setIsIntroduction} />
      )}
      <TransitionGroup>
        {page == PAGES.LOCATION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Location isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>


      <TransitionGroup>
        {isShowBottomNav && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <BottomNavigation
              isPresentation={isPresentation}
              resetState={resetState}
              handleClickEndGuideTenantSession={handleClickEndGuideTenantSession}
              handleClickTenantEndSession={handleClickTenantEndSession}
            />
          </CSSTransition>
        )}
      </TransitionGroup>

      {renderTransportOptionDistricts()}

      <CSSTransition
        in={page === PAGES.UNIT_EXPLORER_PAGE}
        timeout={1000}
        classNames="fade-left"
        unmountOnExit
      >
        <UnitExplore
          isPresentation={isPresentation}
          handleUnitClick={handleUnitClick}
          setActiveObjectIds={setActiveObjectIds}
          handleAreaClick={(area) => handleAreaClick(area)}
        />
      </CSSTransition>

      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <Gallery />
          </CSSTransition>
        )}
      </TransitionGroup>

      <RightPanel
        page={page}
        handleClickTransportOptions={handleClickTransportOptions}
      />
      <TransitionGroup>
        {isShowReplayVideo && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <VideoIntro />
          </CSSTransition>
        )}
      </TransitionGroup>
      <PostSessionPopup />
      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <HomeGallery />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowVirtualModal && (
          <CSSTransition timeout={200} classNames="fade-item">
            <VirtualModal />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowBookingAppointmentForm && (
          <CSSTransition timeout={200} classNames="fade-item">
            <BookingAppointment />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.ABOUT_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <About isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.LIFE_STYLE_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <LifeStyle isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.TEAM_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Team isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowSearch && (
          <CSSTransition
            timeout={200}
            classNames="fade-item"
            unmountOnExit
            resetState={() => setShowSearchModal(false)}
          >
            <SearchV2 isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.GALLERY_LANDING_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <GalleryLanding isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SESSION_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Session isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default ReactUI;
