import React, { useEffect, useState } from "react";
import Modal from "../../components/modal-base";
import { useSelector } from "react-redux";
import "./index.scss";
import customerApi from "../../apis/api/customer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import authApi from "../../apis/api/auth";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

const EditUserProfileModal = ({ isShowModal, onClose, data }) => {
  const { register, handleSubmit } = useForm({ values: data });

  const handleEditProfile = async (data) => {
    try {
      data.userGroup = data?.userGroup?.id;

      const res = await authApi.updateProfile(data);
      if (res && res?.message?.includes("Errors")) {
        toast.error("Email exist, Please try again!");
        return;
      } else {
        onClose();
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Modal
      classNames="modal-request-support"
      open={isShowModal}
      onHide={onClose}
      isOutSideClose={false}
      currentColor="#C7A446"
    >
      <div className="modal-form__title">Update profile</div>
      <Form onSubmit={handleSubmit(handleEditProfile)}>
        <div className="mb-4 wrapper-list-user">
          <div className="wrapper-info">
            <div className="form-info w-100 mt-4">
              <label htmlFor="firstName">First name *</label>
              <input
                id="firstName"
                placeholder="First name"
                {...register("firstname")}
              />
            </div>
            <div className="form-info w-100 mt-4">
              <label htmlFor="lastname">Last name *</label>
              <input
                id="lastName"
                placeholder="Last name"
                {...register("surname")}
              />
            </div>
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="email">Email *</label>
            <input id="Email" placeholder="Email" {...register("email")} />
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="phone">Phone number *</label>
            <input
              id="phone"
              placeholder="Phone number"
              {...register("phone")}
            />
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="Staff type">Staff type</label>
            <input
              id="Staff type"
              placeholder="Staff type"
              {...register("staffType")}
            />
          </div>

          <div className="wrapper-info">
            <div className="form-info w-100 mt-4">
              <label htmlFor="email">Password *</label>
              <input
                id="password"
                placeholder="Password"
                {...register("password")}
              />
            </div>
            <div className="form-info w-100 mt-4">
              <label htmlFor="email">Confirm password *</label>
              <input
                id="confirm password"
                placeholder="Confirm password"
                {...register("confirmPassword")}
              />
            </div>
          </div>
        </div>
        <div className="wrap-button-list-user d-flex justify-content-between">
          <button type="submit">Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditUserProfileModal;
