import React, { useMemo } from "react";
import { Color, DoubleSide, MeshStandardMaterial, SRGBColorSpace } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { makeApplyGammaMaterial } from "./applyGammaMaterial";
import { sharedData } from "./App";
import { makeSSRMaterial } from "./ssrMaterial";
import { useState } from "react";
import config from "../../../config";

let modelsInitalized = false;

function getFBX(path) {
  return new Promise((res, rej) => {
    new FBXLoader().load(
      path,
      (obj) => {
        res(obj);
      },
      null,
      (err) => {
        console.log(path, "errored out");
      },
    );
  });
}

function useModels(path) {
  const [modelsArray, setModelsArray] = useState([]);

  useMemo(() => {
    Promise.all([
      getFBX(
        `${config.SITE_URL}/uploads/newcanvasmodels/BG_BUILDING_CLOSE.fbx`,
      ),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/BG_CITY.fbx`),
      // getFBX(
      //   `${config.SITE_URL}/uploads/newcanvasmodels/Ground_Additional.fbx`,
      // ),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/LAND_BLACK.fbx`),
      getFBX(
        `${config.SITE_URL}/uploads/newcanvasmodels/ROAD_IN_FRONT_OF_ORLA_03.fbx`,
      ),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/ROADS_DECORATIVE.fbx`),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/ROADS_DETAILS_01.fbx`),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/SEA_BLUE.fbx`),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/Water_Feature.fbx`),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/Infinity.fbx`),
      getFBX(
        `${config.SITE_URL}/uploads/newcanvasmodels/Orla_Main_Podium_Glass.fbx`,
      ),
      getFBX(`${config.SITE_URL}/uploads/newcanvasmodels/Site_Paving.fbx`),
    ]).then((res) => {
      setModelsArray(res);
    });
  }, []);

  return modelsArray;
}

export function Models() {
  const modelsArr = useModels();
  // const modelsArr = getModels(fbxs);
  const [
    BG_BUILDING_CLOSE,
    BG_CITY,
    // Ground_Additional,
    LAND_BLACK,
    ROAD_IN_FRONT_OF_ORLA_03,
    ROADS_DECORATIVE,
    ROADS_DETAILS_01,
    SEA_BLUE,
    Water_Feature,
    Infinity,
    Orla_Main_Podium_Glass,
    Site_Paving,
  ] = modelsArr;

  // useEffect(() => {
  //   new FBXLoader().load('models/FBX_Standard_02/BG_BUILDING_CLOSE.fbx', (obj) => {
  //     console.log(obj);
  //   });
  // }, []);

  // need usememo instead of useEffect because it runs before mounting the DOM
  // here's the issue, the <primitive> component is referencing the mesh, not the group
  // thus after the first render call the groups will be empty, because the meshes
  // will be moved into r3f's state-graph, and the values referenced in this callback
  // would be undefined
  useMemo(() => {
    if (modelsArr.length === 0) return;

    if (modelsInitalized) return;
    modelsInitalized = true;

    const models = [
      BG_BUILDING_CLOSE,
      BG_CITY,
      // Ground_Additional,
      LAND_BLACK,
      ROAD_IN_FRONT_OF_ORLA_03,
      ROADS_DECORATIVE,
      ROADS_DETAILS_01,
      SEA_BLUE,
      Water_Feature,
      Infinity,
      Orla_Main_Podium_Glass,
      Site_Paving,
    ];

    const groundMeshesModels = [
      LAND_BLACK,
      ROAD_IN_FRONT_OF_ORLA_03,
      ROADS_DECORATIVE,
      ROADS_DETAILS_01,
      SEA_BLUE,
    ];

    models.forEach((object) => {
      // I'll assume moving forward that all .fbx objects have a
      // single Mesh child shaded with PhongMaterial
      let mesh = object.children[0];
      let prevMaterial = mesh.material;
      mesh.position.set(mesh.position.x, mesh.position.y, mesh.position.z);
      // mesh.position.set(mesh.position.x * 0.1, mesh.position.y * 0.1, mesh.position.z * 0.1);
      // mesh.scale.set(0.1, 0.1, 0.1);

      prevMaterial.map.colorSpace = SRGBColorSpace;
      if (groundMeshesModels.includes(object)) {
        let mat = new MeshStandardMaterial({
          envMap: sharedData.envMap,
          envMapIntensity: 32,
          color: new Color(1, 1, 1),
          map: prevMaterial.map,
          roughness: 0,
          metalness: 1,
        });

        if (object == LAND_BLACK) {
          mat.envMapIntensity = 55;
        }

        if (
          object == ROADS_DECORATIVE ||
          object == ROADS_DETAILS_01 ||
          object == ROAD_IN_FRONT_OF_ORLA_03
        ) {
          mat = new MeshStandardMaterial({
            envMap: sharedData.envMap,
            envMapIntensity: 25,
            color: new Color(1, 0.8, 0.67),
            map: prevMaterial.map,
            roughness: 0,
            metalness: 0.7,
          });

          if (object == ROAD_IN_FRONT_OF_ORLA_03) {
            mat.color.set(1, 0.9, 0.85);
          }
        }

        mesh.material = makeSSRMaterial(mat, sharedData.ssrRT.texture);
        sharedData.stencilTestScene.add(mesh);
      } else {
        let mat = new MeshStandardMaterial({
          color: prevMaterial.color,
          emissive: new Color(1, 1, 1).multiplyScalar(1),
          emissiveMap: prevMaterial.map,
        });

        if (object == BG_CITY || object == BG_BUILDING_CLOSE) {
          mat.emissive.multiplyScalar(1.25);
        }

        mesh.material = makeApplyGammaMaterial(mat);
        // sceneRef.add(mesh);
      }

      if (
        object == Infinity ||
        object == Orla_Main_Podium_Glass ||
        object == Site_Paving ||
        object == Water_Feature
        // || object == Ground_Additional
      ) {
        mesh.material.emissiveMap.anisotropy = 4;
        mesh.meshId = 1.0;
      }
    });
  }, [modelsArr]);

  if (modelsArr.length === 0) return null;

  // only add to the scene graph the non-stencilTestScene meshes
  return (
    <>
      <primitive object={BG_BUILDING_CLOSE.children[0]} />
      <primitive object={BG_CITY.children[0]} />
      <primitive object={Water_Feature.children[0]} />
      <primitive object={Infinity.children[0]} />
      {/*<primitive object={Ground_Additional.children[0]} />*/}
      <primitive object={Orla_Main_Podium_Glass.children[0]} />
      <primitive object={Site_Paving.children[0]} />
    </>
  );
}
