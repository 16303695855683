import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.scss";
import unitApi from "../../apis/api/unit";
import amenityApi from "../../apis/api/amenities";
import Modal from "../modal-base";
import {
  reqSetIsShowFilter,
  reqSetSelectedUnit,
} from "../../reduxs/unit-explore/action";
import { reqSetIsTransparent, reqSetPage } from "../../reduxs/home/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../helper/unit";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
} from "../../reduxs/explore-modal/action";
import socket from "../../helper/socket";

const SearchV2 = ({ resetState, isPresentation }) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState();
  const [units, setUnits] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [isShowModal, setShowModal] = useState(false);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_SEARCH_UNIT) {
          handleClickUnit(content.data.unit);
        }
        if (content.action === ACTION_NAME.CLICK_SEARCH_AMENITY) {
          handleClickAmenity(content.data.amenity);
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current && (pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight);
        }
        if (content.action === ACTION_NAME.CLOSE_SEARCH_MODAL) {
          handleClose();
        }
        if (content.action === ACTION_NAME.SEARCH_KEYWORD_CHANGED) {
          onChangeKeyword(content.data.keyword);
        }
      })
    }
  }, [isPresentation]);

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [keyword]);

  const getSearch = async () => {
    const data = {
      search: keyword,
    };
    const unitRes = await unitApi.getUnitList(data);
    const amenityRes = await amenityApi.getAmenitiesList(data);
    setAmenities(amenityRes.data);
    setUnits(unitRes.data);
  };

  const handleSearch = useCallback(debounce(getSearch, 600), [keyword]);

  const handleClickUnit = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SEARCH_UNIT, {
        unit: item,
      });
    }
    dispatch(reqSetSelectedUnit(item.id));
    resetState();
    dispatch(reqSetIsShowFilter(true));
    dispatch(reqSetIsTransparent(false));
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const handleClickAmenity = (amenity) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SEARCH_AMENITY, {
        amenity,
      });
    }
    resetState();
    dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
    dispatch(reqSetExploreModal(amenity.modal));
    dispatch(reqSetIsShowExploreModal(true));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  const onChangeKeyword = (keyword) => {
    setKeyword(keyword);
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SEARCH_KEYWORD_CHANGED, {
        keyword,
      })
    }
  }

  const renderSearch = () => {
    return (
      <div className="searchPage">
        <span className="title-search">Search</span>
        <div className="search-input">
          <input
            onChange={(e) => onChangeKeyword(e.target.value)}
            type="text"
            placeholder="Search..."
            value={keyword}
          />
          <button>Search</button>
        </div>
        <div ref={pageRef} className="wrapper" onScroll={onScroll}>
          {units.map((unit, idx) => {
            return (
              <div key={`${idx}-units`} className="item-search">
                <div
                  className="card-search"
                  onClick={() => handleClickUnit(unit)}
                >
                  <span className="title">{unit.name}</span>
                  <span className="textSub">{unit.description}</span>
                </div>
              </div>
            );
          })}
          {amenities.map((amenity, idx) => {
            return (
              <div key={`${idx}-units`} className="item-search">
                <div
                  className="card-search"
                  onClick={() => handleClickAmenity(amenity)}
                >
                  <span className="title">{amenity.name}</span>
                  <span className="textSub">{amenity.description}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const handleClose = () => {
    setShowModal(false);
    resetState();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_SEARCH_MODAL);
    }
  };

  return (
    <Modal
      classNames="modal-search"
      open={!!isShowModal}
      onHide={handleClose}
      isOutSideClose={false}
      currentColor="#C7A446"
    >
      {renderSearch()}
    </Modal>
  );
};

export default SearchV2;
