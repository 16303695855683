import React, { useEffect, useRef } from "react";
import "./style.scss";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useState } from "react";
import LoadingIcon from "../loading-icon";
import { useDispatch, useSelector } from "react-redux";
import { reqSetIsShowVideo, reqSetPage } from "../../reduxs/home/action";
import {
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowUserTypeModal,
} from "../../reduxs/guide-session/action";
import Cube from "../cube";
import UserTypeModal from "../guide-sesions/user-type-modal";
import ListUserSelectModal from "../guide-sesions/list-user-select-modal";
import CreateUserProfileModal from "../guide-sesions/create-user-profile-modal";
import { reqGetUserProfile } from "../../reduxs/user/action";
import { USER_GROUP } from "../../constants/master-data";
import VideoIntro from "../video-intro";
import socket from "../../helper/socket";
import webSocket from "../../helper/websocket";

const Loading = (props) => {
  const { isLoading, isPresentation } = props;
  const isShowVideo = useSelector((state) => state.home.isShowVideo);
  const authUser = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const customer = useSelector((state) => state.user.customer);
  const isVideoMuted = useSelector((state) => state.guideSession.isVideoMuted);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const isShowUserTypeModal = useSelector(
    (state) => state.guideSession.isShowUserTypeModal
  );
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListTenantModal = useSelector(
    (state) => state.guideSession.isShowListTenantModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );
  const isShowCreateInvestorProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateInvestorProfileModal
  );
  const isShowCreateTenantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateTenantProfileModal
  );
  const isShowCreateConsultantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateConsultantProfileModal
  );

  const [isShowVideoIntro, setShowVideoIntro] = useState(false);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_INSPIRE_FILM) {
          setShowVideoIntro(true);
        }

        if (content.action === ACTION_NAME.CLOSE_INSPIRE_FILM) {
          setShowVideoIntro(false);
        }

        if (content.action === ACTION_NAME.SATRT_SESSION) {
          onStartSession();
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isVideoMuted;
    }
  }, [isVideoMuted]);

  useEffect(() => {
    if (videoRef.current) {
      isVideoPaused ? videoRef.current.pause() : videoRef.current.play();
    }
  }, [isVideoPaused]);

  const onOpenInspireFilm = () => {
    setShowVideoIntro(true);

    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.SHOW_INSPIRE_FILM);
      socket.shareMedia({
        FG: { "FG_Orla_IR-Film-01": "MOV", MSG_ID: messageId },
      });
      webSocket.send(
        JSON.stringify({
          FG: { "FG_Orla_IR-Film-01": "MOV", MSG_ID: messageId },
        })
      );
    }
  };

  const onCloseInspireFilm = () => {
    setShowVideoIntro(false);
    if (!isPresentation) {
      const messageId = Date.now();
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_INSPIRE_FILM);
      socket.shareMedia({ "FG": { "FG_0000": "MOV", "MSG_ID": messageId } });
      webSocket.send(
        JSON.stringify({ "FG": { "FG_0000": "MOV", "MSG_ID": messageId } })
      );
    }
  };

  const startDiscover = () => {
    dispatch(reqSetIsShowVideo(false));
    dispatch(reqSetPage(PAGES.GALLERY_LANDING_PAGE));
    analytics.track("Agent Started Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    });
  };

  const onExplore = () => {
    dispatch(reqSetIsShowUserTypeModal(true));
  };

  const onStartSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SATRT_SESSION);
    }
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
    startDiscover();
  };

  const renderExploreButton = () => {
    if (!authUser) return;

    if (
      [USER_GROUP.INVESTOR, USER_GROUP.CONSULTANT].includes(
        authUser?.userGroup.name
      )
    ) {
      return (
        <div className="btn-loading">
          {/* <button className="explore_button" onClick={() => { }}>
            Inspire Film
          </button> */}
          <button className="explore_button" onClick={() => startDiscover()}>
            Explore Project
          </button>
        </div>
      );
    }

    if (authUser?.userGroup.name === USER_GROUP.ADMIN) {
      return (
        <>
          {/* <button className="explore_button" onClick={onOpenInspireFilm}>
            Inspire Film
          </button> */}
          <button className="explore_button" onClick={() => onExplore()}>
            Explore Project
          </button>
        </>
      );
    }
  };

  const isShowBgImg =
    isShowUserTypeModal ||
    isShowListInvestorModal ||
    isShowListConsultantModal ||
    isShowListTenantModal ||
    isShowCreateInvestorProfileModal ||
    isShowCreateConsultantProfileModal ||
    isShowCreateConsultantProfileModal;

  return (
    <div id="loading-screen">
      <div id="loading-cube-wrapper">
        {/*<Cube draggable={false} disabled={isLoading} onClick={() => {}} />*/}
      </div>
      <div className={`wrap-intro ${isShowVideo ? "hide" : "show"}`}>
        <div
          id="intro-video"
          style={{ backgroundImage: `url("./uploads/images/poster.png")` }}
          className={isShowBgImg ? "fade-in-image" : ""}
        />

        <div className="intro-content">
          <div className="wrap-text">
            <img
              width="396px"
              height="210px"
              className="img-fluid"
              src="./uploads/images/d-logo.png"
              alt=""
            />
          </div>
          {/*<img*/}
          {/*  width="191px"*/}
          {/*  height="20px"*/}
          {/*  src="./uploads/images/by-logo.png"*/}
          {/*  alt=""*/}
          {/*/>*/}
          {!!isLoading && <LoadingIcon />}
          <div className={`wrap-button ${isLoading ? "disable-btn" : ""}`}>
            {renderExploreButton()}
          </div>
          {/* Used to cache video */}
          <div style={{ display: "none" }}>
            <video
              autoPlay={false}
              onEnded={() => startDiscover()}
              preload="auto"
              muted={false}
              style={{
                backgroundImage: `url("uploads/media/gallery-image-orla-sky-palaces-01.jpeg")`,
              }}
            >
              <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className={`wrap-slide-loading ${isShowVideo ? "show" : "hide"}`}>
        {
          <>
            <video
              ref={videoRef}
              autoPlay={false}
              onEnded={() => startDiscover()}
              preload="auto"
              id="intro-video-2"
              muted={false}
              style={{
                backgroundImage: `url("uploads/media/gallery-image-orla-sky-palaces-01.jpeg")`,
              }}
            >
              <source src="/uploads/videos/FG_Orla_IR-Film-01.mp4" type="video/mp4" />
            </video>

            {isVideoPaused && (
              <img
                onClick={() => setIsVideoPaused(false)}
                className="btn-start-video"
                src="/icons/play.svg"
                alt=""
              />
            )}

            <button onClick={() => startDiscover()} className="btn-skip-video">
              Skip video
            </button>
          </>
        }
      </div>
      <UserTypeModal />
      <ListUserSelectModal startDiscover={onStartSession} isPresentation={isPresentation} />
      <CreateUserProfileModal startDiscover={onStartSession} isPresentation={isPresentation} />
      {isShowVideoIntro && <VideoIntro isIntro onHide={onCloseInspireFilm} />}
    </div>
  );
};

export default Loading;
