export const UNIT_ENUM = {
  SQUARE: "square",
  SQUARE_METER: "squareMeter",
  ACRE: "acre",
  HECTARE: "hectare",
};
export const PROPERTY_TYPE_ENUM = {
  HOUSE: "house",
  UNIT: "unit",
  APARTMENT: "apartment",
  OFFICE: "office",
  VILLA: "villa",
  LAND: "land",
  TOWNHOUSE: "townhouse",
};
export const AVAILABILITY_STATUS_ENUM = {
  AVAILABLE_NOW: "available_now",
  RELEASING_SOON: "releasing_soon",
  SOLD: "sold",
  RESERVED: "reserved",
  MUTILPLE_OFFERS: "multiple_offers",
};
export const MEDIA_TYPE_ENUM = {
  INTERIOR_ID: "interior_id",
  FLOORPLAN: "floorplan",
  IMAGE: "image",
  VIDEO: "video",
  "360_PANO_TOUR": "360_pano_tour",
};
export const USER_GROUP = {
  INVESTOR: "investor",
  TENANT: "tenant",
  ADMIN: "admin",
  CONSULTANT: "consultant",
};

export const USER_GROUP_ID = {
  INVESTOR: "606d6f356af09f50f367ee23",
  TENANT: "610a4a3351400773f94bfa89",
};

export const FLOOR_TYPE = {
  ORLA_FLOOR: "orla_floorplan",
  ORLA_INFINITY_FLOOR: "orla_infinity_floorplan",
  ORLA_MANSION_FLOOR: "orla_mansion_floorplan",
  ORLA_SKY_PLACES_FLOOR: "orla_sky_places_floorplan",
};

export const LEVEL_TYPE = {
  ORLA_LEVEL: "orla_level",
  ORLA_INFINITY_LEVEL: "orla_infinity_level",
  ORLA_MANSION_LEVEL: "orla_mansion_level",
  ORLA_SKY_PLACES_LEVEL: "orla_sky_places_level",
}

export const FILM_TYPE = {
  ORLA_FILM: "orla_film",
  ORLA_INFINITY_FILM: "orla_infinity_film",
  ORLA_MANSION_FILM: "orla_mansion_film",
  ORLA_SKY_PLACES_FILM: "orla_sky_places_film",
}

export const GALLERY_IMAGE_TYPE = {
  ORLA_GALLERY_IMAGE: "orla_image",
  ORLA_INFINITY_GALLERY_IMAGE: "orla_infinity_image",
  ORLA_MANSION_GALLERY_IMAGE: "orla_mansion_image",
  ORLA_SKY_PLACES_GALLERY_IMAGE: "orla_sky_places_image",
}

export default {
  UNIT_ENUM,
  PROPERTY_TYPE_ENUM,
  AVAILABILITY_STATUS_ENUM,
  MEDIA_TYPE_ENUM,
  USER_GROUP,
};
