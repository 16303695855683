import React from "react";
import { BackSide, Color } from "three";

const color = new Color(0.009, 0.009, 0.009).convertLinearToSRGB();

export function SphereSceneContainer() {
  return (
    <>
      <mesh meshId={10}>
        <sphereGeometry args={[18000, 30, 30]} />
        <meshBasicMaterial side={BackSide} color={color} />
      </mesh>
    </>
  );
}
