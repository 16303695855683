import React, { useState } from "react";
import { FormControl, InputGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./index.scss";
import * as yup from "yup";
import customerApi from "../../apis/api/customer";
import { useDispatch } from "react-redux";
import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
} from "../../reduxs/guide-session/action";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import socket from "../../helper/socket";
import webSocket from "../../helper/websocket";

const CreateUserProfileModal = (props) => {
  const { startDiscover, isPresentation } = props;
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState();
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [role, setRole] = useState();
  const [mobile, setMobile] = useState();
  const [error, setError] = useState();
  const notify = (message) => toast.info(message);
  const history = useHistory();
  const authUser = useSelector((state) => state.user.data);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );
  const isShowCreateInvestorProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateInvestorProfileModal
  );
  const isShowCreateTenantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateTenantProfileModal
  );
  const isShowCreateConsultantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateConsultantProfileModal
  );

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    surname: yup.string().required(),
  });

  const handleCreateCustomer = async () => {
    try {
      let userGroup = "606d6f356af09f50f367ee23";

      if (isShowCreateTenantProfileModal) {
        userGroup = "610a4a3351400773f94bfa89";
      }

      if (isShowCreateConsultantProfileModal) {
        userGroup = "63030d2f94bc7bf551da79e3";
      }

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
      };

      // const result = await validationSchema.validate(data);
      const res = await customerApi.createCustomer(data);

      if (res instanceof Error) {
        if (res.body?.data?.reason === "Email is existed") {
          toast.error("Email exist, Please try again!");
          return;

        }
        if (res?.message?.includes("Bad Request")) {
          toast.error("Data input is invalid, Please try again!");
          return;
        }
      }

      analytics.track("Agent Created Client", {
        agentId: authUser.id,
        clientId: res.data?.id,
        clientFistName: res.data?.firstname,
        clientEmail: res.data?.email,
        clientSurname: res.data?.surname,
      });

      history.push({
        search: `?customer=${res?.data?.id}`,
      });

      if (isShowCreateInvestorProfileModal) {
        dispatch(reqSetIsShowCreateInvestorProfileModal(false));
        notify("Investor created successfully!");
      }
      if (isShowCreateTenantProfileModal) {
        dispatch(reqSetIsShowCreateTenantProfileModal(false));
        notify("Tenant created successfully!");
      }
      if (isShowCreateConsultantProfileModal) {
        dispatch(reqSetIsShowCreateConsultantProfileModal(false));
        notify("Consultant created successfully!");
      }

      if (!isPresentation) {
        const messageId = Date.now();
        socket.shareMedia({ "FG": { "FG_Orla_IR-Film-01": "MOV", "MSG_ID": messageId } });
        webSocket.send(JSON.stringify({ "FG": { "FG_Orla_IR-Film-01": "MOV", "MSG_ID": messageId } }));
      }

      handleResetData();
      startDiscover();
    } catch (err) {
      setError(err);
    }
  };

  const handleClose = () => {
    history.push({
      search: "",
    });
    if (isShowCreateInvestorProfileModal) {
      dispatch(reqSetIsShowCreateInvestorProfileModal(false));
      dispatch(reqSetIsShowListInvestorModal(true));
    }
    if (isShowCreateTenantProfileModal) {
      dispatch(reqSetIsShowCreateTenantProfileModal(false));
      dispatch(reqSetIsShowListTenantModal(true));
    }
    if (isShowCreateConsultantProfileModal) {
      dispatch(reqSetIsShowCreateConsultantProfileModal(false));
      dispatch(reqSetIsShowListConsultantModal(true));
    }
    handleResetData();
  };

  const handCloseAllModal = () => {
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    handleResetData();
  };

  const handleResetData = () => {
    setEmailAddress("");
    setName("");
    setCompany("");
    setRole("");
    setMobile("");
  };
  return (
    <Modal
      backdropClassName="opacity0"
      className="wrap-list-user-modal"
      show={
        isShowCreateInvestorProfileModal ||
        isShowCreateTenantProfileModal ||
        isShowCreateConsultantProfileModal
      }
      onHide={handCloseAllModal}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="modal-form__title">
          Enter profile details to continue
        </div>
        <div className="modal-form__title underline-sm mb-4">
          {isShowCreateInvestorProfileModal
            ? "setting up a remote guided session"
            : "setting up a sales gallery session"}
        </div>
        <p className="mb-4 modal-form__sub-title">
          Create a new customer for today’s session
        </p>
        <div className="mb-4 wrapper-list-user">
          <div className="first-last-name">
            <div className="form-info">
              <label htmlFor="email">Fist Name*</label>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                id="firsName"
                placeholder="First Name"
              />
            </div>
            <div className="form-info">
              <label htmlFor="email">Last Name*</label>
              <input
                onChange={(e) => setLastName(e.target.value)}
                id="lastName"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="email">Email*</label>
            <input
              onChange={(e) => setEmailAddress(e.target.value)}
              id="email"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="wrap-button-list-user d-flex justify-content-between">
          <button onClick={handleCreateCustomer}>Create</button>
          <button onClick={handleClose}>Cancel</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserProfileModal;
