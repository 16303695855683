import React, { useEffect, useRef, useState } from "react";
import iconPlay from "../../../assets/images/play.svg";
import { getImagePath } from "../../../helper/media";
import "../../home-gallery/index.scss";
import "./index.scss";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import socket from "../../../helper/socket";
import webSocket from "../../../helper/websocket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";

const PlayVideo = ({ urlBg, urlVideo, onStopVideo, isPresentation }) => {
  const videoRef = useRef(null);

  const onSkipVideo = () => {
    onStopVideo();
    if (!isPresentation) {
      const messageId = Date.now();
      webSocket.send(JSON.stringify({ "FG": { "FG_0000": "MOV", "MSG_ID": messageId } }));
      socket.shareMedia({ "FG": { "FG_0000": "MOV", "MSG_ID": messageId } });
    }
  };

  return (
    <div className="wrap-video-film">
      <video
        loop
        ref={videoRef}
        autoPlay={true}
        muted={false}
        preload="auto"
        id="intro-video-2"
        style={{ backgroundImage: urlBg && `url(${getImagePath(urlBg)})` }}
      >
        <source src={getImagePath(urlVideo)} type="video/mp4" />
      </video>
      <div className="btn-close-video" onClick={onSkipVideo}>
        <Close fill="white" />
      </div>
    </div>
  );
};

const Films = ({ data, isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const [urlThumbVideo, setUrlThumbVideo] = useState("");

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.PLAY_VIDEO) {
          return handlePlayVideo(content.data.item);
        }
        if (content.action == ACTION_NAME.STOP_VIDEO) {
          return onStopVideo();
        }
      });
    }
  }, [isPresentation]);

  const handlePlayVideo = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_VIDEO, {
        item: item,
      });

      if (item?.message) {
        const message = item.message;
        message.FG.MSG_ID = Date.now();
        webSocket.send(JSON.stringify(message));
        socket.shareMedia(message);
      }
    }

    setShowVideo(true);
    setUrlVideo(item.path);
    setUrlThumbVideo(item.thumbnail);
  };

  const onStopVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_VIDEO);
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  return (
    <div className="films-content">
      <div className="films-row">
        {data?.media?.map((i, idx) => {
          return (
            <div
              key={`films-${idx}`}
              data-fancybox="gallery"
              className={`card-films ${isInfinity && "infinity"}`}
              style={{
                backgroundImage: `url(${getImagePath(i.thumbnail)}`,
              }}
              onClick={() => handlePlayVideo(i)}
            />
          );
        })}
      </div>
      {isShowVideo && (
        <PlayVideo
          isPresentation={isPresentation}
          urlVideo={urlVideo}
          onStopVideo={onStopVideo}
        />
      )}
    </div>
  );
};

export default Films;
