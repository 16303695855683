import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reqGetListUnits,
  actSetEditablePropertyColumn,
} from "../../reduxs/cms/action";
import PropertyRow from "./property-row";
import unitApi from "../../apis/api/unit";
import { toast } from "react-toastify";
import icSearch from "../../assets/images/icSearchV3.svg";
import EditPropertyModal from './edit-property-modal';

const CMSProperties = () => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);
  const editablePropertyColumn = useSelector(
    (state) => state.cms.editablePropertyColumn
  );
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isShowSearch, setShowSearch] = useState(false);
  const [editType, setType] = useState("");
  const [search, setSearch] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    getListProperties();
  }, [search, isSortAsc]);

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        search,
        sortBy: JSON.stringify({
          tradingName: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const handleWhenClickEditButton = (title) => {
    dispatch(actSetEditablePropertyColumn(null));
    setType((prev) => {
      if (prev === title) {
        setIsEdit(!isEdit);
        return "";
      }
      setIsEdit(true);
      return title;
    });
  };

  const handleWhenClickCancelButton = () => {
    dispatch(actSetEditablePropertyColumn(null));
    setIsEdit(false);
    setType("");
  };

  const renderListProperty = () => {
    return (units || []).map((item, index) => {
      return (
        <PropertyRow
          key={item.id}
          data={item}
          index={index}
          setSelectedProperty={setSelectedProperty}
        />
      );
    });
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">Residences</h1>
        <div className="input-group ms-auto page-header__input-search w-50">
          {isShowSearch && (
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="form-control"
              type="text"
              placeholder="Search..."
            />
          )}
        </div>
        <img
          src={icSearch}
          alt="icon-search"
          onClick={() => setShowSearch((v) => !v)}
        />
      </div>
      <div className="page-body">
        <div className="table-responsive-custom-cms">
          <table className="table-cms">
            <tr className="menu-table-cms">
              <th>Residence</th>
              <th>Building</th>
              <th>Price</th>
              <th>Bedrooms</th>
              <th>Aspect</th>
              <th>Residence Type</th>
              <th>Ceiling Height</th>
              <th />
            </tr>
            {renderListProperty()}
          </table>
        </div>
      </div>
      <EditPropertyModal property={selectedProperty} onClose={() => setSelectedProperty(null)} />
    </div>
  );
};

export default CMSProperties;
