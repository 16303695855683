import React, { useEffect, useRef } from "react";
import "./index.scss";
import life01 from "../../assets/images/life_01.png";
import life02 from "../../assets/images/life_02.png";
import life03 from "../../assets/images/life_03.png";
import life04 from "../../assets/images/life_04.png";
import life05 from "../../assets/images/life_05.png";
import life06 from "../../assets/images/life_06.png";
import life07 from "../../assets/images/life_07.png";
import lifeBg01 from "../../assets/images/life_bg_01.png";
import lifeBg02 from "../../assets/images/life_bg_02.png";
import lifeBg03 from "../../assets/images/life_bg_03.png";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const LifeStyle = (props) => {
  const { isPresentation } =  props;
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current && (pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div className="static-page lifePage" >
      <div ref={pageRef} className="scroll-view" onScroll={onScroll}>
        <div className="image-page content-life-1" style={{ backgroundImage: `url(${life01})` }}>
          <div className="text">
            As an ORLA Collection resident, exclusive access is granted to all of ORLA’s world-class amenities. Here is where wellbeing and enjoyment take center stage, creating an experience that complements the splendour of your surroundings.
          </div>
        </div>
        <div className="content-life-2 blend-multiply" style={{ backgroundImage: `url(${lifeBg01})` }}>
          <div className="content-wrapper upper">
            <div className="text">
              Descend into the ORLA grounds and get swept<br />away by the best aspects of the amenities.<br /><br />
              Find exclusive escapes from a resident only<br />beach club, two private cinemas, sophisticated<br />cigar lounges, indoor kids areas, bowling alley<br />and entertainment lounges throughout.
            </div>
            <div className="img-ctn">
              <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} alt="life02" src={life02} />
            </div>
          </div>
          <div className="content-wrapper lower">
            <div className="img-ctn">
              <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} alt="life03" src={life03} />
            </div>
            <div className="text">
              Relish in the private beach of ORLA and ORLA Infinity,<br />where untouched sands of Palm Jumeirah and the<br />glistening crystal waters clasp at your senses.<br />Experience the luxury of having a pristine paradise just<br />steps from your residence.<br /><br />
              Take time to unwind in the spa, rejuvenate in our serene<br />treatment rooms, and relax in the pools, all designed<br />with your wellbeing in mind.
            </div>
          </div>
        </div>
        <div className="image-page content-life-3" style={{ backgroundImage: `url(${life04})` }}>
          <div className="text-holder">
            <div className="title text">ORLA BEACH CLUB</div>
            <div className="text">
              Presenting ORLA’s exclusive beach club, reserved exclusively for ORLA and ORLA Infinity residents, situated along the pristine shores of Palm Jumeirah and the crystal waters of the Arabian Gulf.
            </div>
          </div>
        </div>
        <div className="content-life-4 blend-multiply" style={{ backgroundImage: `url(${lifeBg02})` }}>
          <div className="content-wrapper">
            <div className="text">
            Proudly placed next to the beach club is a<br />magnificent infinity pool, accompanied by<br />comfortable sun loungers and luxurious<br />cabanas, where you can relax and enjoy<br />poolside dining with family and friends.<br /><br />
            Spend your day sipping refreshing cocktails<br />and savor the culinary delights while<br />immersing yourself in the serene ambiance<br />of ORLA, leaving the city noise behind.
            </div>
            <div className="img-ctn">
              <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} alt="life05" src={life05} />
            </div>
          </div>
        </div>
        <div className="image-page content-life-5" style={{ backgroundImage: `url(${life06})` }}>
          <div className="text">
            Dorchester Collection, renowned for their unwavering commitment to meticulous attention to detail and exceptional service, caters to the discerning individuals.
          </div>
        </div>
        <div className="content-life-6 blend-multiply" style={{ backgroundImage: `url(${lifeBg03})` }}>
          <div className="content-wrapper">
            <div className="text">
              ORLA and ORLA Infinity, managed exclusively by<br />Dorchester Collection, continues this legacy of<br />excellence. Residents can anticipate the highest<br />
              level of attentive service, reflecting the<br />exceptional standards with Dorchester<br />Collection’s iconic hotels worldwide.<br /><br />
              <span className="bold">Classic</span>
              • Common area maintenance and cleaning<br />
              • 24/7 Residence concierge<br />
              • Doorman and porter services<br />
              • Valet parking<br />
              • Lifeguard and gym service<br />
              • 24/7 Security services<br /><br />
              <span className="bold">À La Carte</span>
              • Housekeeping<br />
              • Laundry<br />
              • Maintenance<br />
              • Bespoke in-residence catering services<br />
              • One-off dining events & functions<br />
              • Floristry services<br />
              • Bookings, reservations & appointments<br />
              • Five-star experiences at home
            </div>
            <div className="img-ctn">
              <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} alt="life07" src={life07} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeStyle;
