import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import { useRef } from "react";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";

export const FloorPlanView = ({ url, isPresentation }) => {
  const loadedMapRef = useRef(); // used to add marker / overlay
  const mapRef = useRef(); // used to control map
  const overlayRef = useRef();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI",
  });

  useEffect(() => {
    if (!url || !loadedMapRef?.current) return;
    const img = new Image();
    img.onload = function () {
      const ratio = this.width / this.height;
      overlayRef.current = new google.maps.GroundOverlay(url, {
        north: 1,
        south: -1,
        east: ratio,
        west: ratio * -1,
      });
      overlayRef.current?.setMap(loadedMapRef.current);
      mapRef.current?.state?.map?.setZoom(9);
    };
    img.src = url;

    return () => {
      overlayRef.current?.setMap(null);
    };
  }, [url, loadedMapRef?.current]);

  useEffect(() => {
    if (!isPresentation) {
      return;
    }
    socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
      if (content.action === ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED) {
        mapRef.current?.state?.map?.setZoom(content.data.zoom);
      }

      if (content.action === ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED) {
        mapRef.current?.state?.map?.setCenter({
          lat: content.data.lat,
          lng: content.data.lng,
        });
      }
    });

    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION);
    };
  }, [isPresentation]);

  if (!isLoaded || !url) return null;
  return (
    <GoogleMap
      ref={mapRef}
      center={{ lat: 0, lng: 0 }}
      zoom={9}
      streetViewControl={false}
      mapTypeControlOptions={{
        mapTypeIds: ["floorplans"],
      }}
      onLoad={(map) => {
        const floorplanMapType = new google.maps.ImageMapType({
          getTileUrl: (coord, zoom) => {
            return "";
          },
          tileSize: new google.maps.Size(512, 512),
          maxZoom: 12,
          minZoom: 9,
          name: "Floorplan",
        });
        map.mapTypes.set("floorplans", floorplanMapType);
        map.setMapTypeId("floorplans");

        loadedMapRef.current = map;
      }}
      onZoomChanged={function () {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED, {
            zoom: this.zoom,
          });
        }
      }}
      onCenterChanged={function () {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED, {
            lat: this.center.lat(),
            lng: this.center.lng(),
          });
        }
      }}
      mapContainerStyle={{
        width: "100svw",
        height: "100svh",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1000,
      }}
      options={{
        maxZoom: 12,
        minZoom: 9,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        isFractionalZoomEnabled: true,
      }}
    />
  );
};
