import React, { useEffect, useState } from "react";
import { Modal, InputGroup, FormControl, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./index.scss";
import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
  reqSetIsShowUserTypeModal,
} from "../../reduxs/guide-session/action";

import customerAPi from "../../apis/api/customer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ErrorModal from "./error-modal";
import socket from "../../helper/socket";
import webSocket from "../../helper/websocket";
import { ORLA_NAME } from "../../constants/common";
import { reqSetPage } from "../../reduxs/home/action";
import { ACTION_NAME, PAGES } from "../../constants/options";


const ListUserSelectModal = (props) => {
  const { isPresentation } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [listUserSelect, setListUserSelect] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [isShowNotFound, setShowNotFound] = useState(false);
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListTenantModal = useSelector(
    (state) => state.guideSession.isShowListTenantModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  const getListInvestor = async () => {
    const data = {
      firstname: firstName?.toLowerCase(),
      surname: lastName?.toLowerCase(),
      email: emailAddress?.toLowerCase(),
    };
    
    const result = await customerAPi.findCustomer(data);
    if (result?.data) {
      onStartUserSession(result.data);
    } else {
      setShowNotFound(true);
    }
  };

  const getListTenant = async () => {
    const result = await customerAPi.getTenantList();
    setListUserSelect(result?.data || []);
  };

  const getListConsultant = async () => {
    const data = {
      firstname: firstName?.toLowerCase(),
      surname: lastName?.toLowerCase(),
      email: emailAddress?.toLowerCase(),
    };

    const result = await customerAPi.findCustomer(data);
    
    if (result?.data) {
      onStartUserSession(result.data);
    } else {
      setShowNotFound(true);
    }
  };

  const onSelectUser = (user) => {
    history.push({
      search: `?customer=${user?.id}`,
    });
    setSelectedUser(user);
  };

  const isShowCreateUserProfileModal = () => {
    history.push({
      search: "",
    });
    if (userSelectType === "investor") {
      dispatch(reqSetIsShowCreateInvestorProfileModal(true));
      dispatch(reqSetIsShowListInvestorModal(false));
    } else if (userSelectType === "tenant") {
      dispatch(reqSetIsShowCreateTenantProfileModal(true));
      dispatch(reqSetIsShowListTenantModal(false));
    } else if (userSelectType === "consultant") {
      dispatch(reqSetIsShowCreateConsultantProfileModal(true));
      dispatch(reqSetIsShowListConsultantModal(false));
    }
  };

  const handleClose = () => {
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));
    setShowNotFound(false);
  };

  const handleCancel = () => {
    handleClose();
    dispatch(reqSetIsShowUserTypeModal(true));
  };

  const getActiveClass = (user) => {
    return selectedUser?.id === user?.id
      ? "cursor-pointer active"
      : "cursor-pointer";
  };

  const onStartUserSession = (user) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SATRT_SESSION, {
        user
      });
    }
    history.push({
      search: `?customer=${user?.id}`,
    });
    setSelectedUser(user);
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));

    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user.firstname,
      customerLastName: user.surname,
      customerEmail: user.email,
    });
    dispatch(reqSetPage(PAGES.GALLERY_LANDING_PAGE));
    
  };

  const handleSubmitUser = async () => {
    if (isShowListInvestorModal) {
      await getListInvestor();
    }
    if (isShowListConsultantModal) {
      await getListConsultant();
    }

    if (!isPresentation) {
      const messageId = Date.now();
      socket.shareMedia({ "FG": { "FG_Orla_IR-Film-01": "MOV", "MSG_ID": messageId } });
      webSocket.send(JSON.stringify({ "FG": { "FG_Orla_IR-Film-01": "MOV", "MSG_ID": messageId } }));
    }
  };

  // https://ideabox.atlassian.net/browse/BN-973 Sales gallery button skips customer registration page
  useEffect(() => {
    const startSalesGallerySession = async () => {
      const data = {
        firstname: "sales",
        surname: "gallery",
        email: "salesgallerycustomer@ideabox.com.au",
      };
  
      const result = await customerAPi.findCustomer(data);
  
      if (result?.data) {
        onStartUserSession(result.data);
      } else {
        setShowNotFound(true);
        dispatch(reqSetIsShowListConsultantModal(false));
      }
    }
    if (isShowListConsultantModal) {
      startSalesGallerySession();
    }
  }, [isShowListConsultantModal])
  
  if (isShowListConsultantModal) return <></>;
  //

  return (
    <>
      <Modal
        backdropClassName="opacity0"
        className={`wrap-list-user-modal`}
        show={
          isShowListTenantModal ||
          isShowListInvestorModal ||
          isShowListConsultantModal
        }
        onHide={handleClose}
        centered
      >
        <Modal.Body className="wrap-modal-body">
          <div className="modal-form__title">
            Enter profile details to continue
          </div>
          <div className="modal-form__title">
            {isShowListInvestorModal
              ? "setting up a remote guided session"
              : "setting up a sales gallery session"}
          </div>
          <div className="mb-4 modal-form__sub-title">
            {`First time experiencing ${ORLA_NAME}?`}
            <button onClick={isShowCreateUserProfileModal}>
              Create a new profile
            </button>
          </div>
          <div className="mb-4 wrapper-list-user">
            <div className="first-last-name">
              <div className="form-info">
                <label htmlFor="email">Fist Name*</label>
                <input
                  autoCapitalize="none"
                  onChange={(e) => setFirstName(e.target.value)}
                  id="firsName"
                  placeholder="First Name"
                />
              </div>
              <div className="form-info">
                <label htmlFor="email">Last Name*</label>
                <input
                  autoCapitalize="none"
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastName"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="form-info w-100 mt-4">
              <label htmlFor="email">Email*</label>
              <input
                autoCapitalize="none"
                onChange={(e) => setEmailAddress(e.target.value)}
                id="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="wrap-button-list-user d-flex justify-content-between">
            <button onClick={handleSubmitUser}>submit</button>
            <button onClick={handleCancel}>cancel</button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        isShow={isShowNotFound}
        onClose={() => setShowNotFound(false)}
      />
    </>
  );
};

export default ListUserSelectModal;
