import { parseIncludes } from "./utils";

export function meshDepthBiasedMaterial(material) {
  material.onBeforeCompile = (shader) => {
    shader.vertexShader = parseIncludes(shader.vertexShader);
    shader.vertexShader = shader.vertexShader.replace(
      "gl_Position = projectionMatrix * mvPosition;",
      `
      gl_Position = projectionMatrix * mvPosition;
      gl_Position.z -= 0.03;
      `,
    );

    // console.log(shader.vertexShader);
  };

  return material;
}
