import React, { useState, useEffect } from "react";
import "./index.scss";
import { ACTION_NAME, SUB_TAB_GALLERY, TAB_GALLERY, WEBSOCKET_CHANNEL } from "../../constants/options";
import Renders from "./components/renders";
import Films from "./components/films";
import FloorPlans from "./components/floor-plans/floor-plans";
import galleryApi from "../../apis/api/gallery";
import { useDispatch } from "react-redux";
import { reqGetGalleryList } from "../../reduxs/gallery/action";
import { FILM_TYPE, FLOOR_TYPE, GALLERY_IMAGE_TYPE, LEVEL_TYPE } from "../../constants/master-data";
import { ArrowCircleIcon, ArrowCircleLightIcon } from "../svgs/icons";
import socket from "../../helper/socket";

const TAB = [
  {
    name: TAB_GALLERY.ORLA,
    isActive: true,
    width: 76,
  },
  {
    name: TAB_GALLERY.ORLA_INFINITY,
    isActive: false,
    width: 133,
  },
  {
    name: TAB_GALLERY.ORLA_MANSION,
    isActive: false,
    width: 143,
  },
  {
    name: TAB_GALLERY.ORLA_SKY_PALACES,
    isActive: false,
    width: 169,
  },
];

const SUB_TAB = [
  {
    name: SUB_TAB_GALLERY.FILMS,
  },
  {
    name: SUB_TAB_GALLERY.FLOOR_PLANS,
  },
  {
    name: SUB_TAB_GALLERY.RENDER,
  },
  // {
  //   name: SUB_TAB_GALLERY.VIEWS,
  // },
];

const GalleryLanding = (props) => {
  const { isPresentation } = props;
  const [tabActive, setTabActive] = useState(TAB[0].name);
  const [subTabActive, setSubTabActive] = useState(SUB_TAB[2].name);
  const [floor, setFloor] = useState([]);
  const [floorDetail, setFloorDetail] = useState({});
  const [imageRender, setImgRender] = useState([]);
  const [filmRender, setFilmRender] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reqGetGalleryList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_GALLERY_TAB) {
          return onClickTab(content.data.name);
        }
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB) {
          return onClickSubTab(content.data.name);
        }
      })
    }
  }, []);

  useEffect(() => {
    if (tabActive === TAB[0].name) {
      if (subTabActive === SUB_TAB[2].name) {
        handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_GALLERY_IMAGE);
      }
      if (subTabActive === SUB_TAB[0].name) {
        handleGetFilmRender(FILM_TYPE.ORLA_FILM);
      }
      if (subTabActive === SUB_TAB[1].name) {
        handleGetListLevel(LEVEL_TYPE.ORLA_LEVEL);
        handleGetGalleryDetail(FLOOR_TYPE.ORLA_FLOOR);
      }
    } else if (tabActive === TAB[1].name) {
      if (subTabActive === SUB_TAB[2].name) {
        handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_INFINITY_GALLERY_IMAGE);
      }
      if (subTabActive === SUB_TAB[0].name) {
        handleGetFilmRender(FILM_TYPE.ORLA_INFINITY_FILM);
      }
      if (subTabActive === SUB_TAB[1].name) {
        handleGetListLevel(LEVEL_TYPE.ORLA_INFINITY_LEVEL);
        handleGetGalleryDetail(FLOOR_TYPE.ORLA_INFINITY_FLOOR);
      }
    } else if (tabActive === TAB[2].name) {
      if (subTabActive === SUB_TAB[2].name) {
        handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_MANSION_GALLERY_IMAGE);
      }
      if (subTabActive === SUB_TAB[0].name) {
        handleGetFilmRender(FILM_TYPE.ORLA_MANSION_FILM);
      }
      if (subTabActive === SUB_TAB[1].name) {
        handleGetListLevel(LEVEL_TYPE.ORLA_MANSION_LEVEL);
        handleGetGalleryDetail(FLOOR_TYPE.ORLA_MANSION_FLOOR);
      }
    } else if (tabActive === TAB[3].name) {
      if (subTabActive === SUB_TAB[2].name) {
        handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_SKY_PLACES_GALLERY_IMAGE);
      }
      if (subTabActive === SUB_TAB[0].name) {
        handleGetFilmRender(FILM_TYPE.ORLA_SKY_PLACES_FILM);
      }
      if (subTabActive === SUB_TAB[1].name) {
        handleGetListLevel(LEVEL_TYPE.ORLA_SKY_PLACES_LEVEL);
        handleGetGalleryDetail(FLOOR_TYPE.ORLA_SKY_PLACES_FLOOR);
      }
    }
  }, [tabActive, subTabActive]);

  const handleGetImageRender = async (type) => {
    const res = await galleryApi.findOneGalleryDetail({
      'type[equal]': type,
    });
    if (res) {
      setImgRender(res.data);
    }
  };

  const handleGetFilmRender = async (type) => {
    const res = await galleryApi.findOneGalleryDetail({
      'type[equal]': type,
    });
    if (res) {
      setFilmRender(res.data);
    }
  };

  const handleGetListLevel = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloor(res.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const handleGetGalleryDetail = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const floorDetailRes = await galleryApi.findOneGalleryDetail(data);
      if (floorDetailRes) {
        setFloorDetail(floorDetailRes.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const onClickTab = (name) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_GALLERY_TAB, {
        name,
      });
    }
    setTabActive(name);
  };
  const onClickSubTab = (name) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB, {
        name,
      });
    }
    setSubTabActive(name);
  };

  const renderTab = () => {
    return (
      <div className="tab-wrapper">
        <div className="main-tab">
          {TAB.map((t, idx) => {
            const isActive = t.name === tabActive;
            return (
              <div
                key={`tab-${idx}`}
                className={`main-tab-item`}
                style={{ width: t?.width }}
                onClick={() => onClickTab(t.name)}
              >
                <span className={`main-tab-text ${isActive ? "active" : ""}`}>
                  {t.name}
                </span>
                {isActive ? (
                  <ArrowCircleIcon style={{ width: 22, height: 22 }} />
                ) : (
                  <ArrowCircleLightIcon style={{ width: 22, height: 22 }} />
                )}
              </div>
            );
          })}
        </div>
        <div className="secondary-tab">
          {SUB_TAB.map((s, idx) => {
            const isActive = s.name === subTabActive;
            if (idx === 1) return null
            return (
              <div
                key={`subTab-${idx}`}
                className="secondary-tab-wrap-item"
                onClick={() => onClickSubTab(s.name)}
              >
                <div className={`secondary-tab-item`}>
                  <span
                    className={`secondary-tab-text ${isActive ? "active" : ""}`}
                  >
                    {s.name}
                  </span>
                </div>
                <div className="secondary-tab-border">
                  <div
                    className={`secondary-tab-border-line ${isActive ? "active" : ""
                      }`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderOrla = () => {
    //tabActive
    switch (subTabActive) {
      case SUB_TAB_GALLERY.RENDER:
        return <Renders data={imageRender} isPresentation={isPresentation} />;
      case SUB_TAB_GALLERY.FILMS:
        return <Films data={filmRender} isPresentation={isPresentation} />;
      case SUB_TAB_GALLERY.FLOOR_PLANS:
        return <FloorPlans
          isPresentation={isPresentation}
          data={floor}
          // floorDetail={floorDetail}
          tabActive={tabActive} subTabActive={subTabActive} />;
    }
  };

  const renderContent = () => {
    return <div className="gallery-content">{renderOrla()}</div>;
  };

  return (
    <div className="static-page gallery-landing" >
      <div className="gallery-landing-wrapper">
        {renderTab()}
        {renderContent()}
      </div>
    </div>
  );
};

export default GalleryLanding;
