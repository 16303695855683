import React from "react";
import DatePicker from 'sassy-datepicker';

const Calendar = (props) => {
  const {selectedDate, setSelectedDate} = props;
  const today = new Date();

  return (
    <DatePicker
      value={selectedDate}
      onChange={setSelectedDate}
      minDate={today}
      weekStartsFrom="Monday"
      className="absolute"
    />
  )
}

export default Calendar;
