import React, { useEffect, useRef, useState } from "react";
import UnitList from "./UnitList";
import UnitDetail from "./UnitDetail";
import UnitFilter from "./UnitFilter";
import "./index.scss";
import FloorPlanGallery from "../floor-plan-gallery";
import Gallery from "../gallery";
import VirtualTour from "../virtual-tour";
import { useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";

const UnitExplore = (props) => {
  const { handleUnitClick, handleAreaClick, setActiveObjectIds, isPresentation } = props;
  const dispatch = useDispatch();
  const floorPlanGalleryRef = useRef();

  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );
  const isShowUnitDetail = useSelector(
    (state) => state.unitExplore.isShowUnitDetail
  );
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const units = useSelector((state) => state.unitExplore.units);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );

  const filterUnitArea = useSelector(
    (state) => state.unitExplore.filterUnitArea
  );
  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect
  );
  const filterUnitType = useSelector(
    (state) => state.unitExplore.filterUnitType
  );
  const filterUnitCellingHeight = useSelector(
    (state) => state.unitExplore.filterUnitCellingHeight
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);

  useEffect(() => {
    dispatch(
      unitExploreAct.reqGetUnitArea({
        "area_type[equal]": "Units Area",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  }, [unitQuery]);

  useEffect(() => {
    onFilterChange();
  }, [
    filterUnitArea,
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitCellingHeight,
    filterUnitType,
    filterUnitPrice,
  ]);

  useEffect(() => {
    if (isFiltered()) {
      setActiveObjectIds(
        units.map((item) => {
          return item['3d_asset']?.id;
        })
      );
    } else if (selectedUnit) {
      const unitFiltereds = units.filter((item) => item.id == selectedUnit.id);
      if (unitFiltereds.length) setActiveObjectIds([unitFiltereds[0]['3d_asset']?.id]);
    } else {
      setActiveObjectIds([]);
    }
  }, [units]);

  const isFiltered = () => {
    return filterUnitAspect || filterUnitType
      || filterUnitCellingHeight || filterUnitBedroom.length || filterUnitArea
      || filterUnitPrice?.max || filterUnitPrice?.min;
  }


  const onFilterChange = () => {
    const query = {};
    if (filterUnitArea) {
      query["area[equalId]"] = filterUnitArea.id;
    }

    if (filterUnitAspect) {
      query["aspect[equal]"] = filterUnitAspect.value;
    }

    if (filterUnitBedroom.length) {
      query["bedrooms[in]"] = JSON.stringify(
        filterUnitBedroom.map((item) => item.value)
      );
    }

    if (filterUnitType) {
      query["type[equal]"] = filterUnitType.value;
    }

    if (filterUnitCellingHeight) {
      query["cellingHeight[equal]"] = filterUnitCellingHeight.value;
    }

    if (filterUnitPrice) {
      if (filterUnitPrice.min) {
        query["price[gte]"] = filterUnitPrice.min;
      }

      if (filterUnitPrice.max) {
        query["price[lte]"] = filterUnitPrice.max;
      }
    }

    dispatch(unitExploreAct.reqSetUnitQuery(query));
  };

  return (
    <>
      <div className={`wrap-sidenav`}>
        <CSSTransition
          in={isShowFilter}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <UnitFilter
            isPresentation={isPresentation}
            handleAreaClick={handleAreaClick}
            isTransparent={isTransparent}
            setActiveObjectIds={setActiveObjectIds}
          />
        </CSSTransition>
        <div
          className={`float-start position-relative ${isTransparent ? "invisible" : ""
            }`}
        >
          <CSSTransition
            in={isShowUnitList}
            timeout={500}
            classNames="fade-left"
            unmountOnExit
          >
            <UnitList isPresentation={isPresentation} handleUnitClick={handleUnitClick} />
          </CSSTransition>
        </div>
      </div>

      <CSSTransition
        in={!!selectedUnit && isShowUnitDetail}
        timeout={500}
        classNames="fade-item"
        unmountOnExit
      >
        <div
          className={`wrap-sidenav wrap-sidenav--right ${isTransparent ? "invisible" : ""
            }`}
        >
          <UnitDetail />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isShowFloorplan}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <FloorPlanGallery floorPlanGalleryRef={floorPlanGalleryRef} />
      </CSSTransition>

      <TransitionGroup>
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Gallery />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowVirtualTour && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <VirtualTour />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default UnitExplore;
