import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import icHome from "../../assets/images/icHome.svg";
import logo from "../../assets/images/orla_logo.png";

const TopNavCMS = (props) => {
  const history = useHistory();

  const logout = () => {
    jwtAuth.removeToken();
    history.push("/holding");
  };
  return (
    <div className="wrap-top-nav show">
      <div className="nav-menu nav-menu-cms">
        <ul className="left">
          <li>
            <Link to="/cms/properties" className="text-decoration-none">
              <div>Residences</div>
            </Link>
          </li>
          <li>
            <Link to="/cms/customers" className="text-decoration-none">
              <div>Customers</div>
            </Link>
          </li>
        </ul>
        <img width="105px" height="25px" src={logo} alt="icon-cube" onClick={() => history.push("/")}/>
        <ul className="right">
          <li>
            <Link to="/cms/profiles" className="text-decoration-none">
              <div>Profile</div>
            </Link>
          </li>
          <li>
            <Link
              to="/cms/frequently-asked-questions"
              className="text-decoration-none"
            >
              <div>FAQs</div>
            </Link>
          </li>
          <li onClick={() => logout()}>
            <div>Log Out</div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopNavCMS;
