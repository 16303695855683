import { Mesh, PerspectiveCamera, PlaneGeometry, Scene, ShaderMaterial } from "three";

export default class Blit {
  constructor(customFragment) {
    this.material = new ShaderMaterial({
      uniforms: {
        uTexture: { type: "t", value: null },
      },

      vertexShader: `
          varying vec2 vUv;

          void main() {
              vUv = uv;
              gl_Position = vec4(position.xy, 0.0, 1.0);    
          }
      `,

      fragmentShader: `
          uniform sampler2D uTexture;

          varying vec2 vUv;

          void main() {
              ${
                customFragment
                  ? customFragment
                  : "gl_FragColor = texture2D(uTexture, vUv);"
              }  
          }
      `,

      depthTest: false,
      depthWrite: false,
    });

    this.mesh = new Mesh(
      new PlaneGeometry(2, 2),
      this.material
    );
    this.camera = new PerspectiveCamera(
      45,
      1 /* remember that the camera is worthless here */,
      1,
      1000
    );

    this.scene = new Scene();
    this.scene.add(this.mesh);
  }

  blit(renderer, textureFrom, renderTargetDest) {
    renderer.setRenderTarget(renderTargetDest);

    this.material.uniforms.uTexture.value = textureFrom;
    renderer.render(this.scene, this.camera);

    renderer.setRenderTarget(null);
  }
}
