import React, { Suspense, useEffect, useRef, useState } from "react";
// import {
//   Environment,
//   OrbitControls,
//   PerspectiveCamera,
// } from "@react-three/drei";
import SSRBuffers from "./SSRBuffers";
import Blit from "./blit";
import {
  ACESFilmicToneMapping,
  FloatType,
  // Group,
  Mesh,
  PMREMGenerator,
  SRGBColorSpace,
  Scene,
  Sprite,
  WebGLRenderTarget,
} from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { SphereSceneContainer } from "./SphereSceneContainer";
import { Models } from "./Models";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { computeSSR } from "./ssr";
import { GroundPlane } from "./GroundPlane";
import { computeBlur } from "./blur";
import { computeDOF } from "./dof";
import { renderWithExistingDepthBuffer } from "./renderWithExistingDepthBuffer";

// to make the text of the hotspots readable, we have unfortunately to
// increase the pixel ratio of a few RTs, namely spriteRT and radianceRT
// (plus the output RT) - Ideally, the proper solution would be to use
// non-image based text representations
const textBasedPixelRatio = 2;

const USE_DOF = false;

const cameraFar = 25000;
const ssrBuffersProgram = new SSRBuffers(window.innerWidth, window.innerHeight);
const blitProgram = new Blit();
const ssrRadianceRT = new WebGLRenderTarget(
  window.innerWidth,
  window.innerHeight,
  { type: FloatType },
);
const radianceRT = new WebGLRenderTarget(
  window.innerWidth * textBasedPixelRatio,
  window.innerHeight * textBasedPixelRatio,
  // we can't do that: on iphones / ipadPro unfortunately.
  // check the docs to understand the full setup
  // // { samples: 4, type: FloatType }
  { samples: 4 },
);
const spriteRT = new WebGLRenderTarget(
  window.innerWidth * textBasedPixelRatio,
  window.innerHeight * textBasedPixelRatio,
  {
    samples: 4,
  },
);
const dofRT = new WebGLRenderTarget(window.innerWidth, window.innerHeight, {
  type: FloatType,
});
const ssrRT = new WebGLRenderTarget(window.innerWidth, window.innerHeight, {
  type: FloatType,
});
const normalBuffer = ssrBuffersProgram.GBuffer.texture[0];
const positionBuffer = ssrBuffersProgram.GBuffer.texture[1];

export const sharedData = {
  envMap: null,
  equirectEnvMap: null,
  stencilTestScene: new Scene(),
  ssrRT: ssrRT,
  textBasedPixelRatio: textBasedPixelRatio,
};

let envmapDownloadStarted = false;

function App() {
  const [envmapDownloaded, setEnvmapDownloaded] = useState(false);
  // const controlsRef = useRef(null);

  const { gl } = useThree();

  useEffect(() => {
    gl.toneMapping = ACESFilmicToneMapping;
    // gl.outputEncoding = sRGBEncoding;
    gl.outputColorSpace = SRGBColorSpace;
    gl.setSize(window.innerWidth, window.innerHeight);
    // for some reason setting the PR here doesn't work since it seems like
    // it gets overwritten later
    // gl.setPixelRatio(textBasedPixelRatio);
  }, []);

  useEffect(() => {
    if (envmapDownloadStarted) return;
    envmapDownloadStarted = true;

    (async function () {
      let pmrem = new PMREMGenerator(gl);
      let envmapTexture = await new RGBELoader()
        .setDataType(FloatType)
        .loadAsync("envmap2_blur_4.hdr");
      sharedData.equirectEnvMap = envmapTexture;
      let envMap = pmrem.fromEquirectangular(envmapTexture).texture;

      sharedData.envMap = envMap;
      setEnvmapDownloaded(true);
    })();
  }, []);

  useFrame(({ gl, scene, camera }) => {
    // if (!controlsRef.current) return;

    // controlsRef.current.update();

    // gl.render(scene, camera);

    if (gl.getPixelRatio() !== textBasedPixelRatio) {
      gl.setPixelRatio(textBasedPixelRatio);
    }

    function turnOffFBXModel(scene) {
      const group = scene.getObjectByName("FbxModelGroup");
      if (group) group.visible = false;
    }
    function turnOnFBXModel(scene) {
      const group = scene.getObjectByName("FbxModelGroup");
      if (group) group.visible = true;
    }
    function turnOffTonemapping(scene) {
      scene.children.forEach((child) => {
        if (child.material?.userData?.uniforms?.uApplyGamma) {
          child.material.userData.uniforms.uApplyGamma.value = false;
        }
      });
    }
    function turnOnTonemapping(scene) {
      scene.children.forEach((child) => {
        if (child.material?.userData?.uniforms?.uApplyGamma) {
          child.material.userData.uniforms.uApplyGamma.value = true;
        }
      });
    }
    function turnOffSprites(scene) {
      scene.traverse((child) => {
        if (child.isHotspotSprite) {
          child.canvasBoxVisibility = child.visible;
          child.visible = false;
        }
      });
    }
    function turnOnSprites(scene) {
      scene.traverse((child) => {
        if (child.isHotspotSprite) {
          // sprite will be visible only if it's set to visible by CanvasBox
          child.visible = child.canvasBoxVisibility;
        }
      });
    }
    // hides all objects that are not sprites
    function hideNonSprites(scene) {
      scene.traverse((child) => {
        // this guard is necessary to avoid setting Scene and Groups to visible=false
        if (!(child instanceof Mesh || child instanceof Sprite)) return;
        // don't change visibility of fbx model meshes / group
        if (child.type == "fbx-model-mesh" || child.name == "FbxModelGroup")
          return;

        if (!child.isHotspotSprite) {
          child.prevVisible = child.visible;
          child.visible = false;
        }
      });
    }
    // shows all objects that are not sprites
    function showNonSprites(scene) {
      scene.traverse((child) => {
        // this guard is necessary to avoid setting Scene and Groups to visible=false
        if (!(child instanceof Mesh || child instanceof Sprite)) return;
        // don't change visibility of fbx model meshes / group
        if (child.type == "fbx-model-mesh" || child.name == "FbxModelGroup")
          return;

        if (!child.isHotspotSprite && child instanceof Mesh) {
          child.visible = child.prevVisible;
        }
      });
    }

    turnOffFBXModel(scene);
    turnOffTonemapping(scene);
    turnOffSprites(scene);
    gl.setRenderTarget(ssrRadianceRT);
    gl.render(scene, camera);
    gl.setRenderTarget(null);

    ssrBuffersProgram.compute(gl, scene, camera);

    computeSSR(
      gl,
      camera,
      ssrRT,
      ssrRadianceRT,
      ssrBuffersProgram.GBuffer,
      sharedData.equirectEnvMap,
    );
    // rt1 and rt0 are not used anywhere else in the app because computeBlur is
    // internally blitting rt1 inside ssrRT
    let { rt1, rt0 } = computeBlur(
      gl,
      camera,
      ssrRT,
      positionBuffer,
      normalBuffer,
    );

    gl.autoClear = false;
    gl.setRenderTarget(radianceRT);
    gl.clear(true, true, true);
    turnOnTonemapping(scene);
    // inside stencilTestScene there are all the ground elements that should display ssrs
    turnOnTonemapping(sharedData.stencilTestScene);
    gl.render(scene, camera);
    gl.render(sharedData.stencilTestScene, camera);
    gl.setRenderTarget(null);
    gl.autoClear = true;

    if (USE_DOF) {
      computeDOF(gl, radianceRT.texture, positionBuffer, dofRT, cameraFar);

      // ******* now we'll render the sprites / hotspots separately *******

      // sadly, I need to do yet another blit to move from an RT with
      // only 1 sample (dofRT), to an RT with 4 samples (spriteRT), because
      // renderWithExistingDepthBuffer only works if both RTs have the same
      // number of samples..
      blitProgram.blit(gl, dofRT.texture, spriteRT);
    }

    // Only draw the sprites, by re-using the depth buffer of radianceRT
    // we need to do it after computeDOF otherwise the hotspots might be
    // blurred by the DOF shader
    hideNonSprites(scene);
    turnOnFBXModel(scene);
    turnOnSprites(scene);
    if (USE_DOF) {
      renderWithExistingDepthBuffer(
        gl,
        spriteRT, // target RT, unfortunately I need to specify one
        radianceRT, // RT from which we're taking the depth buffer
        scene,
        camera,
      );
      blitProgram.blit(gl, spriteRT.texture, null);
    } else {
      gl.autoClear = false;
      gl.setRenderTarget(radianceRT);
      gl.render(scene, camera);
      gl.autoClear = true;
      blitProgram.blit(gl, radianceRT.texture, null);
    }
    showNonSprites(scene);

    // const model = scene.getObjectByName("64c8c3326a166d6d4b1f3c00");
    // if (model) {
    //   console.log(model.material.userData.mapRef?.uuid, model.material.uuid);
    // }

    // hideNonSprites(scene);
    // turnOffSprites(scene);
    // turnOnSprites(scene);
    // // showNonSprites(scene);
    // gl.autoClear = true;
    // gl.render(scene, camera);

    // blitProgram.blit(gl, ssrRadianceRT.texture, null);
    // blitProgram.blit(gl, rt1.texture, null);
    // blitProgram.blit(gl, positionBuffer, null);
    // blitProgram.blit(gl, normalBuffer, null);
    // blitProgram.blit(gl, radianceRT.texture, null);
    // blitProgram.blit(gl, ssrRT.texture, null);
  }, 2);

  if (!envmapDownloaded) return null;

  return (
    <Suspense fallback={null}>
      {/* <PerspectiveCamera
      makeDefault
      position={[-84.77, 17.13, -124.55]}
      fov={12.5}
      aspect={window.innerWidth / window.innerHeight}
      near={1}
      far={cameraFar}
    /> */}
      {/* <OrbitControls ref={controlsRef} target={[3.13, 2.98, -7.97]} dampingFactor={0.05} rotateSpeed={0.5} /> */}

      <GroundPlane />
      <SphereSceneContainer />
      <Models />
    </Suspense>
  );
}

export default App;
