import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  BEDROOM_OPTION,
  ASPECT_OPTION,
  UNIT_TYPE_OPTION,
  CELLING_HEIGHT_OPTION,
  ACTION_NAME,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import SideNav from "../sidenav";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import _ from "lodash";
import socket from '../../helper/socket';
import { capitializeText } from "../../helper/utils";

const UnitFilter = (props) => {
  const { handleAreaClick, setActiveObjectIds, isPresentation } = props;
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const unitAreas = useSelector((state) => state.unitExplore.unitAreas);
  const filterUnitArea = useSelector(
    (state) => state.unitExplore.filterUnitArea,
  );
  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom,
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect,
  );
  const filterUnitType = useSelector(
    (state) => state.unitExplore.filterUnitType,
  );
  const filterUnitCellingHeight = useSelector(
    (state) => state.unitExplore.filterUnitCellingHeight,
  );
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList,
  );
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
          handleApplyFilter(content.data);
        } else if (content.action == ACTION_NAME.CLEAR_ALL_FILTERS) {
          onResetAllFilter();
        } else if (content.action == ACTION_NAME.HIDE_FILTER) {
          onHideFilter();
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current &&
            (pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    onChangePrice({
      min: minPrice,
      max: maxPrice,
    });
  }, [minPrice, maxPrice]);

  const handleApplyFilter = (data) => {
    const filterName = data.name;
    const item = data?.item;

    switch (filterName) {
      case "FILTER_BEDROOM":
        onChangeBedroom(item, data.filterUnitBedroom);
        break;
      case "FILTER_AREA":
        onChangeArea(item, data.filterUnitArea);
        break;
      case "FILTER_UNIT_ASPECT":
        onFilterUnitAspect(item, data.filterUnitAspect);
        break;
      case "FILTER_UNIT_TYPE":
        onFilterUnitType(item, data.filterUnitType);
        break;
      case "FILTER_UNIT_CELLING_HEIGHT":
        onFilterUnitCellingHeight(item, data.filterUnitCellingHeight);
        break;
      case "FILTER_UNIT_PRICE":
        onChangePrice(data.filterUnitPrice);
        break;
      default:
        break;
    }
  };

  const onChangeBedroom = (item, filterUnitBedroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitBedroom,
        name: "FILTER_BEDROOM",
      });
    }

    if (_.find(filterUnitBedroom, (el) => el.id === item.id)) {
      const newBedRoom = filterUnitBedroom.filter((i) => i.id !== item.id);
      dispatch(unitExploreAct.reqFilterUnitBedroom(newBedRoom));
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitBedroom([...filterUnitBedroom, item]),
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onChangeArea = (item, filterUnitArea) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitArea,
        name: "FILTER_AREA",
      });
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    dispatch(unitExploreAct.reqSetSelectedUnit(""));
    handleAreaClick(item);
    const area = filterUnitArea?.id == item.id ? "" : item;
    dispatch(unitExploreAct.reqFilterUnitArea(area));
  };

  const onFilterUnitAspect = (item, filterUnitAspect) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAspect,
        name: "FILTER_UNIT_ASPECT",
      });
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    const unitAspect = filterUnitAspect?.id == item.id ? "" : item;
    dispatch(unitExploreAct.reqFilterUnitAspect(unitAspect));
  };

  const onFilterUnitType = (item, filterUnitType) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitType,
        name: "FILTER_UNIT_TYPE",
      });
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    const unitType = filterUnitType?.id == item.id ? "" : item;
    dispatch(unitExploreAct.reqFilterUnitType(unitType));
  };

  const onFilterUnitCellingHeight = (item, filterUnitCellingHeight) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitCellingHeight,
        name: "FILTER_UNIT_CELLING_HEIGHT",
      });
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    const unitCellingHeight =
      filterUnitCellingHeight?.id == item.id ? "" : item;
    dispatch(unitExploreAct.reqFilterUnitCellingHeight(unitCellingHeight));
  };

  const onChangePrice = (filterUnitPrice) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: "",
        filterUnitPrice,
        name: "FILTER_UNIT_PRICE",
      });
    } else {
      setMaxPrice(filterUnitPrice.max)
      setMinPrice(filterUnitPrice.min)
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    dispatch(unitExploreAct.reqFilterUnitPrice({ ...filterUnitPrice }));
  };

  const onResetAllFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLEAR_ALL_FILTERS);
    }

    dispatch(unitExploreAct.reqSetIsShowUnitList(false));
    dispatch(unitExploreAct.reqFilterUnitArea());
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAspect());
    dispatch(unitExploreAct.reqFilterUnitType());
    dispatch(unitExploreAct.reqFilterUnitCellingHeight());
    dispatch(unitExploreAct.reqFilterUnitPrice());
    dispatch(unitExploreAct.reqSetSelectedUnit(""));
    dispatch(unitExploreAct.reqSetUnitQuery({}));
    setActiveObjectIds([]);
    setMinPrice("");
    setMaxPrice("");
  };

  const onHideFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <SideNav
      position="left"
      heading="Filters"
      id="filter"
      isDark={false}
      isTransparent={props.isTransparent}
    >
      <div ref={pageRef} className="scroll-filter" onScroll={onScroll}>
        {/* filter house type */}
        <div className="filter-group mb-1">
          <ul className="list-group">
            {unitAreas.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => onChangeArea(item, filterUnitArea)}
                  className={`rounded-0 border-1 mb-1 list-group-item ${
                    item.id === filterUnitArea?.id ? "active" : ""
                  }`}
                >
                  {capitializeText(item.name, "ORLA")}
                </li>
              );
            })}
          </ul>
        </div>

        {/* filter bedrooms  */}
        <div className="filter-group mb-1">
          <h2 className="heading">Bedrooms</h2>
          <ul className="list-group flex flex-row justify-content-between flex-wrap">
            {BEDROOM_OPTION.map((item, key) => (
              <li
                onClick={() => onChangeBedroom(item, filterUnitBedroom)}
                className={`list-group-item ${
                  _.find(filterUnitBedroom, (i) => i.id == item.id)
                    ? "active"
                    : ""
                }`}
                key={`filter-item-${key}`}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>

        {/* filter aspect */}
        <div className="filter-group mb-1">
          <h2 className="heading">Aspect</h2>
          <ul className="list-group flex flex-col">
            {ASPECT_OPTION.map((item, key) => (
              <li
                onClick={() => onFilterUnitAspect(item, filterUnitAspect)}
                className={`rounded-0 border-1 mb-1 list-group-item ${
                  item.id === filterUnitAspect?.id ? "active" : ""
                }`}
                key={`filter-item-${key}`}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>

        {/* filter type */}
        <div className="filter-group mb-1">
          <h2 className="heading">Unit Type</h2>
          <ul className="list-group flex flex-col">
            {UNIT_TYPE_OPTION.map((item, key) => (
              <li
                onClick={() => onFilterUnitType(item, filterUnitType)}
                className={`rounded-0 border-1 mb-1 list-group-item ${
                  item.id === filterUnitType?.id ? "active" : ""
                }`}
                key={`filter-item-${key}`}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>

        {/* filter celling height */}
        <div className="filter-group mb-1">
          <h2 className="heading">Ceiling Height</h2>
          <ul className="list-group flex flex-col">
            {CELLING_HEIGHT_OPTION.map((item, key) => (
              <li
                onClick={() =>
                  onFilterUnitCellingHeight(item, filterUnitCellingHeight)
                }
                className={`rounded-0 border-1 mb-1 list-group-item ${
                  item.id === filterUnitCellingHeight?.id ? "active" : ""
                }`}
                key={`filter-item-${key}`}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>

        {/* filter unit price */}
        <div className="filter-group mb-1">
          <h2 className="heading">Price range</h2>
          <div className="d-flex justify-content-between flex-col">
            <div className="me-3">
              <input
                value={minPrice}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setMinPrice(e.target.value);
                  }
                }}
                className="form-control rounded-0"
                type="number"
                placeholder="$$$$"
              />
            </div>
            <div>
              <input
                value={maxPrice}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setMaxPrice(e.target.value);
                  }
                }}
                className="form-control rounded-0"
                type="number"
                placeholder="$$$$"
              />
            </div>
          </div>
        </div>

        <div className="wrapper-button">
          <div
            className={`btn-bottom ${
              isShowUnitList ? "unActive-btn" : "active-btn"
            }`}
            onClick={() => onResetAllFilter()}
          >
            <img
              src={
                isShowUnitList
                  ? "/icons/close.svg"
                  : "/icons/close-unActive.svg"
              }
              alt=""
            />
            <span className={isShowUnitList ? "txt-active" : ""}>
              Clear filter
            </span>
          </div>
          <div
            className={`btn-bottom ${
              isShowUnitList ? "unActive-btn" : "active-btn"
            }`}
            onClick={() => onHideFilter()}
          >
            <img
              src={
                isShowUnitList
                  ? "/icons/arrow.svg"
                  : "/icons/arrow-unActive.svg"
              }
              alt=""
            />
            <span className={isShowUnitList ? "txt-active" : ""}>
              Hide filter
            </span>
          </div>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitFilter;
