import React, { useRef, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Accordion, AccordionContext } from "react-bootstrap";
import { config, options } from "./map-config";

import "./index.scss";
import {
  reqGetPoiList,
  reqSetActivePoi,
  reqSetActiveType,
} from "../../reduxs/locations/action";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
} from "../../reduxs/explore-modal/action";

const activeMarkers = [];

const Location = (props) => {
  const pois = useSelector((state) => state.poi.data);
  const activePoi = useSelector((state) => state.poi.activePoi);
  const activeType = useSelector((state) => state.poi.activeType);
  const defaultActiveKey = useSelector((state) => state.poi.defaultActiveKey);
  const dispatch = useDispatch();
  const mapRef = useRef(false);
  const [shouldFit, setShouldFit] = useState(false);
  const { isPresentation } = props;
  const pageRef = useRef(null);

  useEffect(() => {
    dispatch(reqGetPoiList());
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_POI) {
          handleClickPoint(content.data.poi);
        }

        if (content.action === ACTION_NAME.CLICK_POI_ITEM) {
          handleShowItem(content.data.item);
        }

        if (content.action === ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED) {
          if (mapRef.current) {
            mapRef.current.state.map.setZoom(content.data.zoom);
          }
        }

        if (content.action === ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED) {
          if (mapRef.current) {
            mapRef.current.state.map.setCenter({
              lat: content.data.lat,
              lng: content.data.lng,
            });
          }
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current && (pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_TYPE, {
        activeType,
      });
    }
  }, [activeType]);

  useEffect(() => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_POI, {
        activePoi,
      });
    }
  }, [activePoi]);

  useEffect(() => {
    // Fit the bounds to the currently shown markers
    if (shouldFit && activeMarkers.length && mapRef) {
      let bounds = new google.maps.LatLngBounds();
      activeMarkers.forEach((marker) => {
        bounds.extend(marker);
      });

      if (!!mapRef?.current?.state?.map) {
        mapRef.current.state.map.setOptions({ maxZoom: 13 });
        mapRef.current.state.map.fitBounds(bounds);
        mapRef.current.state.map.setCenter(bounds.getCenter());
        mapRef.current.state.map.setOptions({ maxZoom: undefined });
      }
    }
    setShouldFit(false);
  });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI",
  });

  const showInfoWindow = (data) => {
    dispatch(reqSetActivePoi(""));
    dispatch(reqSetActivePoi(data.id));
  };

  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = (eventKey) => {
    dispatch(reqSetActiveType(eventKey == currentEventKey ? "" : eventKey));
    eventKey === currentEventKey && dispatch(reqSetActivePoi(""));
    setShouldFit(true);
  };

  const handleShowItem = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI_ITEM, {
        item,
      });
    }
    decoratedOnClick(item.group);
    handleClickSubPoint(item);
  };

  const getListPoint = () => {
    const listPoiItems = [];
    const poisOrlas = pois.filter(p => p.name.includes('ORLA'));
    const poisNotOrlas = pois.filter(p => !p.name.includes('ORLA'));
    const newPois = poisOrlas.concat(poisNotOrlas);

    newPois.map((item) => {
      listPoiItems.push(
        <li
          key={`${item.id}_id`}
          className={`list-group-item ${activePoi === item.id ? "active" : ""}`}
          onClick={() => handleShowItem(item)}
        >
          {item.name}
        </li>
      );
    });
    return listPoiItems;
  };


  const handleClickSubPoint = (poi) => {
    dispatch(reqSetExploreModal(poi?.modal?.id));
    dispatch(reqSetIsShowExploreModal(true));
    showInfoWindow(poi);
  };

  const handleClickPoint = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI, {
        poi,
      });
    }
    dispatch(reqSetExploreModal(poi?.modal?.id));
    dispatch(reqSetIsShowExploreModal(true));
    showInfoWindow(poi);
  };

  const renderMap = () => {
    // reset active markers on each render
    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={config.center}
        zoom={config.zoom}
        options={options}
        onZoomChanged={function () {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED, {
              zoom: this.zoom
            });
          }
        }}
        onCenterChanged={function () {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED, {
              lat: this.center.lat(),
              lng: this.center.lng(),
            });
          }
        }}
      >
        {pois.map((poi) => {
          if (activeType === poi.group)
            return (
              <Marker
                key={`${poi.id}_marker`}
                position={{
                  lat: poi.coordinate.lat,
                  lng: poi.coordinate.long,
                }}
                icon={
                  activePoi === poi.id
                    ? poi.icon
                    : "uploads/images/icons/inactive_hotspot.svg"
                }
                onClick={(e) => {
                  // showInfoWindow(poi);
                  handleClickPoint(poi);
                }}
                onLoad={(e) => {
                  activeMarkers.push(e.getPosition());
                }}
                onUnmount={(e) => {
                  let lat = e.getPosition().lat();
                  let lng = e.getPosition().lng();
                  activeMarkers.splice(
                    activeMarkers.findIndex((am) => {
                      return am.lng() === lng && am.lat === lat;
                    }, 1)
                  );
                }}
                data={poi}
              />
            );
        })}
      </GoogleMap>
    );
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <>
      <div className="wrap-location">
        {isLoaded && renderMap()}
        <div className={`card wrap-list-location side-card left-card`}>
          <h2 className="card-title">Points of interest</h2>
          <div ref={pageRef} className="card-body" onScroll={onScroll}>
            <Accordion defaultActiveKey={defaultActiveKey}>
              {getListPoint()}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
