import React, { useState } from "react";
import "./index.scss";
import Modal from "../../components/modal-base";
import customerApi from "../../apis/api/customer";
import { actAddOneCustomer } from "../../reduxs/cms/action";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { reqSetEditableCustomer } from "../../reduxs/cms/action";
import { ErrorMessage } from "@hookform/error-message";
import { CloseModalIcon } from '../../components/svgs/icons';

const CustomerModal = ({ isShow, onClose }) => {
  const dispatch = useDispatch();
  const editableCustomer = useSelector((state) => state.cms.editableCustomer);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setError,
  } = useForm({ values: editableCustomer });

  const onSubmit = (data) => {
    if (!editableCustomer) {
      handleCreateCustomer(data);
      return;
    } else {
      handleEditCustomer(data);
      return;
    }
  };

  const handleEditCustomer = async (data) => {
    try {
      const res = await customerApi.updateCustomer(editableCustomer.id, data);
      if (res.data) {
        onClose();
        toast.success("Update customer successfully");
      } else {
        setError("email", { message: "Email is existed, try again!" });
        toast.error("Email is existed, try again!");
      }
    } catch (err) {
      console.log("err------", err);
      toast.error(err.message);
    }
  };

  const handleCreateCustomer = async (data) => {
    try {
      const res = await customerApi.createCustomer({
        userGroup: "610a4a3351400773f94bfa89",
        ...data,
      });
      if (res.data) {
        onClose();
        toast.success("Create customer successfully");
      } else {
        setError("email", { message: "Email is existed, try again!" });
        toast.error("Email is existed, try again!");
      }
    } catch (err) {
      console.log("err------", err);
      toast.error(err.message);
    }
  };

  return (
    <Modal
      classNames="modal-request-support"
      open={isShow || !!editableCustomer}
      onHide={onClose}
      isOutSideClose={false}
      currentColor="#C7A446"
      isShowBtnClose={false}
    >
      <span className="close-button-x" onClick={onClose}>
        <CloseModalIcon />
      </span>
      <div className="modal-form__title">
        {editableCustomer ? "Update customer" : "New customer profile"}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} id="customer-form">
        <div className="wrapper-list-user">
          <div className="wrapper-info">
            <div className="form-info w-100">
              <Form.Group controlId="Firstname">
                <Form.Label className={errors["firstname"] && "error"}>
                  First name*
                </Form.Label>
                <Form.Control
                  className={errors["firstname"] && "error"}
                  type="text"
                  placeholder="First name"
                  {...register("firstname", {
                    required: { value: true, message: "Please enter a valid name" },
                    validate: (value) => !!value.trim() || "Please enter a valid name",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstname"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </div>
            <div className="form-info w-100">
              <Form.Group controlId="Firstname">
                <Form.Label className={errors["surname"] && "error"}>
                  Last name*
                </Form.Label>
                <Form.Control
                  className={errors["surname"] && "error"}
                  type="text"
                  placeholder="Last name"
                  {...register("surname", {
                    required: { value: true, message: "Please enter a valid name" },
                    validate: (value) => !!value.trim() || "Please enter a valid name",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="surname"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="wrapper-info">
            <div className="form-info w-100">
              <Form.Group controlId="email">
                <Form.Label className={errors["email"] && "error"}>
                  Email*
                </Form.Label>
                <Form.Control
                  className={errors["email"] && "error"}
                  type="text"
                  placeholder="Email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Please enter a valid email",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <Form.Text className="error-text">{message}</Form.Text>
                  )}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Form>
      <div className="wrap-button-list-user d-flex justify-content-between">
        <button
          type="submit"
          form="customer-form"
          className={isDirty ? "submit-button dirty" : "submit-button"}
          disabled={!isDirty}
        >
          {editableCustomer ? "Save" : "Create"}
        </button>
        <button className="close-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default CustomerModal;
