import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUploadedMediaUrl } from "../../helper/media";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import galleryApi from "../../apis/api/gallery";
import { useDispatch } from "react-redux";
import {
  reqSetActivePatourId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import socket from "../../helper/socket";
import { LeftArrowIcon, RightArrowIcon } from "../svgs/icons";

const Gallery = (props) => {
  const [gallery, setGallery] = useState("");
  const [media, setMedia] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch();
  const galleryRef = useRef();
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    swipeToSlide: true,
    afterChange: (index) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
          index,
        });
      }

      // Display limited dots
      const activeDotEl = document.querySelector('.slick-dots li.dot-active');
      activeDotEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    },
    beforeChange: (current, next) => {
      setCurrentSlide(next)
    },
    appendDots: function Dot(dots) {
      return (
        <div>
          <div className="btn-prev cursor-pointer" onClick={() => galleryRef.current.slickPrev()}>
            <LeftArrowIcon style={{width: 21, height: 35}} fill={'#C7A446'}/>
          </div>
          <ul>
            {dots.map((item, index) => {
              return (
                <li key={index} className={index === currentSlide ? "dot-active": ""}>{item.props.children}</li>
              );
            })}
          </ul>
          <div className="btn-next cursor-pointer" onClick={() => galleryRef.current.slickNext()}>
            <RightArrowIcon style={{width: 21, height: 35}} fill={'#C7A446'}/>
          </div>
        </div>
      )
    },
  };

  const HOTSPOT_TYPES = {
    PANO_TOUR: "360_pano_tour",
    URL: "url",
    VIDEO: "video",
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          return showFilter();
        }
        if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
          onGoToSlideIndex(content.data.index);
        }
      })
    }
  }, []);

  useEffect(() => {
   galleryApi.getGalleryDetail(activeGalleryId).then((res) => {
        setGallery(res.data);
        });
  }, [activeGalleryId]);

  useEffect( () => {
    setMedia(gallery?.media ? gallery.media : []);
  }, [gallery]);

  const handleClickHotspot = async (data) => {
    const hotpotData = data;

    if (hotpotData.link_type == HOTSPOT_TYPES.PANO_TOUR) {
      dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
      dispatch(reqSetActivePatourId(hotpotData.link));
    }
  };

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  }

  return (
    <>
      <div className="wrap-gallery-image">
        <Slider ref={galleryRef} {...settings}>
          {(media || []).map((item, key) => {
            return (
              <div key={key}>
                <div
                  className="gallery-item"
                  style={{
                    backgroundImage: `url(${getUploadedMediaUrl(item.path)})`,
                  }}
                >
                  {(item.hotspots || []).map((item, index) => {
                    return (
                      <img
                        onClick={() => handleClickHotspot(item)}
                        key={index}
                        style={{
                          left: `${item.position.x}%`,
                          top: `${item.position.y}%`,
                        }}
                        className="gallery-hotspot"
                        src={getUploadedMediaUrl(item.image_path)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      {isTransparent && (
        <div className="wrap-close-btn">
          <div onClick={showFilter}>
            <img src="/icons/add.svg" alt="" />
            <span>Reopen panels</span>
          </div>
          <div onClick={hideGallery}>
            <img src="/icons/close.svg" alt="" />
            <span>Close gallery</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
