import React, { useEffect, useRef } from "react";
import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const About = (props) => {
  const { isPresentation } = props;
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current &&
            (pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <div className="static-page aboutPage">
      <div ref={pageRef} className="scroll-view" onScroll={onScroll}>
        <div className="about-1">
          <div className="overlay" />
          <div className="image">
            <span className="text">
              Introducing ORLA and ORLA Infinity, two distinct, exclusive
              residences that form a singular, exclusive destination, sharing
              world class amenities.
              <br />
              <br />
              ORLA exudes refined elegance, providing an oasis of tranquility,
              while ORLA Infinity offers unparalleled privacy. Together, they
              harmoniously enrich the lives of discerning individuals,
              redefining resort-style living within one ultra-luxury residential
              community.
            </span>
          </div>
        </div>
        <div className="about-2">
          <div className="bg">
            <div className="bg-image" />
            <div className="bg-overlay" />
          </div>
          <div className="layout">
            <div className="text">
              <span className="title">
                at the apex
                <br />
                of an icon,
                <br />
                palm jumeirah
              </span>
              <span className="content">
                Palm Jumeirah is an exclusive destination intimately connected
                to the sea, offering unparalleled beauty.
                <br />
                <br />
                Nearby, you’ll discover Dubai’s finest restaurants, clubs, and
                beach resorts, providing a world-class experience. The wonders
                of Dubai are easily accessible, ensuring that every captivating
                sight is within your reach.
                <br />
                <br />
                Situated on a beachfront plot at the apex of the Palm’s
                crescent, this prime position offers uninterrupted 270-degree
                views, encompassing Dubai’s stunning skyline and the turquoise
                waters of the Arabian Gulf.
              </span>
            </div>
            <div className="image" />
          </div>
        </div>
        <div className="about-3">
          <div className="image" />
          <div className="overlay" />
          <div className="logo">
            <div className="logo-overlay" />
            <div className="logo-image" />
          </div>
          <div className="text">
            <span className="title">stunning from sea to sky</span>
            <span className="content">
              Your residence at ORLA is your exclusive escape, where indoor and
              outdoor blend seamlessly with sea and sky, as energising light
              streams through each residence creating eternal vistas from the
              moment the sun rises above the horizon to the last golden rays
              shimmering off the sea below.
            </span>
          </div>
        </div>

        <div className="about-4-5-6">
          <div className="overlay" />
          <div className="layout">
            <div className="about-4">
              <div className="text">
                <span className="title">
                  an arrival
                  <br />
                  to remember
                </span>
                <span className="content">
                  Each ORLA residence has a seamless arrival experience unique
                  to each home. Once you have arrived into your private paradise
                  you will be welcomed by full floor to ceiling windows, double
                  height spaces of up to 6.5 metre, and huge terraces with
                  landscaping all around. Larger units will have private
                  swimming pools on their terraces, enthralled by a vista of
                  city and sea.
                </span>
              </div>
              <div className="image" />
            </div>
            <div className="about-5">
              <div className="image" />
              <div className="text">
                <span className="title">RESIDENCES THAT STAND APART</span>
                <span className="content">
                  With each residence carefully positioned and designed to
                  beautifully blend indoor and outdoor spaces and create vantage
                  points to capturing the many magnificent views across the
                  facilities and to the city beyond, you will be left in awe at
                  the ever-shifting seascapes that greet you around every
                  corner.
                </span>
              </div>
            </div>
            <div className="about-6">
              <div className="text">
                <span className="title">
                  NO TWO
                  <br />
                  ARE QUITE ALIKE
                </span>
                <span className="content">
                  ORLA’s 85 tower residences are a mix of two, three and
                  four-bedroom units floating above the resort and shimmering
                  sea. Each have a unique layout spread over one or two storeys,
                  ensuring the best use of each space and providing an array of
                  options to suit every resident’s individual desires.
                  <br />
                  <br />
                  <br />
                  <span className="bold">• 16 Two Bedroom Simplex</span> – 2,799
                  to 4,299 sq. ft.
                  <br />
                  <span className="bold">• 17 Three Bedroom Simplex</span> –
                  3,181 to 4,435 sq. ft.
                  <br />
                  <span className="bold">• 15 Three Bedroom Duplex</span> –
                  4,871 to 6,749 sq. ft.
                  <br />
                  <span className="bold">• 6 Four Bedroom Simplex</span> – 4,413
                  to 4,618 sq. ft.
                  <br />
                  <span className="bold">• 13 Four Bedroom Duplex</span> (Double
                  Height Living) – <br />
                  6,282 to 8,202 sq. ft.
                  <br />
                  <span className="bold">• 18 Four Bedroom Duplex</span> – 7,741
                  to 11,550 sq. ft.
                  <br />
                  <span className="bold">• 3 Sky Palaces</span>
                  <br />
                  <span className="bold">• 1 Mansion</span>
                </span>
              </div>
              <div className="image" />
            </div>
          </div>
        </div>

        <div className="about-7">
          <div className="image" />
          <div className="logo" />
          <div className="text">
            <span className="title">
              WELCOME TO <br />
              THE INFINiTE
            </span>
            <span className="content">
              ORLA Infinity is a destination for open-minded individuals seeking
              resort-style living as part of everyday life, effortlessly
              blending with the surrounding sand and sea to embrace clarity and
              rejuvenation.
              <br />
              <br />
              With only 20 four bedroom duplex residences, ORLA Infinity
              presents an exclusive and personalised experience. Each residence
              is thoughtfully positioned and designed to integrate indoor and
              outdoor spaces, allowing for breathtaking views of the surrounding
              facilities and the cityscape beyond.
            </span>
          </div>
        </div>

        <div className="about-8-9">
          <div className="overlay" />
          <div className="layout">
            <div className="about-8">
              <div className="image" />
              <div className="text">
                <span className="title">
                  RELAXATION <br />
                  IN EVERY RESIDENCE
                </span>
                <span className="content">
                  Each residence is a beachfront paradise, complete with a
                  private flowing pool where relaxation knows no bounds.
                </span>
              </div>
            </div>
            <div className="about-9">
              <div className="text">
                <span className="title">
                  AN ARRIVAL <br />
                  LIKE NO OTHER
                </span>
                <span className="content">
                  A landscaped driveway grants you access to a private entrance,
                  welcoming you to ORLA Infinity. As you step into the ground
                  floor lobby, a world-class service awaits, accompanied by
                  exquisite artworks and flowing water features that amplify the
                  grandeur of the space.
                  <br />
                  <br />
                  Discreet lifts effortlessly transport you from either the
                  secure parking garage or ground floor directly to your
                  residence, ensuring privacy and convenience.
                </span>
              </div>
              <div className="image" />
            </div>
          </div>
        </div>
        <div className="about-10">
          <div className="image" />
          <span className="text">
            Step into your residence through direct lift access, where
            double-height ceilings instantly give a sense of spaciousness and
            serenity.
            <br />
            <br />
            The ambiance is further heightened by the intricately designed
            frames that grace the floor-to-ceiling windows, offering
            uninterrupted vistas of the Gulf and the iconic Palm Jumeirah.
          </span>
        </div>

        <div className="about-11-12-13">
          <div className="overlay" />
          <div className="layout">

          <div className="about-11-12">
            <div className="left">
              <div className="image-11" />
              <span className="text">
                Within each residence, the rooms combine the finest materials
                with intuitive design, curated to perfection. Complemented by
                smart home automation, the living experience becomes
                effortlessly simple and integrated.
                <br />
                <br />
                Indulge in the luxury of walk-in closets and ensuite bathrooms,
                while the kitchens, equipped with high quality European
                appliances, effortlessly blend function and form. Thoughtfully
                designed, service amenities and staff entrances are discreetly
                hidden away, preserving a sense of privacy exclusively for you
                and your guests.
              </span>
            </div>

            <div className="image-12" />
          </div>
          <div className="about-13">
            <div className="text">
              <span className="title">RESIDENCES THAT STAND ABOVE</span>
              <span className="content">
                Perfectly positioned, ORLA Infinity offers captivating vistas of
                the serene Gulf waters that unfold from within your exquisitely
                crafted residence.
                <br />
                <br />
                The magnificence of this view extends beyond the soft curved
                terrace, a rarity in Dubai, revealing an impressive panorama
                that embraces the landscaped grounds of Palm Jumeirah, and the
                breathtaking skyline of Dubai.
              </span>
            </div>
            <div className="image" />
          </div>
          </div>
        </div>
        <div className="about-14">
          <div className="overlay" />
          <div className="image" />
          <div className="text">
            <span className="title">
              SPECTACULAR PALACES IN
              <br />
              THE SKY
            </span>
            <span className="content">
              A higher level of luxury is yours at ORLA’s Sky Palaces, which
              crown the three 60 metre ORLA towers. By utilising the
              breathtaking roof terrace gardens, the Sky Palaces can be used for
              functions ranging from corporate events and fundraising parties to
              more intimate dinners or simply functioning as a family home.
              <br />
              <br />
              The Sky Palaces will have the most exclusive experience with
              360-degree views at the top of its 60 metre height limit. Each
              comes with its own drop off and individual lobby and exclusive
              lift with lifestyle coordinator office for exclusivity and
              security, along with lobby guest parking.
            </span>
          </div>
          <div className="logo">
            <span>SKY</span>
            <span>PALACE</span>
          </div>
        </div>
        <div className="about-15">
          <div className="image" />
          <div className="overlay" />
          <div className="text">
            <div className="logo" />
            <span className="content">
              The Dubai dream comes alive at ORLA’s Mansion, designed by
              award-winning international architecture firm SAOTA. Ideal as a
              fabulous family residence or the ultimate beachside second home,
              the mansion offer every imaginable convenience and amenities.
              <br />
              <br />
              From vast living and dining spaces and a custom open kitchen on
              the ground floor, to beautiful ensuite bedrooms overlooking the
              resort-like facilities to the dedicated recreation room, private
              spa, bowling alley and exclusive 15 car garage in the lower ground
              floor – a life of leisure is yours at ORLA.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
