import React, { useEffect, useRef } from "react";
import "./index.scss";
import team01 from "../../assets/images/team_01.png";
import team0201 from "../../assets/images/team_0201.png";
import team0202 from "../../assets/images/team_0202.png";
import team0203 from "../../assets/images/team_0203.png";
import team0301 from "../../assets/images/team_0301.png";
import team0302 from "../../assets/images/team_0302.png";
import team04 from "../../assets/images/team_04.png";
import team0501 from "../../assets/images/team_0501.png";
import team0502 from "../../assets/images/team_0502.png";
import team0503 from "../../assets/images/team_0503.png";
import team0504 from "../../assets/images/team_0504.png";
import team0505 from "../../assets/images/team_0505.png";
import team0506 from "../../assets/images/team_0506.png";
import teamBg01 from "../../assets/images/team_bg_01.png";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";

const Team = (props) => {
  const { isPresentation } = props;
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current && (pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div className="static-page teamPage">
      <div ref={pageRef} className="scroll-view" onScroll={onScroll}>
        <div className="image-page content-team-1" style={{ backgroundImage: `url(${team01})` }}>
          <div className="title">GLOBAL PIONEERS</div>
        </div>
        <div className="content-team-2 blend-multiply" style={{ backgroundImage: `url(${teamBg01})` }}>
          <div className="content-wrapper sec-01">
            <div className="img-ctn">
              <div className="ctn-upper">
                <div className="ctn-0201"><img id="team0201" alt="team0201" src={team0201} /></div>
                <div className="ctn-0202"><img id="team0202" alt="team0202" src={team0202} /></div>
              </div>
              <div className="ctn-0203"><img id="team0203" alt="team0203" src={team0203} /></div>
            </div>
            <div className="text-holder">
              <div className="title">ARCHITECTURE<br/>AT ITS FINEST:<br/>FOSTER + PARTNERS</div>
              <div className="text">
              With a legacy spanning over 50 years, Foster + Partners,<br/>an internationally renowned architectural practice, has<br/>continuously set the standard for innovative design and<br/>sustainable engineering worldwide.<br /><br />
              At The ORLA Collection, their expertise is exemplified.<br/>With breathtaking views of the Arabian Gulf on one side<br/>and the iconic Palm Jumeirah and Dubai’s distinctive<br/>skyline on the other, this architecturally distinguished<br/>masterpiece is perfectly attuned to the most discerning<br/>lifestyles.
              </div>
            </div>
          </div>
          <div className="content-wrapper sec-02">
            <div className="content-left">
              <div className="img-ctn">
                <img alt="team0301" src={team0301} />
              </div>
              <div className="text-holder">
                <div className="title">INTERIOR DESIGN<br/>VISIONARIES:<br/>YODEZEEN</div>
                <div className="text">
                Recognised by their industry with numerous<br/>awards, YODEZEEN lends their distinct vision to<br/>the spacious rooms and public areas of The ORLA<br/>Collection.<br /><br />
                With exquisite craftsmanship, their celebrated<br/>interiors combine colours, textures, and materials,<br/>creating an inviting environment for stylish living.
                </div>
              </div>
            </div>
            <div className="img-ctn">
                <img alt="team0302" src={team0302} />
            </div>
          </div>
          <div className="content-wrapper sec-03">
            <div className="img-ctn">
              <img alt="team04" src={team04} />
            </div>
            <div className="text-holder">
              <div className="title">LANDSCAPE<br/>ARCHITECTS:<br/>ZED COLLECTIVE</div>
              <div className="text">
              Creating a fitting environment for a prestigious<br/>development like The ORLA Collection requires<br/>the expertise of a skilled Balinese landscape<br/>architect.<br/><br/>
              ZED Collective, recognised worldwide for their<br/>designs that prioritise people and th<br/> environment, have crafted a captivating<br/>ecosystem. Unifying intimate gardens and<br/>
              open spaces, their thoughtful design offers<br/>panoramic views that extend beyond delicate<br/>acacias, olives, and palms, encompassing the<br/>iconic Dubai skyline and beyond.
              </div>
            </div>
          </div>
          <div className="content-wrapper sec-04">
            <div className="text-holder">
              <div className="title">THE HEIGHT<br/>OF REFINED<br/>LIVING</div>
              <div className="text">
              OMNIYAT is a renowned developer of luxurious<br/>architectural masterpieces. The Art of Elevation<br/>embodies our dedication to imagining the<br/>extraordinary and building it into reality. We<br/>elevate the exceptional to create immersive living<br/>masterpieces framed by Dubai’s iconic skylines.<br/><br/>
              Since our establishment in 2005, OMNIYAT has<br/>redefined each project as an extraordinary, rare<br/>piece of art, partnering with global pioneers such as<br/>Foster + Partners, Zaha Hadid, Gilles et Boissier,<br/>Dorchester Collection and more.<br/><br/>
              The OMNIYAT touch lies in our ability to enhance<br/>experiences and shape the future of luxury living.
              </div>
            </div>
            <div className="img-ctn">
              <div className="ctn-0501"><img alt="team0501" src={team0501} /></div>
              <div className="ctn-0502"><img alt="team0502" src={team0502} /></div>
              <div className="ctn-0503"><img alt="team0503" src={team0503} /></div>
              <div className="ctn-0504"><img alt="team0504" src={team0504} /></div>
              <div className="ctn-0505"><img alt="team0505" src={team0505} /></div>
              <div className="ctn-0506"><img alt="team0506" src={team0506} /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
