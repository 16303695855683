import * as Types from './type';
import unitApi from '../../apis/api/unit';
import areaApi from '../../apis/api/area';

const actGetUnitList = (data) => ({
  type: Types.GET_UNIT_LIST,
  data,
});

const actGetUnitArea = (data) => ({
  type: Types.GET_UNIT_AREA_LIST,
  data,
});

const actFilterUnitArea= (data) => ({
  type: Types.FILTER_UNIT_AREA,
  data,
});

const actFilterUnitStatus = (data) => ({
  type: Types.FILTER_UNIT_STATUS,
  data,
});

const actFilterUnitFloorplanName= (data) => ({
  type: Types.FILTER_UNIT_FLOORPLAN_NAME,
  data,
});

const actFilterUnitFloorplanCode= (data) => ({
  type: Types.FILTER_UNIT_FLOORPLAN_CODE,
  data,
});

const actFilterUnitEnclave= (data) => ({
  type: Types.FILTER_UNIT_ENCLAVE,
  data,
});

const actFilterUnitPrice= (data) => ({
  type: Types.FILTER_UNIT_PRICE,
  data,
});

const actFilterUnitSquareFootage= (data) => ({
  type: Types.FILTER_UNIT_SQUARE_FOOTAGE,
  data,
});

const actFilterUnitParkingStall= (data) => ({
  type: Types.FILTER_UNIT_PARKING_STALL,
  data,
});

const actFilterUnitBedroom = (data) => ({
  type: Types.FILTER_UNIT_BEDROOM,
  data,
});

const actFilterUnitAspect = (data) => ({
  type: Types.FILTER_UNIT_ASPECT,
  data,
});

const actFilterUnitType = (data) => ({
  type: Types.FILTER_UNIT_TYPE,
  data,
});

const actFilterUnitCellingHeight = (data) => ({
  type: Types.FILTER_UNIT_CELLING_HEIGHT,
  data,
});

const actFilterUnitSize= (data) => ({
  type: Types.FILTER_UNIT_SIZE,
  data,
});

const actIsShowFloorplan= (data) => ({
  type: Types.SET_IS_SHOW_FLOORPLAN,
  data,
});

const actIsShowGallery= (data) => ({
  type: Types.SET_IS_SHOW_GALLERY,
  data,
});

const actIsShowVirtualTour= (data) => ({
  type: Types.SET_IS_SHOW_VIRTUAL_TOUR,
  data,
});

const actIsShowUnitDetail= (data) => ({
  type: Types.SET_IS_SHOW_UNIT_DETAIL,
  data,
});

const actSetSelectedUnit= (data) => ({
  type: Types.SET_SELECTED_UNIT,
  data,
});

const actSetUnitQuery= (data) => ({
  type: Types.SET_UNIT_QUERY,
  data,
});

const actSetIsShowUnitList= (data) => ({
  type: Types.SET_IS_SHOW_UNIT_LIST,
  data,
});


const actSetIsShowFilter= (data) => ({
  type: Types.SET_IS_SHOW_FILTER,
  data,
});

const actSetIsShowPrecinctDetail = (data) => ({
  type: Types.SET_IS_SHOW_PRECINCT_DETAIL,
  data,
});

export const reqGetUnitList = (query) => (dispatch) => {
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actGetUnitList(data));
    })
    .catch(() => {
    });
};

export const reqGetUnitArea = (query) => (dispatch) => {
  return areaApi
    .getAreasList(query)
    .then((data) => {
      dispatch(actGetUnitArea(data));
    })
    .catch(() => {
    });
};

export const reqSetSelectedUnit = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actSetSelectedUnit(''));
  }

  return unitApi
    .getUnitDetail(id)
    .then((data) => {
      dispatch(actSetSelectedUnit(data));
    })
    .catch(() => {
    });
};

export const reqSetIsShowUnitList = (data) => (dispatch) => {
  return dispatch(actSetIsShowUnitList(data));
};

export const reqFilterUnitArea = (data) => (dispatch) => {
  return dispatch(actFilterUnitArea(data));
};

export const reqFilterUnitStatus = (data) => (dispatch) => {
  return dispatch(actFilterUnitStatus(data));
};

export const reqFilterUnitFloorplanName = (data) => (dispatch) => {
  return dispatch(actFilterUnitFloorplanName(data));
};

export const reqFilterUnitFloorplanCode = (data) => (dispatch) => {
  return dispatch(actFilterUnitFloorplanCode(data));
};

export const reqFilterUnitEnclave = (data) => (dispatch) => {
  return dispatch(actFilterUnitEnclave(data));
};

export const reqFilterUnitPrice = (data) => (dispatch) => {
  return dispatch(actFilterUnitPrice(data));
};

export const reqFilterUnitSquareFootage = (data) => (dispatch) => {
  return dispatch(actFilterUnitSquareFootage(data));
};

export const reqFilterUnitParkingStall = (data) => (dispatch) => {
  return dispatch(actFilterUnitParkingStall(data));
};

export const reqFilterUnitBedroom = (data) => (dispatch) => {
  return dispatch(actFilterUnitBedroom(data));
};

export const reqFilterUnitAspect = (data) => (dispatch) => {
  return dispatch(actFilterUnitAspect(data));
};

export const reqFilterUnitType = (data) => (dispatch) => {
  return dispatch(actFilterUnitType(data));
};

export const reqFilterUnitCellingHeight = (data) => (dispatch) => {
  return dispatch(actFilterUnitCellingHeight(data));
};

export const reqFilterUnitSize = (data) => (dispatch) => {
  return dispatch(actFilterUnitSize(data));
};

export const reqIsShowFloorplan = (data) => (dispatch) => {
  return dispatch(actIsShowFloorplan(data));
};

export const reqIsShowGallery = (data) => (dispatch) => {
  return dispatch(actIsShowGallery(data));
};

export const reqIsShowVirtualTour  = (data) => (dispatch) => {
  return dispatch(actIsShowVirtualTour(data));
};

export const reqIsShowUnitDetail = (data) => (dispatch) => {
  return dispatch(actIsShowUnitDetail(data));
};

export const reqSetUnitQuery = (data) => (dispatch) => {
  return dispatch(actSetUnitQuery(data));
};

export const reqSetIsShowFilter = (data) => (dispatch) => {
  return dispatch(actSetIsShowFilter(data));
};

export const reqFilterUnitPrecinct = (data) => (dispatch) => {
  return dispatch(actFilterUnitPrecinct(data));
};

export const reqSetIsShowPrecinctDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowPrecinctDetail(data));
};
