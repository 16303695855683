import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditUserProfileModal from "../../components/guide-sesions/edit-user-profile-modal";
import authApi from "../../apis/api/auth";

const CMSProfile = () => {
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [profile, setProfile] = useState();

  useEffect(() => {
    handleGetProfile();
  }, []);

  const handleGetProfile = async () => {
    const res = await authApi.getMe();
    if (res) {
      setProfile(res.data);
    }
  };

  const onClose = () => {
    handleGetProfile();
    setShowModalEdit(false);
  }

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center justify-content-between">
        <h1 className="f-title mb-0">PROFILE</h1>
        <button
          className={"btn-update-profile"}
          onClick={() => setShowModalEdit(true)}
        >
          Update Profile
        </button>
      </div>
      <div className="page-body">
        <div className="line-info-profile">
          <span>Email</span>
          <span>{profile?.email}</span>
        </div>
        <div className="line-info-profile">
          <span>Phone number</span>
          <span>{profile?.phone}</span>
        </div>
        <div className="line-info-profile">
          <span>Staff Type</span>
          <span>{profile?.staffType}</span>
        </div>
        <div className="line-info-profile">
          <span>Password</span>
          <span>***********</span>
        </div>
        {/*<div className="line-info-profile">*/}
        {/*  <span>Last name</span>*/}
        {/*  <span>Example Last</span>*/}
        {/*</div>*/}
      </div>
      {isShowModalEdit && (
        <EditUserProfileModal
          data={profile}
          isShowModal={isShowModalEdit}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default CMSProfile;
