import React from "react";
import { sharedData } from "./App";

export function GroundPlane() {
  return (
    <mesh rotation-x={-Math.PI * 0.5} meshId={0} position={[0, -3.05, 0]}>
      <planeGeometry args={[40000, 40000]} />
      <meshStandardMaterial
        color={[0.1, 0.1, 0.1]}
        roughness={0.25}
        metalness={1}
        envMap={sharedData.envMap}
      />
    </mesh>
  );
}
