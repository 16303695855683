import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import TopNavCMS from "./cms-top-nav";
import CMSCustomers from "./customers";
import CMSContent from "./content";
import CMSFrequentlyAQS from "./frequently-asked-questions";
import "./index.scss";
import CMSProperties from "./properties";
import CMSProfile from "./profiles";

const CMS = (props) => {
  const { roles, authMiddleware } = props;
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  return (
    <div className="wrap-cms-page">
      <TopNavCMS />
      <Switch>
        <Route
          exact
          path="/cms"
          render={() => {
            return <Redirect to="/cms/properties" />;
          }}
        />
        <Route path="/cms/content">
          <CMSContent />
        </Route>
        <Route path="/cms/properties">
          <CMSProperties />
        </Route>
        <Route path="/cms/customers">
          <CMSCustomers />
        </Route>
        <Route path="/cms/frequently-asked-questions">
          <CMSFrequentlyAQS />
        </Route>
        <Route path="/cms/profiles">
          <CMSProfile />
        </Route>
      </Switch>
    </div>
  );
};
export default CMS;
