import React, { useEffect, useState } from "react";
import icSearch from "../../assets/images/icSearchV3.svg";
import RequestSupportModal from "./request-support-modal";

const Tabs = [
  {
    name: "App",
    key: "app",
  },
  {
    name: "iPab",
    key: "iPab",
  },
  {
    name: "Physical model",
    key: "physical_model",
  },
  {
    name: "Sales gallery hardware",
    key: "sales_gallery_hardware",
  },
  {
    name: "TV display",
    key: "tv_display",
  },
];

const CMSFrequentlyAQS = () => {
  const [tabActive, setTabActive] = useState(Tabs[1].key);
  const [showModal, setShowModal] = useState(false);
  const selectTab = (key) => {
    setTabActive(key);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const renderTab = () => {
    return (
      <div className="tab-faqs">
        {Tabs.map((i, idx) => {
          return (
            <span
              onClick={() => selectTab(i.key)}
              key={`name-tab-${idx}`}
              className={`${i.key === tabActive && "isActive"}`}
            >
              {i.name}
            </span>
          );
        })}
      </div>
    );
  };
  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">FAQs</h1>
        <div className="input-group ms-auto page-header__input-search w-50" />
        <button
          className="btn-update-profile mx-4"
          onClick={() => setShowModal(true)}
        >
          Request Support
        </button>
        <img src={icSearch} alt="icon-search" />
      </div>
      <div className="page-body">
        {renderTab()}
        <div className="wrapper-list-faq">
          {[1, 2, 3, 4, 5, 6, 7, 8, , 9, 10].map((i) => {
            return (
              <div key={i} className="line-faq">
                <span>Question topic</span>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <RequestSupportModal isShow={showModal} onClose={handleClose} />
    </div>
  );
};
export default CMSFrequentlyAQS;
