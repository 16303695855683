import * as type from './type';
import { PAGES } from '../../constants/options';

const initialState = {
  page: PAGES.ONBOARD_PAGE,
  isShowImmerse: false,
  isExpandNav: false,
  activePanotourId: '',
  isShowExploreModal: false,
  isTransparent: false,
  activeGalleryId: '',
  activeAreaId: '',
  isShowSubPrecinctDetail: false,
  activeDistrictYear: 2021,
  isShowLoginModal: false,
  transportOptions: [],
  transportOptionDistricts: [],
  subPrecincts: [],
  activeTransportOption: [],
  activeTransportOptionDistricts: [],
  isShowEndGuideTenantSession: false,
  activeEndGuideTenantSessionId: '',
  isShowBookingAppointmentForm: false,
  isShowVideo: false,
  isPresentation: false,
  activeObjectIds: [],

};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_IS_SHOW_BOOKING_APPOINTMENT_FORM:
      return {
        ...state,
        isShowBookingAppointmentForm: action.data,
      };
    case type.SET_PAGE:
      return {
        ...state,
        page: action.data,
      };
    case type.SET_IS_SHOW_VIDEO:
      return {
        ...state,
        isShowVideo: action.data,
      };
    case type.SET_IS_SHOW_IMMERSE:
      return {
        ...state,
        isShowImmerse: action.data,
      };
    case type.SET_IS_EXPAND_NAV:
      return {
        ...state,
        isExpandNav: action.data,
      };
    case type.SET_ACTIVE_PANTOUR_ID:
      return {
        ...state,
        activePanotourId: action.data,
      };
    case type.SET_IS_SHOW_EXPLORE_MODAL:
      return {
        ...state,
        isShowExploreModal: action.data,
      };
    case type.SET_IS_TRANSPARENT:
      return {
        ...state,
        isTransparent: action.data,
      };
    case type.SET_ACTIVE_GALLERY_ID:
      return {
        ...state,
        activeGalleryId: action.data,
      }
    case type.SET_ACTIVE_AREA_ID:
      return {
        ...state,
        activeAreaId: action.data,
      }
    case type.SET_IS_SHOW_SUB_PRECINCT_DETAIL:
      return {
        ...state,
        isShowSubPrecinctDetail: action.data,
      }
    case type.SET_ACTIVE_DISTRICT_YEAR:
      return {
        ...state,
        activeDistrictYear: action.data,
      }
    case type.SET_IS_SHOW_LOGIN_MODAL:
      return {
        ...state,
        isShowLoginModal: action.data,
      }
    case type.SET_TRANSPORT_OPTIONS:
      return {
        ...state,
        transportOptions: action.data.data,
      }
    case type.SET_TRANSPORT_OPTION_DISTRICTS:
      return {
        ...state,
        transportOptionDistricts: action.data.data,
      }
    case type.SET_SUB_PRECINCTS:
      return {
        ...state,
        subPrecincts: action.data.data,
      }
    case type.SET_ACTIVE_TRANSPORT_OPTION:
      return {
        ...state,
        activeTransportOption: action.data,
      }
    case type.SET_ACTIVE_TRANSPORT_OPTION_DISTRICTS:
      return {
        ...state,
        activeTransportOptionDistricts: action.data,
      }
    case type.SET_IS_SHOW_END_GUIDE_TENANT_SESSION:
      return {
        ...state,
        isShowEndGuideTenantSession: action.data,
      }

    case type.SET_ACTIVE_END_GUIDE_TENANT_SESSION_ID:
      return {
        ...state,
        activeEndGuideTenantSessionId: action.data,
      }
    case type.SET_IS_PRESENTATION:
      return {
        ...state,
        isPresentation: action.data,
      }
    case type.SET_ACTIVE_OBJECT_IDS:
      return {
        ...state,
        activeObjectIds: action.data,
      }
    default:
      return state;
  }
};
