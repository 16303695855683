import React, { useEffect } from "react";
import SideNav from "../sidenav";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
} from "../../reduxs/home/action";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const UnitDetail = () => {
  const dispatch = useDispatch();
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );
  const unit = useSelector((state) => state.unitExplore.selectedUnit);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_UNIT_FLOORPLAN) {
          handleShowFloorplan();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_FLOORPLAN) {
          handleCloseFloorplan();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_GALLERY) {
          handleShowGallery();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
          handleCloseGallery();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_VIEWLINE) {
          handleShowVirtualTour();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
          handleCloseVirtualTour();
        }
      });
    }
  }, [])

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(true));
    dispatch(reqSetIsTransparent(true));
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
    dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
    dispatch(reqSetIsTransparent(true));
  };

  const handleShowVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetActiveGalleryId(unit?.virtualTour?.id));
    dispatch(unitExploreAct.reqIsShowVirtualTour(true));
    dispatch(reqSetIsTransparent(true));
  };

  const handleCloseVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(reqSetIsTransparent(false));
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const renderBtnFloorplan = () => {
    if (isShowFloorplan) {
      return (
        <li onClick={() => handleCloseFloorplan()} className="list-group-item">
          <img src="/icons/arrow.svg" alt="" />
          Close floorplan
        </li>
      );
    }

    return (
      <li onClick={() => handleShowFloorplan()} className="list-group-item">
        <img src="/icons/arrow.svg" alt="" />
        View floorplan
      </li>
    );
  };

  const renderBtnGallery = () => {
    if (isShowGallery) {
      return (
        <li onClick={() => handleCloseGallery()} className="list-group-item">
          <img src="/icons/arrow.svg" alt="" />
          Close gallery
        </li>
      );
    }

    return (
      <li onClick={() => handleShowGallery()} className="list-group-item">
        <img src="/icons/arrow.svg" alt="" />
        View gallery
      </li>
    );
  };

  const renderBtnVirtualTour = () => {
    if (isShowVirtualTour) {
      return (
        <li
          onClick={() => handleCloseVirtualTour()}
          className="list-group-item"
        >
          <img src="/icons/arrow.svg" alt="" />
          Close viewline
        </li>
      );
    }

    return (
      <li onClick={() => handleShowVirtualTour()} className="list-group-item">
        <img src="/icons/arrow.svg" alt="" />
        View viewline
      </li>
    );
  };

  const formatMoney = (price) => {
    if (!price) return 0;

    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AED",
      maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
    });

    return formatter.format(price);
  };

  return (
    <SideNav
      position="right"
      isDark={false}
      id="sidenavDetail"
      heading="Residence details"
    >
      <ul className="list-unit-detail">
        <li className="item-detail">
          <span className="me-auto">Name</span>
          <b>{unit?.name}</b>
        </li>
        <li className="item-detail">
          <span className="me-auto">Bedrooms</span>
          <b>{unit?.bedrooms}</b>
        </li>
        <li className="item-detail">
          <span className="me-auto">Celling Height</span>
          <b className="text-capitalize">{unit?.cellingHeight}</b>
        </li>
        <li className="item-detail">
          <span className="me-auto">Price</span>
          <b>{formatMoney(unit?.price)}</b>
        </li>
      </ul>

      <div className="wrap-btn-detail mt-auto">
        <ul className="list-group mb-0 py-2">
          {renderBtnFloorplan()}
          {renderBtnGallery()}
          {unit?.virtualTour !== null && renderBtnVirtualTour()}
        </ul>
      </div>
    </SideNav>
  );
};

export default UnitDetail;
