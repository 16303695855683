import React from "react"
import { Modal } from "react-bootstrap";

const ErrorModal = (props) => {
  const { onClose, isShow } = props;

  return (
    <Modal
      backdropClassName="opacity0"
      className="wrap-not-found"
      show={isShow}
      onHide={onClose}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="modal-not-found">
          <span className="title">CUSTOMER NOT FOUND</span>
          <span className="sub">
            This combination of name and email does not match any customer
            in our system.{" "}
          </span>
          <button onClick={onClose}>Close</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ErrorModal;
