import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_GROUP_ID } from "../../constants/master-data";
import {
  reqSetEditableCustomer,
  reqSetEditableCustomerNote,
} from "../../reduxs/cms/action";
import { ArrowRightFill } from "../../components/svgs/icons";

const CustomerRow = (props) => {
  const { item, showFavorites, onToggleActiveCustomer } = props;
  const dispatch = useDispatch();
  const editableCustomer = useSelector((state) => state.cms.editableCustomer);

  const [isActive, setIsActive] = useState(item.isActive || item.isActive === undefined);
  
  const onEditable = () => {
    dispatch(reqSetEditableCustomer(item));
  };

  const onEditCustomerNote = () => {
    dispatch(reqSetEditableCustomerNote(item));
  };

  const onClickView = () => {
    showFavorites(item);
  };

  const customerUrl = `${window.location.origin}/${item.id}`;
  const handleCopyCustomerUrl = () => {
    navigator.clipboard.writeText(customerUrl);
  }

  const onToggle = () => {
    try {
      onToggleActiveCustomer(item);
      setIsActive(!isActive);  
    } catch (error) {
      return;
    }
  }

  return (
    <tr>
      <td className="col-2">{item.firstname}</td>
      <td className="col-2">{item.surname}</td>
      <td className="col-3 email-column-custom text-one-line">{item.email}</td>
      <td className="col-1 text-one-line clickable mb-0 cursor-pointer">
        <div
          className="text-one-line clickable mb-0 cursor-pointer"
          onClick={onClickView}
        >
          View
          <span style={{ marginLeft: 10 }}>
            <ArrowRightFill />
          </span>
        </div>
      </td>

      <td className="col-3 text-one-line">
        <div className="unique-url-column-wrapper">
          <div className="unique-url-column">
            <span className='unique-url-column-text'>
              {customerUrl}
            </span>
            <span className="unique-url-column-copy-button" onClick={handleCopyCustomerUrl}>Copy</span>
          </div>
        </div>
      </td>
      <td className="col-1">
        <div className="form-check form-switch my-n1">
          <input
            className="form-check-input customer-active-switch"
            type="checkbox"
            id="customer-state-1"
            onClick={() => onToggle()}
            checked={isActive}
          />
          <label className="form-check-label" htmlFor="customer-state-1" />
        </div>
      </td>
      <td className="col-1">
        <button onClick={onEditable} className="edit-property-button">
          <span>...</span>
        </button>
      </td>
    </tr>
  );
};

export default CustomerRow;
