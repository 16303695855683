import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import socket from '../../helper/socket';
import './agent-session.scss';

const AgentSession = () => {
  const agent = useSelector((state) => state.user.data);
  const { customerId } = useParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    socket.connect(agent);
  }, [agent]);

  const sendMessage = () => {
    socket.emit('private message', {
      content: message,
      to: customerId,
      from: agent,
    });
    setMessage('');
  };

  socket.on('connect_error', (err) => {
    if (err.message === 'invalid user name') {
      socket.off('connect_error');
    }
  });

  return (
    <div className="wrap-agent-session">
      <div className="col-lg-4 col-md-4" style={{ margin: '0 auto', paddingTop: '20px' }}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="form-control mb-3"
        />
        <button className="btn btn-primary" onClick={() => sendMessage()}>
          Chat
        </button>
      </div>
    </div>
  );
};

export default AgentSession;
