import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import "../../home-gallery/index.scss";
import { ACTION_NAME, GALLERY_TYPE, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { getImagePath } from "../../../helper/media";
import Fancybox from "../../home-gallery/fancy-box";
import {
  reqSetActiveGallery,
  reqSetIsShowVirtualModal,
} from "../../../reduxs/gallery/action";
import { useDispatch } from "react-redux";
import socket from "../../../helper/socket";
import { IconCloseButton, LeftArrowIcon, RightArrowIcon } from "../../svgs/icons";
import { classNames } from "../../../helper/utils";

const Renders = ({ data, isInfinity, isPresentation }) => {
  const [isShowFancyBox, setIsShowFancyBox] = useState(null);
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const onShowVirtualModal = (galleryId) => {
    dispatch(reqSetIsShowVirtualModal(true));
    dispatch(reqSetActiveGallery(galleryId));
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.SHOW_RENDER_GALLERY) {
          return onShowFancyBox(content.data.item);
        }

        if (content.action == ACTION_NAME.CLOSE_RENDER_GALLERY) {
          return onCloseFancyBox();
        }

        if (content.action == ACTION_NAME.JUMP_TO_RENDER_IMAGE) {
          return onJumpToImage(content.data.index);
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current && (pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const onNextImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.next();
  };

  const onPrevImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.prev();
  };

  const onShowFancyBox = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_RENDER_GALLERY, {
        item,
      });
    }
    const el = document.getElementById(`im-${item?.id}`)
    if (el) {
      el.click()
      setIsShowFancyBox(`im-${item?.id}`)
    }
  }

  const onCloseFancyBox = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
    }
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.close();
  };

  const onJumpToImage = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_RENDER_IMAGE, {
        index,
      });
    }
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.jumpTo(index);
  }

  const mediaData = data?.media || [];

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} className="renders-content" onScroll={onScroll}>
      <div className="renders-content-row">
        {[1, 2, 3].map((col) => {
          const startIndex = col <= 1 ? 0 : Math.ceil(mediaData?.length * (col - 1) / 3)
          const endIndex = Math.ceil(mediaData?.length * col / 3)
          return (
            <div key={String(col)} className="renders-content-col">
              {
                mediaData?.slice(startIndex, endIndex)?.map((item, index) => {
                  if (item.type === GALLERY_TYPE.VIDEO) return null;
                  return (
                    <div key={`renders-${index}`} className={"renders-content-item"}>
                      {item.type === GALLERY_TYPE.VIRTUAL_TOUR ? (
                        <div
                          className={`card-renders ${isInfinity ? "infinity" : ""}`}
                          onClick={() => onShowVirtualModal(item.id)}
                        >
                          <img src={getImagePath(item.path)} alt="virtual-img" />
                          <div className="title">{item.name}</div>
                        </div>
                      ) : (
                        <Fancybox
                          key={`Fancybox-${index}`}
                          options={{
                            infinite: true, mainClass: "pure-fancy-box", showNavArrows: false,
                            on: {
                              "Carousel.change": (fancybox) => {
                                const el = fancybox.getSlide();
                                if (!isPresentation) {
                                  socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_RENDER_IMAGE, {
                                    index: el.index
                                  });
                                }
                                setIsShowFancyBox(el.$trigger.id);
                              },
                              "destroy": () => {
                                if (!isPresentation) {
                                  socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
                                }
                                setIsShowFancyBox(null)
                              },
                            },
                          }}
                        >
                          <div
                            className={`gallery-item card-renders ${isInfinity ? "infinity" : ""
                              }`}
                          >
                            <img
                              id={`im-${item?.id}`}
                              onClick={() => onShowFancyBox(item)}
                              src={getImagePath(item.path)}
                              alt="normal-img"
                              data-fancybox="gallery"
                            />
                            <div className="title">{item.name}</div>
                          </div>
                        </Fancybox>
                      )}
                    </div>
                  );
                })
              }
            </div>
          )
        })}
      </div>
      {!!isShowFancyBox && (
        <div className="wrap-btn-fancy">
          <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
            <LeftArrowIcon style={{ width: 21, height: 35 }} fill={'#C7A446'} />
          </div>
          <div className="wrap-slide-fancy">
            {mediaData?.map((item, index) => (
              <div
                key={`slide-${index}`}
                className={classNames('fancy-dot', `im-${item?.id}` === isShowFancyBox ? '' : 'fancy-dot-o')} />
            ))}
          </div>
          <div className="btn-next cursor-pointer" onClick={onNextImage}>
            <RightArrowIcon style={{ width: 21, height: 35 }} fill={'#C7A446'} />
          </div>
        </div>
      )}
      {!!isShowFancyBox && (
        <div className="wrap-fancy-close">
          <div className="cursor-pointer" onClick={onCloseFancyBox}>
            <IconCloseButton style={{ width: 26 }} fill={'#C7A446'} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Renders;
