import React from "react";
import "./index.scss";
import { ReactComponent as Close } from "../../assets/images/close.svg";

const Modal = ({
  open,
  onHide,
  isShowBtnClose = true,
  children,
  classNames,
  isOutSideClose = true,
  currentColor = "white",
}) => {
  return (
    <>
      {open && (
        <div
          className={`modal-container ${classNames}`}
          onClick={isOutSideClose ? onHide : () => {}}
        >
          {isShowBtnClose && (
            <div className="btn-close-modal" onClick={onHide}>
              <Close fill={currentColor} />
            </div>
          )}
          <div className="modal-base-content">
            <div className="modal-base-body">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
